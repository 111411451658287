// @flow
import * as React from 'react'
import { Page, Hero, HeroBody, NL, EN, LocalDate, Section, WallpaperSection, Button } from '.'
import DemoThomas from '../assets/Demo_Thomas_orange.png'

import './Insights.scss'

import type { Properties } from 'csstype'
import type { StoryMetadata } from '../views/Insights'
import type { LangString } from '../App'

type HeroProps = {
  title: React.Node,
  subtitle: React.Node,
  date: string,
  description: React.Node,
}

export function InsightsHero({ title, subtitle, date, description }: HeroProps): React.Node {
  return (
    <Hero className="px-3 px-xl-5 pb-3 text-container">
      <div className="title-wrapper mb-3 mb-md-4">
        <div className="subtitle mb-2">{subtitle}</div>
        <h1>{title}</h1>
      </div>
      <HeroBody>{description}</HeroBody>
      <p className="text-muted small my-3">
        <NL>Geplaatst op</NL>
        <EN>Posted on</EN> <LocalDate dateString={date} />
      </p>
    </Hero>
  )
}

type InsightQuoteProps = {
  wallpaper?: string,
  img: string,
  name: string,
  subtitle?: React.Node,
  quote: React.Node,
}

export function InsightsQuote({ wallpaper = DemoThomas, img, name, subtitle, quote }: InsightQuoteProps): React.Node {
  return (
    <div className="px-3 px-lg-5">
      <WallpaperSection
        size="small"
        wallpaper={wallpaper}
        className="insights-quote rounded border border-orange"
        wallpaperStyle={{
          filter: 'blur(4px)',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          opacity: '0.9',
        }}
        divider="bottom"
        dividerClassName="bg-orange"
        dividerGradient="none"
      >
        <div className="text-container text-white">
          <div className="row gx-5 gy-3">
            <div className="col-md-auto position-relative">
              <img src={img} alt={name} className="quote-img rounded-circle img-fluid" />
            </div>
            <div className="col-md-9">
              {subtitle ? <div className="subtitle mb-0">{subtitle}</div> : null}
              {quote ? <blockquote className="blockquote fs-3 fw-bold lh-sm">{quote}</blockquote> : null}
            </div>
          </div>
        </div>
      </WallpaperSection>
    </div>
  )
}

type PhotoProps = { src: string, alt: string, style?: Properties<> }

export function InsightsImageMobile({ src, alt, style }: PhotoProps): React.Node {
  return (
    <div
      alt={alt}
      className="p-3 d-flex d-xl-none align-items-end justify-content-end small text-white"
      style={{
        height: '750px',
        backgroundImage: `url(${src})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center top',
        ...style,
      }}
    >
      {alt}
    </div>
  )
}
export function InsightsImageDesktop({ src, alt }: PhotoProps): React.Node {
  const computedStyle = {
    backgroundImage: `url(${src})`,
    backgroundPosition: 'center top',
    backgroundSize: 'cover',
    paddingBottom: '4.5rem',
  }
  return (
    <div className="d-none d-xl-block col-xl-4 p-0">
      <div
        className="vh-100 col-4 pe-3 position-fixed z-0 d-flex align-items-end justify-content-end small text-white"
        style={computedStyle}
        alt={alt}
      >
        {alt}
      </div>
    </div>
  )
}

export function InsightsSection({ children }: { children: React.Node }): React.Node {
  return (
    <Section size="small" className="my-0 pt-3">
      <div className="text-container d-flex flex-column">{children}</div>
    </Section>
  )
}

export function ReadMore({ to, children }: { to: LangString, children: React.Node }): React.Node {
  return (
    <div
      className="mx-auto my-3 border border-green position-relative bg-transparent-green small d-flex"
      style={{ minWidth: '45%' }}
    >
      <div className="d-flex p-3">
        <div className="d-flex flex-column me-4">
          <span className="subtitle lh-sm mb-1" style={{ fontSize: '75%' }}>
            <NL>Lees meer</NL>
            <EN>Read more</EN>
          </span>
          <span className="fw-bold lh-sm">{children}</span>
        </div>
      </div>
      <Button
        to={to}
        className="align-self-end btn-dark-green text-white stretched-link ms-auto my-0 p-1 pb-0 lh-1"
        noArrow
      >
        <i className="material-icons lh-1" style={{ height: '27px' }}>
          arrow_forward
        </i>
      </Button>
    </div>
  )
}

type PageProps = {
  metadata: StoryMetadata,
  hero: HeroProps,
  quote: InsightQuoteProps,
  image: PhotoProps,
  children: React.Node,
}

export function InsightsPage({ metadata, hero, quote, image, children }: PageProps): React.Node {
  return (
    <Page title={metadata.title} description={metadata.description} location={`/insights/${metadata.slug}`}>
      <div className="row g-0 insights-page">
        <InsightsImageDesktop {...image} />
        <div className="col-12 col-xl-8">
          <InsightsHero date={metadata.date} {...hero} />
        </div>
        <InsightsQuote {...quote} />
        <div className="col-12 offset-xl-4 col-xl-8">
          <InsightsImageMobile {...image} />
          <InsightsSection>{children}</InsightsSection>
        </div>
      </div>
    </Page>
  )
}
