// @flow
import * as React from 'react'
import { EN, NL, Link } from './'

type CardProps = {
  children: React.Element<any> | string | Array<React.Element<any> | string>,
  img: string,
  backgroundPosition?: ?string,
  link: string | { [lang: string]: string },
}

type AttributionProps = {
  name: React.Node,
  org: React.Node,
}

export const QuoteCard = ({
  children,
  img,
  backgroundPosition,
  link,
}: CardProps): React.Node => (
  <div className="card mb-3 position-relative">
    <div className="row no-gutters flex-column flex-md-row">
      <div className="col col-md-4 pe-md-0 position-relative">
        <div
          className="d-flex h-100 v-100"
          style={{ overflow: 'hidden', minHeight: '150px' }}
        >
          <div
            style={{
              backgroundImage: `url(${img})`,
              backgroundSize: 'cover',
              backgroundPosition: backgroundPosition || 'center',
            }}
            className="card-img"
          />
        </div>
      </div>
      <div className="col col-md-8 ps-2">
        <div className="card-body">
          <div className="card-text mb-0">{children}</div>
          <div className="card-text mb-0">
            <Link to={link} className="small stretched-link">
              <EN>Read more →</EN>
              <NL>Lees meer →</NL>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export const QuoteAttrib = ({ name, org }: AttributionProps): React.Node => (
  <div className="text-end text-muted">
    <div className="">— {name}</div>
    <div className="fw-bold small">{org}</div>
  </div>
)
