// @flow

// Add a new logo?
// 1. Add logo to src/assets/clients
// 2. Import the image from there
// 3. Add a line in the `logo_DB` with their name and the src image
// 4. Add them to any subsets (note the order!)

import * as React from 'react'
import _ from 'lodash'

//import XX from '../assets/clients/XX.png'
import AmsterdamUMC from '../assets/clients/AmsterdamUMC.png'
import Artis from '../assets/clients/Artis.png'
import AOG from '../assets/clients/AOG.png'
import Astellas from '../assets/clients/Astellas.png'
import Brill from '../assets/clients/Brill.png'
import CHDR from '../assets/clients/CHDR.png'
import DHD from '../assets/clients/DHD.png'
import EKuSIM from '../assets/clients/EKuSIM.png'
import HiiL from '../assets/clients/HiiL.png'
import Inventeers from '../assets/clients/Inventeers.png'
import GemeenteLeiden from '../assets/clients/Leiden.png'
import Levels from '../assets/clients/Levels.png'
import LUMC from '../assets/clients/LUMC.png'
import Naturalis from '../assets/clients/Naturalis.png'
//import Nijestee from '../assets/clients/Nijestee.png'
import NLAIC from '../assets/clients/NLAIC.png'
import RAG from '../assets/clients/RAG.png'
import RWS from '../assets/clients/RWS.png'
import STEAG from '../assets/clients/STEAG.png'
import Strohm from '../assets/clients/Strohm.png'
import TNO from '../assets/clients/TNO.png'
import Unive from '../assets/clients/Unive.png'
import Verenso from '../assets/clients/Verenso.png'
import WePublic from '../assets/clients/WePublic.png'
import UniLeiden from '../assets/clients/UniLeiden.png'
import Whooz from '../assets/clients/Whooz.png'
import Zig from '../assets/clients/Zig.png'
import ZorgZekerheid from '../assets/clients/ZorgZekerheid.png'
import { ClientLogo as Logo } from './ClientLogo'

const logoDB = {
  'Airborne Oil & Gas': { src: AOG },
  'Universiteit Leiden': { src: UniLeiden },
  'Zorg en Zekerheid': { src: ZorgZekerheid },
  AmsterdamUMC: { src: AmsterdamUMC },
  Artis: { src: Artis },
  Astellas: { src: Astellas },
  Brill: { src: Brill },
  CHDR: { src: CHDR },
  DHD: { src: DHD },
  EKuSIM: { src: EKuSIM },
  GemeenteLeiden: { src: GemeenteLeiden },
  HiiL: { src: HiiL },
  Inventeers: { src: Inventeers },
  Levels: { src: Levels },
  LUMC: { src: LUMC },
  Naturalis: { src: Naturalis },
  //Nijestee: { src: Nijestee },
  NLAIC: { src: NLAIC },
  RAG: { src: RAG },
  Rijkswaterstaat: { src: RWS },
  STEAG: { src: STEAG },
  Strohm: { src: Strohm },
  TNO: { src: TNO },
  Univé: { src: Unive },
  Verenso: { src: Verenso },
  WePublic: { src: WePublic },
  Whooz: { src: Whooz },
  Zig: { src: Zig },
}

const subsets = {
  healthcare: [
    'DHD',
    'LUMC',
    'Verenso',
    'AmsterdamUMC',
    'CHDR',
    'Levels',
    'Astellas',
  ],
  industry: [
    'RAG',
    'STEAG',
    'EKuSIM',
    // 'DFC',
    'Airborne Oil & Gas',
    'Inventeers',
    'Strohm',
  ],
  financialServices: [
    'Univé',
    'Zorg en Zekerheid',
    'Zig',
    'Whooz',
    //'Nijestee',
    // 'Blaauwberg',
  ],
  researchAndMedia: [
    'TNO',
    'Universiteit Leiden',
    'Brill',
    // 'Pallas',
    'WePublic',
  ],
  networks: ['NLAIC'],
}

type MultiProps = {|
  subset?: string[],
  className?: string,
  innerClassName?: string,
  style?: Object,
|}

type SingleProps = {|
  className?: string,
  innerClassName?: string,
  style?: Object,
  name: string,
|}

export const ClientLogos = ({
  subset = _.keys(logoDB),
  className,
  innerClassName,
  ...props
}: MultiProps): React.Element<'div'>[] => {
  return subset.map((name) => (
    <div key={name} className={className}>
      <Logo
        {...props}
        key={name}
        name={name}
        src={_.get(logoDB, [name, 'src'])}
        className={innerClassName}
      />
    </div>
  ))
}

export const ClientLogo = ({ name, ...props }: SingleProps): React.Node => {
  return <ClientLogos subset={[name]} {...props} />
}

export const HealthcareLogos = (props: MultiProps): React.Node => (
  <ClientLogos subset={subsets['healthcare']} {...props} />
)
export const IndustryLogos = (props: MultiProps): React.Node => (
  <ClientLogos subset={subsets['industry']} {...props} />
)
export const FinServicesLogos = (props: MultiProps): React.Node => (
  <ClientLogos subset={subsets['financialServices']} {...props} />
)
export const ResearchAndMediaLogos = (props: MultiProps): React.Node => (
  <ClientLogos subset={subsets['researchAndMedia']} {...props} />
)
export const NetworksLogos = (props: MultiProps): React.Node => (
  <ClientLogos subset={subsets['networks']} {...props} />
)
