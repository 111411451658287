// @flow
import * as React from 'react'

import './BackgroundImage.scss'

type Props = {
  image: any,
  alt: string,
  style?: Object,
  className?: string
}

export const BackgroundImage = ({ className = '', ...props }: Props): React.Element<"div"> => (
  <div className="background-image-container">
    <img
      src={props.image}
      alt={props.alt}
      className={`background-image ${className}`}
      style={props.style}
    />
  </div>
)
