// @flow
import * as React from 'react'
import {
  BackToTop,
  Button,
  EN,
  Hero,
  HeroTitle,
  HeroBody,
  Link,
  NL,
  Page,
  Section,
  QuoteAttrib,
  QuoteCard,
} from '../components'

import ServiceLinks from './ServiceLinks'

import WorkshopThumb from '../assets/projects/workshop-thumbnail.jpg'
import RAGThumb from '../assets/projects/rag-thumbnail.jpg'
import ZooThumb from '../assets/projects/zoo-thumbnail.jpg'

// import Python from '../assets/technologies/Python.png'
// import Docker from '../assets/technologies/Docker.png'
// import Plotly from '../assets/technologies/Plotly.png'
// import Tensorflow from '../assets/technologies/Tensorflow.png'

const Services = (): React.Node => (
  <Page
    title={{ nl: 'Diensten', en: 'Services' }}
    description={{
      nl: 'Wij maken begrijpelijke, op mensen gefocuste, data- en AI-oplossingen, voor allerlei soorten organisaties. Van idee tot oplossing: wij helpen je graag!',
      en: 'We create understandable, human-centered, data-driven solutions, for various types of organisations. From idea to solution: we can help!',
    }}
    location="/services"
  >
    <Hero>
      {/* <div className="subtitle mb-3">
        <NL>Wat we doen</NL>
        <EN>What we do</EN>
      </div> */}
      <HeroTitle>
        <NL>Van idee naar oplossing</NL>
        <EN>From idea to solution</EN>
      </HeroTitle>
      <HeroBody>
        <p>
          <NL>
            Of je nu kleine of grote data- en AI-ambities hebt, met een gerichte of algemenere vraag:{' '}
            <span className="strong text-green">
              als jij aan de slag wilt met jouw data en AI, dan kunnen wij je helpen!
            </span>
          </NL>
          <EN>
            Whether you have small or big data and AI ambitions, with a clear or more general question:{' '}
            <span className="strong text-green">if you want to start leveraging your data and AI, we can help!</span>
          </EN>
        </p>
      </HeroBody>
    </Hero>
    <Section size="small" className="container pt-0 mt-0">
      <ServiceLinks />
    </Section>

    {/* <Section size="small" className="text-center">
      {'<!-- Here could perhaps go some logos of some of the stuff we use? -->'}
    </Section> */}

    <Section id="ontdek" gradient="aqua-blue">
      <div className="text-container text-white">
        <div className="subtitle">
          <NL>Starten met data en AI</NL>
          <EN>Starting with data en AI</EN>
        </div>
        <h1 className="text-white">
          <NL>Ontdek jouw data- en AI-mogelijkheden</NL>
          <EN>Discover your data and AI potential</EN>
        </h1>
        <p>
          <NL>
            Steeds meer organisaties werken datagedreven. AI is steeds meer een hot-topic. Maar wat kan je er nu
            eigenlijk allemaal mee? Is het iets voor jou? Wanneer kan je daarmee aan de slag? Wij beantwoorden graag al
            je vragen, in het bijzonder...
          </NL>
          <EN>
            More and more organisations are going data driven. AI is an increasingly hot topic. But what can you really
            do with it? Is it for you? When can you start with it and how? We will gladly answer any questions you may
            have, most importantly...
          </EN>
        </p>
        <div className="strong my-4 blockquote">
          <NL>
            "Is Artificiële Intelligentie nuttig voor mijn organisatie?{' '}
            <span className="text-light-blue">En hoe dan?</span>"
          </NL>
          <EN>
            "Is Artificial Intelligence useful for my organisation?{' '}
            <span className="text-light-blue">And if yes, how?</span>"
          </EN>
        </div>
        <p>
          <NL>
            Samen komen we erachter of en hoe dit het geval is. Via een kort gesprek of via een uitgebreide workshop –
            afhankelijk van jouw wensen, jullie voorkennis en/of de complexiteit van jullie processen – brengen we in
            kaart waar jouw kansen liggen.
          </NL>
          <EN>
            Together we will find out if this is the case. Through a short conversation or an elaborate workshop –
            depending on your wishes, foreknowledge and/or the complexity of your processes – we will map where your AI
            opportunities lie.
          </EN>
        </p>
        <h3>
          <NL>Ter inspiratie en/of als begin van je reis naar een menselijke, datagedreven organisatie.</NL>
          <EN>As inspiration and/or as the start of your journey to a human-centered, data driven organisation.</EN>
        </h3>
        <p>
          <NL>Wil je ontdekken waar jouw AI-kansen liggen?</NL>
          <EN>Want to know where your AI potential lies?</EN>
        </p>
        <div className="my-5">
          <Button size="lg" color="white" to="/contact" className="me-3 text-aqua">
            <NL>Plan nu een gesprek</NL>
            <EN>Plan a meeting now</EN>
          </Button>
          <Button size="lg" color="white" to="#begrip" className="me-3" outline>
            <NL>Lees over onze kennissessies</NL>
            <EN>Read about our knowledge sessions</EN>
          </Button>
        </div>
        <BackToTop />
      </div>
    </Section>

    <Section size="small" id="begrip">
      <div className="text-container">
        <div className="subtitle">
          <NL>Meer leren over data en AI</NL>
          <EN>Learn more about data and AI</EN>
        </div>
        <h2 className="text-aqua">
          <NL>Adoptie begint bij begrip</NL>
          <EN>Adoption starts with understanding</EN>
        </h2>
        <p>
          <NL>
            Niet alleen aan de start van je 'AI-reis' is het belangrijk om te weten waar je het over hebt als je het
            over AI hebt. Ook tijdens het ontwikkelen en gebruiken ervan is dit essentieel.
          </NL>
          <EN>
            Not only at the start of your 'AI journey' it's important to know what you mean when talking about AI. This
            is also essential while developing and using it.
          </EN>
        </p>
        <p>
          <NL>
            Weten jouw werknemers, collega's of leidinggevenden waar je mee aan de slag bent of gaat? Begrijpen die wat
            de impact op hen zal zijn? Vergeet hen niet te includeren{' '}
            <strong>voor optimaal begrip en zo ook acceptatie.</strong>
          </NL>
          <EN>
            Do your employees, colleagues or superiors know what you are (planning on) working on? Do they know what the
            impact on them will be? Don't forget to include them{' '}
            <strong>for optimal understanding and acceptation.</strong>
          </EN>
        </p>

        {/* Insert quote van Artis */}
        {/*<h2 className="text-aqua">
          <NL>... en begint bij inspiratie</NL>
          <EN>... and starts with inspiration</EN>
  </h2>*/}
        <h3 className="text-aqua">
          <NL>Van basis- tot specialistische AI-kennis</NL>
          <EN>From basic to specific AI knowledge</EN>
        </h3>
        <p>
          <NL>
            Voor, tijdens of na het ontwikkelen van AI-oplossingen, geven we kennissessies waar nodig en voor wie nodig.{' '}
            <strong>Van het MT tot de data scientists</strong>. Alles wat nodig is zodat jouw organisatie optimale
            waarde uit AI kan halen.
          </NL>
          <EN>
            Before, while and after developing AI solutions, we host knowledge and inpiration sessions when, where and
            for whom necessary. <strong>From the MT to the data scientists.</strong> Everything that's needed so your
            organisation can create optimal value with AI.
          </EN>
        </p>
        <div className="strong my-4 blockquote text-aqua">
          <NL>Bijvoorbeeld:</NL>
          <EN>For example:</EN>
        </div>
        <ul className="list">
          <NL>
            <li>Wat is AI eigenlijk?</li>
            <li>AI in de praktijk: waar moet je op letten?</li>
            <li>Uitlegbare AI</li>
            <li>AI & Privacy</li>
            <li>AI in de [zorg, industrie, media, bedrijfsvoering, ...]</li>
            <li>
              Machine Learning (ML)-technieken als Deep Learning, Active Learning, Federated Learning, Natural Language
              Processing (NLP), Neural Networks, etc.
            </li>
          </NL>
          <EN>
            <li>What is AI exactly?</li>
            <li>AI in practice: what to look out for?</li>
            <li>Explainable AI</li>
            <li>AI & Privacy</li>
            <li>AI in [healthcare, industry, media, administration, ...]</li>
            <li>
              Machine Learning (ML) techniques like Deep Learning, Active Learning, Federated Learning, Natural Language
              Processing (NLP), Neural Networks, etc.{' '}
            </li>
          </EN>
        </ul>
        <div className="my-5">
          <Button size="lg" color="aqua" to="/contact" className="me-3">
            <NL>Plan een kennissessie</NL>
            <EN>Plan a knowledge session</EN>
          </Button>
          <Button size="lg" color="aqua" to="#onderzoek" className="me-3" outline>
            <NL>Aan de slag met je AI-idee</NL>
            <EN>Get started with your AI idea</EN>
          </Button>
        </div>
        <div className="">
          <QuoteCard img={RAGThumb} link={{ nl: '/portfolio/rag', en: '/portfolio/rag' }}>
            <NL>
              "De samenwerking met Landscape overtrof al onze verwachtingen. De resultaten zijn veelbelovend en het meer
              leren over machine learning was erg waardevol voor toekomstige projecten."
            </NL>
            <EN>
              "The cooperation with Landscape exceeded all our expectations. The results are very promising and gaining
              insight in Machine Learning was valuable for future projects."
            </EN>
            <QuoteAttrib name="Isabelle Balzer" org="RAG" />
          </QuoteCard>
        </div>
        <BackToTop />
      </div>
    </Section>

    <Section size="big" id="onderzoek" gradient="green-teal">
      <div className="text-container text-white">
        <div className="subtitle">
          <NL>De diepte in </NL>
          <EN>Dive deeper</EN>
        </div>
        <h1 className="">
          <NL>Onderzoek & Ontwerp jouw AI-aanpak</NL>
          <EN>Determine & Design your AI approach</EN>
        </h1>
        <p>
          <NL>
            Een tof AI-idee is soms niet meer dan dat: 'een tof idee'. Soms is het een idee dat niet realiseerbaar is of
            geen echte toegevoegde waarde zal leveren. Maar soms is dat het wel!
          </NL>
          <EN>
            A cool AI idea is sometimes not more than that: 'a cool idea'. Sometimes it simply can't be realised or add
            the expected value. But sometimes it can!
          </EN>
        </p>
        <p>
          <NL>
            Dit hangt af van een hoop <strong>randvoorwaarden</strong>: je data, je mensen, je infrastructuur, je
            processen, de wet... En als je deze randvoorwaarden vergeet mee te nemen, is de kans op het goed landen van
            een nieuwe AI-oplossing echt een stuk kleiner. En dat is zonde!
          </NL>
          <EN>
            This depends on a lot of <strong>conditions</strong>: your data, your people, your infrastructure, your
            processes, the law... If you forget to take these conditions into account, the chance of a new AI solution
            really adding value is a lot smaller!
          </EN>
        </p>
        <p className="text-center blockquote text-light-green">
          <NL>
            "Het is zonde om tijd en geld in een AI-oplossing te stoppen om er later achter te komen dat het niet
            bruikbaar is."
          </NL>
          <EN>
            "It would be a pity to invest in an AI solution to later discover that it is not applicable in practice."
          </EN>
        </p>
        <p>
          <NL>Daarom is het belangrijk eerst die randvoorwaarden – en de ware uitdaging – te onderzoeken.</NL>
          <EN>That is why it is important to first determine what these conditions – and the true challenge – are.</EN>
        </p>
        <h2>
          <NL>Start met een Solution Design</NL>
          <EN>Start with a Solution Design</EN>
        </h2>
        <p>
          <NL>
            In het Solution Design (SD)-traject duiken we, samen met jou, dieper je organisatie in. Het doel hiervan is
            om een goede schatting te kunnen geven van de omvang van zowel je uitdaging als een mogelijke AI-oplossing.
          </NL>
          <EN>
            In the Solution Design phase, we will – together with you – dive deeper into your organization and
            applicable data science techniques. Its goal is to be able to give a better approximation of the scope of
            your challenge as well as a possible solution.
          </EN>
        </p>
        <p>
          <NL>Aspecten waar we naar kijken, zijn onder andere:</NL>
          <EN>Aspects we dive into, include:</EN>
        </p>
        <span className="text-light-green">
          <NL>
            <ul>
              <li>De betreffende processen</li>
              <li>De data</li>
              <li>De gebruikers, hun gebruik en hun wensen</li>
              <li>Onderliggende theorieën</li>
              <li>Infrastructuur en toegang</li>
              <li>Sector- en vakspecifieke voorwaarden</li>
            </ul>
          </NL>
          <EN>
            <ul>
              <li>The concerning processes</li>
              <li>The data</li>
              <li>The users, their method of use and wishes</li>
              <li>Underlying theories</li>
              <li>Infrastructure and access</li>
              <li>Sector and specialisation specific conditions</li>
            </ul>
          </EN>
        </span>
        {/* <p>
          <NL>Vragen die we zullen stellen, zijn bijvoorbeeld:</NL>
          <EN>Questions that we will ask, include for example:</EN>
        </p>
        <div className="px-0">
          <p className="text-light-green">
            <NL>
              <em>
                Hoe zien je processen er precies uit? Welke data wordt daarin
                gegenereerd? Hoe ziet deze eruit? Hoe wordt deze gebruikt? Wie
                heeft er wanneer toegang toe? Wat voor theorieën liggen eraan
                ten grondslag? Welke kwaliteitsvoorwaarden zijn er? Wie zijn de
                uiteindelijke gebruikers? Welke wensen, zorgen en kennis hebben
                zij? Welke machine-learning-technieken zouden we kunnen
                gebruiken? Wat zouden daarvan de beperkingen (kunnen) zijn? Etc.
              </em>
            </NL>
            <EN>
              <em>
                What do your processes look like exactly? What data is
                generated? What does this look like? How is it used? When is it
                used? Who has access to it? What theories underlie it? What
                quality requirements are there? Who are the end users? What
                wishes, concerns and knowledge do they have? What machine
                learning techniques could we use? What would their limitations
                be?{' '}
              </em>
            </EN>
          </p>
        </div> */}
        <p>
          <NL>
            Op basis hiervan maken we een <strong>plan van aanpak en schatting van de projectomvang</strong>, zodat we
            zo snel mogelijk de meest waardevolle, haalbare ideeën kunnen omzetten naar een werkend product.
          </NL>
          <EN>
            Based on these insights we can make a <strong>plan of action and project estimate</strong> so we can
            transform the most valuable, realistic ideas into working products as quickly as possible.
          </EN>
        </p>
        <div className="my-5">
          <Button size="lg" color="white" to="/contact" className="me-3 text-teal">
            <NL>Plan een intake</NL>
            <EN>Plan an intake</EN>
          </Button>
          <Button size="lg" color="white" to="#haalbaarheid" className="me-3" outline>
            <NL>Met een PoC beginnen?</NL>
            <EN>Start with a PoC?</EN>
          </Button>
        </div>
        <BackToTop />
      </div>
    </Section>

    <Section id="haalbaarheid" size="small">
      <div className="text-container">
        <div className="subtitle">
          <NL>Haalbaarheid is veelzijdig</NL>
          <EN>Feasibility knows many forms</EN>
        </div>
        <h2 className="text-teal">
          <NL>Direct met een PoC beginnen?</NL>
          <EN>Start with a PoC directly?</EN>
        </h2>
        <p>
          <NL>
            Haalbaarheid heeft niet alleen maar met je data en technieken te maken. Het is van heel veel meer
            afhankelijk. Wij zien veel te vaak dat er direct de data ingedoken wordt zonder plan. Om te kijken of er
            leuke dingen uitgehaald kunnen worden. Om te kijken of een techniek werkt, zonder na te denken over of deze
            überhaupt uiteindelijk van toegevoegde waarde zal zijn. Onze tip?
          </NL>
          <EN>
            Feasibility doesn't only depend on your data and techniques. It depends on a lot more. We see way too often
            that organisations start wrangling data without a plan. To see if they can get cool insights from them
            randomly. Or to see whether a technique works, without thinking about it's application in the real the real
            world. Our tip?
          </EN>
        </p>
        <div className="text-center text-teal my-4 blockquote strong">
          <NL>Begin niet zomaar met data te stoeien.</NL>
          <EN>"Don't just start data wrangling."</EN>
        </div>
        <p>
          <NL>
            Identificeer eerst wat de échte uitdaging en de randvoorwaarden van je bestaande processen, mensen en
            infrastructuur zijn. Daarná kan je lekker aan de slag met je data.
          </NL>
          <EN>
            First identify what the conditions of your processes, people and infrastructure are. *Then* you can start
            playing with your data.
          </EN>
        </p>
        <div className="my-5">
          <Button size="lg" color="teal" to="#ontwikkel" className="me-3">
            <NL>Haalbaar? Let's code!</NL>
            <EN>Feasible? Let's code!</EN>
          </Button>
          <Button size="lg" color="teal" to="/contact" outline>
            <NL>Niet mee eens? Let's talk :)</NL>
            <EN>Don't agree? Let's talk :)</EN>
          </Button>
        </div>
        <div className="">
          <QuoteCard img={ZooThumb} link={{ nl: '/portfolio#artis', en: '/portfolio#artis' }}>
            <NL>
              "Landscape heeft goed met ons meegedacht en rekening gehouden met het karakter van onze organisatie. Wij
              van ARTIS zijn erg blij met de expertise die Landscape ingebracht heeft om data in heel de organisatie
              voorop te zetten."
            </NL>
            <EN>
              "Landscape thought along well with us, always keeping the character of our organisation into account. We
              of ARTIS are really happy with the expertise that Landscape brought to put data at the forefront of our
              organisation."
            </EN>
            <QuoteAttrib name="Janne Lambermont" org="ARTIS" />
          </QuoteCard>
        </div>
        <BackToTop />
      </div>
      {/*<div className="col-lg-4 d-flex flex-lg-column align-items-center justify-content-around">
            <div style={{ maxWidth: '100px', opacity: 0.5 }}>
              <img
                className="img-fluid"
                src={Python}
                alt="Python"
                title="Python"
              />
            </div>
            <div style={{ maxWidth: '100px', opacity: 0.5 }}>
              <img
                className="img-fluid"
                src={Tensorflow}
                alt="Tensorflow"
                title="Tensorflow"
              />
            </div>
            <div style={{ maxWidth: '100px', opacity: 0.5 }}>
              <img
                className="img-fluid"
                src={Plotly}
                alt="Plotly"
                title="Plotly"
              />
            </div>
            <div style={{ maxWidth: '100px' }}>
              <img
                className="img-fluid"
                src={Docker}
                alt="Docker"
                title="Docker"
              />
            </div>
          </div> */}
    </Section>

    <Section size="big" id="ontwikkel" gradient="green-dark-green">
      <div className="text-container text-white">
        <div className="subtitle">
          <NL>Aan de slag met algoritmes</NL>
          <EN>Start coding</EN>
        </div>
        <h1 className="">
          <NL>Ontwikkel waardevolle AI-oplossingen</NL>
          <EN>Develop valuable AI solutions</EN>
        </h1>
        <p>
          <NL>
            Overtuigd van het plan? Onze data scientists gaan graag met je aan de slag. Samen met jouw team ontwikkelen
            we de AI- en machine-learning-modellen die je nodig hebt. Dat doen we{' '}
            <strong>
              op een agile manier, gericht op efficiënt werken aan de oplossing die de meeste waarde biedt
            </strong>
            . Van proof of concept naar werkende AI-implementatie. Altijd met een oog op veilig en fijn gebruik.
          </NL>
          <EN>
            Convinced by the the plan? Our data scientists can't wait to get to work for and with you. Together with
            your team – from business to engineers and also data scientists – we develop the AI and ML models that you
            need. We do this{' '}
            <strong>
              in an agile way, so we efficiently work on the solutions that create the most value with the least risk
            </strong>
            . From proof of concept to operational AI implementation. Always with an eye on safe and friendly use!
          </EN>
        </p>
        <h2>
          <NL>Met 'state of the art' data-science</NL>
          <EN>With state of the art data science</EN>
        </h2>
        <p>
          <NL>
            Hoewel jong van geest, draaien we met Landscape (2014) al best een tijd mee. We hebben ervaring met
            verschillende typen data (tekstdata, sensordata, beelddata, …) en werken met verschillende
            data-science-technieken die in meerdere sectoren in te zetten zijn, zoals:
          </NL>
          <EN>
            While we are young of heart, we have been working in data science for quite some time. We have experience
            with different types of data (text, sensor, image, ...) and work with different data science techniques that
            are applicable in multiple sectors, like:{' '}
          </EN>
        </p>
        <ul>
          <li>
            <Link to="https://en.wikipedia.org/wiki/Explainable_artificial_intelligence">Explainable AI</Link>
          </li>
          <li>
            <Link to="https://en.wikipedia.org/wiki/Active_learning_(machine_learning)">Active Learning</Link>
          </li>
          <li>
            <Link to="https://en.wikipedia.org/wiki/Federated_learning">Federated Learning</Link>
          </li>
          <li>
            <Link to="https://en.wikipedia.org/wiki/Deep_learning">Deep Learning</Link>
          </li>
          <li>
            <Link to="https://en.wikipedia.org/wiki/Reinforcement_learning">Reinforcement Learning</Link>
          </li>
          <li>
            <Link to="https://en.wikipedia.org/wiki/Natural_language_processing">
              Natural Language Processing (NLP)
            </Link>
          </li>
          <li>
            en andere vormen van <Link to="https://en.wikipedia.org/wiki/Machine_learning">Machine Learning</Link>
          </li>
        </ul>
        <h2>
          <NL>Zonder overbodige complexiteit</NL>
          <EN>Without unnecessary complexity</EN>
        </h2>
        <p>
          <NL>
            Hoe tof we het ook vinden om met de nieuwste en coolste technieken te werken, vinden we het belangrijker dat
            je zo snel en zo simpel mogelijk krijgt wat je nodig hebt.{' '}
            <strong>Ingewikkelder is namelijk zeker niet altijd beter.</strong> Wij zullen altijd simpelere en
            complexere technieken vergelijken en je adviseren over wat de beste aanpak is.
          </NL>
          <EN>
            Even while we love working with the newest and coolest techniques, we find it more important that you get
            the solutions you need as simply and efficiently as possible.{' '}
            <strong>More complex is not always better!</strong> We will always compare simpler and complexer techniques
            and advise you on the best approach.
          </EN>
        </p>
        <div className="my-5">
          <Button size="lg" color="white" to="#maatwerk" className="me-3 text-green">
            <NL>Standaard-oplossing of maatwerk?</NL>
            <EN>Over the counter or custom work?</EN>
          </Button>
          <Button size="lg" color="white" to="/contact" outline className="hover-text-aqua">
            <NL>Ik wil dit!</NL>
            <EN>I want this!</EN>
          </Button>
        </div>
        <BackToTop />
      </div>
    </Section>
    <Section id="maatwerk" size="small">
      <div className="text-container">
        <div className="subtitle">
          <NL>"One size does not fit all"</NL>
          <EN>"One size does not fit all"</EN>
        </div>
        <h2 className="text-green">
          <NL>Maatwerk voor echte waarde</NL>
          <EN>Custom solutions for true value</EN>
        </h2>
        {/*  Eva's stuff really doesn't work anymore*/}
        <p>
          <NL>
            Zoals we vaker zeggen: <strong>"AI is niet slim."</strong> Ook de meeste 'state-of-the-art' technieken
            zorgen ervoor dat je een computer slechts hele specifieke taken aan kunt leren. Op basis van specifieke
            situaties en specifieke data. En gezien elke organisatie anders is, andere processen heeft en andere data
            bezit, is het heel lastig (als het al legaal is) om modellen 1-op-1 te hergebruiken.
          </NL>
          <EN>
            Like we say often: <strong>"AI is not smart."</strong> Even most of the 'state of the art' techniques can
            only teach a computer to learn very specific tasks. Based on specific situations and specific data. And
            since every organisation is different, has different processes and possesses different data, it is hard (if
            it is even legal) to reuse existing models 1-on-1.
          </EN>
        </p>
        <p>
          <NL>
            Daarom zullen we altijd <strong>maatwerk</strong> leveren. Zodat je alleen maar krijgt waar je wat aan hebt.
          </NL>
          <EN>
            That is why we will always deliver <strong>custom solutions</strong>. So you only get what completely suits
            you.
          </EN>
        </p>
        <h3 className="text-green">
          <NL>Met brede kennis die we kunnen hergebruiken</NL>
          <EN>With thorough knowledge we can reuse</EN>
        </h3>
        <p>
          <NL>
            Doordat we de afgelopen tijd al heel wat vraagstukken hebben opgelost in verschillende sectoren, hebben we
            een brede kennisset en 'toolkit' opgebouwd. Deze kunnen we op verschillende manieren inzetten om te bereiken
            wat jij nodig hebt.
          </NL>
          <EN>
            By solving quite some challenges in different sectors over the last years, we have built up a broad
            knowledge set and toolkit. Best practices, learnings and code that we can use to help you develop what you
            need.
          </EN>
        </p>
        <h3 className="text-green">
          <NL>Voor het verantwoord tackelen van nieuwe uitdagingen</NL>
          <EN>To responsibly tackle new challenges</EN>
        </h3>
        <p>
          <NL>
            Daarnaast houden we ervan om onszelf altijd uit te blijven dagen en nemen we geen genoegen met 'het werkt
            dus het zal wel goed gaan'. Wij vinden <strong>uitlegbaarheid</strong> erg belangrijk en zullen dus altijd
            doorwerken, totdat we elke techniek grondig beheersen.
          </NL>
          <EN>
            Besides that we love to challenge ourselves and never settle for 'it works so it must suffice'. We really
            value <strong>explainability</strong> and will always continue until we master every technique.
          </EN>
        </p>
        <p>
          <NL>En daar kan jij van profiteren!</NL>
          <EN>Which you can profit from!</EN>
        </p>
        <div className="my-5">
          <Button size="lg" color="green" to="/contact" className="me-3">
            <NL>Ja? Let's talk!</NL>
            <EN>Yes? Let's talk!</EN>
          </Button>
          <Button size="lg" color="green" to={{ nl: '/portfolio', en: '/portfolio' }} className="me-3" outline>
            <NL>Bekijk ons portfolio</NL>
            <EN>Check out our portfolio</EN>
          </Button>
          <Button size="lg" color="green" to={{ nl: '/over-ons', en: '/about-us' }} outline>
            <NL>Leer ons beter kennen</NL>
            <EN>Get to know us better</EN>
          </Button>
        </div>
        <div className="">
          <QuoteCard img={WorkshopThumb} link="/portfolio" backgroundPosition="right 25%">
            <NL>
              "Met de hulp van Landscape krijgen de ondersteunende diensten van Universiteit Leiden grip op concrete
              mogelijkheden om AI en overige Data Science technieken in te zetten. Ze bieden een uitstekende dienst in
              het helpen opstellen van business cases voor het daadwerkelijk implementeren van projecten!"
            </NL>
            <EN>
              "With Landscape's help service centers of Leiden University got a grip on concrete opportunities for
              implementing AI and data science in their work. Landscape does a good job in helping you formulate
              business cases for actually bringing AI into practice."
            </EN>
            <QuoteAttrib
              name="Pim Overgaauw"
              org={
                <>
                  <NL>Universiteit Leiden</NL>
                  <EN>Leiden University</EN>
                </>
              }
            />
          </QuoteCard>
        </div>
        <BackToTop />
      </div>
    </Section>
  </Page>
)

export default Services
