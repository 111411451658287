// @flow
import * as React from 'react'
import './ClientLogo.scss'

type Props = {
  src: string,
  name: string,
  className?: string,
  style?: Object,
}

export const ClientLogo = ({
  name,
  src,
  style = {},
  className = '',
  ...props
}: Props): React.Element<'div'> => (
  <div
    {...props}
    className={`client-logo ${className}`}
    title={name}
    style={{ backgroundImage: `url(${src})`, ...style }}
  />
)
