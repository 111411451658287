// @flow
import * as React from 'react'
import { Hero, HeroTitle, HeroBody, Section, WallpaperSection, Button, Page, NL, EN } from '../../components'

import Traffic from '../../assets/traffic.jpg'
import { CHDRShort as CHDR } from './CHDR'
import { PallasShort as Pallas } from './Pallas'
import { DHDShort as DHD } from './DHD'
import { PseuduckShort as Pseuduck } from './Pseuduck'

const PortfolioZorg = (): React.Node => (
  <Page
    title={{ nl: 'Portfolio Zorg', en: 'Portfolio Healthcare' }}
    description={{
      nl: 'We helpen onder andere zorgorganisaties data-gedreven oplossingen te realiseren met AI, om werkdruk te verlichten en wachttijden te verkorten',
      en: 'We help healthcare organisations move from ideas to data-driven results with AI and Machine Learning, to lighten the workload and shorten waiting lines.',
    }}
    location={{ nl: '/portfolio-zorg', en: '/portfolio-healthcare' }}
  >
    <Hero>
      <HeroTitle>
        <NL>
          Ons eerdere werk <br /> <span className="text-blue">in de zorg</span>
        </NL>
        <EN>
          Our previous work <br /> <span className="text-blue">in healthcare</span>
        </EN>
      </HeroTitle>
      <HeroBody>
        <NL>
          <p>
            Door de jaren heen hebben wij gewerkt met veel verschillende typen organisaties. De overeenkomst? De wens om{' '}
            <span className="strong text-blue">beter en slimmer</span> hun werk te kunnen doen.{' '}
            <span className="strong text-blue">Met data, machine learning en AI.</span>
          </p>
          <p>
            Lees hier hoe wij organisaties in de <span className="strong text-blue">Gezondheid en Zorg</span> hielpen!
          </p>
        </NL>
        <EN>
          <p>
            Over the years we have worked with quite a variety of organisations. What they had in common? The wish to do
            their <span className="strong text-blue">work better and smarter</span>.{' '}
            <span className="strong text-blue">With data, machine learning and AI.</span>
          </p>
          <p>
            Read below how we helped organisations in <span className="strong text-blue">healthcare</span>!
          </p>
        </EN>
        <Button color="blue" to="#intro" className="me-3">
          <NL>Lees verder!</NL>
          <EN>Read on!</EN>
        </Button>
        <Button color="blue" outline to={{ nl: '/portfolio', en: '/portfolio' }} className="me-3">
          <NL>Terug naar alle projecten</NL>
          <EN>Back to all projects</EN>
        </Button>
      </HeroBody>
    </Hero>

    <WallpaperSection size="small" wallpaper={Traffic}>
      <div className="text-container text-white">
        <h1>
          <NL>
            "Geen <span className="text-light-blue">robots</span> aan het bed, a.u.b."
          </NL>
          <EN>
            "No <span className="text-light-blue">robots</span> at the bed, please"
          </EN>
        </h1>
      </div>
    </WallpaperSection>

    {/* <WallpaperSection size="small" wallpaper={Traffic}>
      <div className="text-container text-white">
        <h3>
          <NL>
            Laat onze case studies je inspireren voor jullie volgende stap met
            data en AI.{' '}
            <span className="text-light-green">
              Wil je direct jouw eigen casus bespreken?
            </span>
          </NL>
          <EN>
            Let our previous work inspire you to take your next step with data
            and AI.{' '}
            <span className="text-light-green">
              Want to start tackling your own case now?
            </span>
          </EN>
        </h3>
        <div className="text-end mt-3">
          <Button color="white" outline to="/contact">
            <NL>Neem contact op!</NL>
            <EN>Contact us!</EN>
          </Button>
        </div>
      </div>
    </WallpaperSection> */}

    <Section size="small" id="intro">
      <div className="text-container">
        <div className="subtitle">
          <NL>AI in de Gezondheid en Zorg</NL>
          <EN>AI in Healthcare</EN>
        </div>
        <h2 className="text-blue mb-3">
          <NL>Werkdruk verlagen en wachtrijen verkleinen</NL>
          <EN>Reducing workload and waiting lines </EN>
        </h2>
        <p>
          <NL>
            Het eerste waar veel mensen aan denken bij ondersteuning door AI in de zorg, is het robotje aan het bed. De
            machines die helpen met verschonen, thee brengen, metingen doen, etc. Maar dat zijn nou juist de gebieden
            waar de patiënt menselijk contact waardeert.
          </NL>
          <EN>
            The first thing that comes to mind when talking about 'AI-support in healthcare' is often the robot at the
            hospital bed. The machines that help changing sheets, bring tea, perform measurements, etc. But let those be
            the things that patients prefer to be done by people.
          </EN>
        </p>
        <h3 className="text-dark-blue">
          <NL>Door AI te laten helpen met de administratie</NL>
          <EN>By letting AI help with the administration</EN>
        </h3>
        <p>
          <NL>
            De taken waar AI juist heel goed bij kan helpen, en waar de gemiddelde zorgmedewerker niet op zit te
            wachten, zijn de administratieve taken. Hier gaat het namelijk vaak om{' '}
            <span className="strong text-blue">herhaling</span> én is er sprake van enige{' '}
            <span className="strong text-blue">standaardisatie</span>. Daarbij zijn er ook nog eens ontzettend veel
            patiënten die ontzettend <span className="strong text-blue">veel data</span> genereren. Ideaal voor AI dus.
          </NL>
          <EN>
            Tasks that AI is particularly good in, and which most healthcare workers don't really enjoy, are the
            administrative tasks. These usually concern <span className="strong text-blue">repetition</span> ánd a
            certain level of <span className="strong text-blue">standardisation</span>. Besides that there are a lot of
            patients that generate <span className="strong text-blue">a lot of data</span>. Ideal for AI.
          </EN>
        </p>
        <h3 className="text-dark-blue">
          <NL>En het herkennen van patronen</NL>
          <EN>And recognizing patterns</EN>
        </h3>
        <p>
          <NL>
            Computers zijn veel beter dan mensen in het snel (ver)werken en het herkennen van patronen in grote
            hoeveelheden data. Deze krachten kan je inzetten om je eigen domeinexpertise schaalbaarder te maken en aan
            te vullen.{' '}
          </NL>
          <EN>
            Computers are a lot better than people at doing tasks quickly and recognizing patterns in large amounts of
            data. These strengths you can utilise to enhance and expand your domain expertise.
          </EN>
        </p>
        <p>
          <NL>
            Of dit nu gaat om het afleiden van een <span className="strong text-blue">diagnose</span> uit teksten, het
            vinden van de juiste <span className="strong text-blue">aanpak</span> voor een patiënt of om het{' '}
            <span className="strong text-blue">voorspellen</span>
            van pieken in de werkdruk: AI kan je hierbij helpen.
          </NL>
          <EN>
            Whether it concerns deriving <span className="strong text-blue">diagnoses</span> from texts, finding the
            right <span className="strong text-blue">treatment</span> for a patient or{' '}
            <span className="strong text-blue">predicting</span> spikes in the workload: AI can help you with this.
          </EN>
        </p>
        <h3 className="text-dark-blue">
          <NL>Op een veilige manier</NL>
          <EN>In a safe way</EN>
        </h3>
        <p>
          <NL>
            En ja, dit kan ook met de gevoelige data in patiëntendossiers. Zo hebben we een{' '}
            <span className="strong text-blue">pseudonimisatie-tool</span> ontwikkeld die gespecialiseerd is in
            Nederlandse zorgteksten en zetten we <span className="strong text-blue">Federated Learning</span> in om van
            data van verschillende ziekenhuizen te werken zonder deze data te verplaatsen.
          </NL>
          <EN>
            And yes, this can indeed also be done with sensitive data in patient dossiers. In order to do this, we have
            developed a <span className="strong text-blue">pseudonymisation tool</span> that is specialised in (Dutch)
            healthcare texts and we use <span className="strong text-blue">Federated Learning</span> to learn from data
            from different hospitals, without moving the data.{' '}
          </EN>
        </p>
      </div>
      <div className="text-container mt-5">
        <h4 className="strong">
          <NL>Lees bijvoorbeeld meer over:</NL>
          <EN>Read more about for example:</EN>
        </h4>
        <Button className="me-3" color="blue" to="#dhd">
          <NL>ICD-10-codes afleiden</NL>
          <EN>Deriving ICD codes</EN>
        </Button>
        <Button className="me-3" color="blue" to="#chdr">
          <NL>Sensoren inzetten</NL>
          <EN>Using sensors</EN>
        </Button>
        <Button className="me-3" color="blue" to="#pseuduck">
          <NL>Dossiers anonimiseren</NL>
          <EN>Anonymising dossiers</EN>
        </Button>
        <Button className="me-3" color="blue" to="#selectical">
          <NL>Literatuur selecteren</NL>
          <EN>Selecting literature</EN>
        </Button>
      </div>
    </Section>

    <Section size="small" className="text-white" gradient="teal-blue" id="dhd">
      <div className="text-container">
        <DHD />
      </div>
    </Section>

    <Section size="small" className="text-container" id="chdr">
      <CHDR />
    </Section>

    <Section size="small" className="text-white" gradient="teal-blue" id="pseuduck">
      <div className="text-container">
        <Pseuduck />
      </div>
    </Section>

    <Section size="small" className="text-container" id="selectical">
      <Pallas />
    </Section>
  </Page>
)
export default PortfolioZorg
