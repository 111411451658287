// @flow
import * as React from 'react'
import { EN, NL, InsightsPage, Button } from '../../../components'
import John from '../../../assets/team/john.jpg'
import Photo from '../../../assets/insights/digital-twin.png'
import Thumbnail from '../../../assets/insights/interview-john.jpg'

import type { StoryMetadata } from '../index'

const title = {
  nl: 'Een Revolutie in de Industrie: De Synergie van Digital Twins en AI',
  en: 'Revolutionizing Industry: The Synergy of Digital Twins and AI',
}

const description = {
  nl: "Naarmate simulaties van levensechte scenario's geavanceerder en gebruikelijker worden, kunnen we systemen gaan ontwerpen en onderzoeken - digitaal - op manieren die voorheen onmogelijk waren. Door deze simulaties (een soort 'digital twin') te integreren met kunstmatige intelligentie (AI) ontstaan spannende nieuwe mogelijkheden. Samen zullen ze hele industrieën veranderen en nieuwe mogelijkheden creëren voor efficiëntere, duurzamere en innovatievere praktijken.",
  en: "As simulations of real-life scenarios become more advanced and more common, we can start to design systems and investigate them — digitally — in ways that would have previously been impossible. By integrating these simulations (a type of 'digital twin') with artificial intelligence (AI), exciting new possibilities emerge. Working together, they will change entire industries and open the door to more efficient, sustainable, and innovative practices.",
}

export const metadata: StoryMetadata = {
  slug: 'revolutionizing-industry-the-synergy-of-digital-twins-and-ai',
  title,
  description,
  date: '2024-01-20',
  published: true,
  thumbnail: Thumbnail,
}

export default function Story(): React.Node {
  return (
    <InsightsPage
      metadata={metadata}
      hero={{
        date: metadata.date,
        subtitle: (
          <>
            <NL>Een interview met John Gunn</NL>
            <EN>An interview with John Gunn</EN>
          </>
        ),
        title: (
          <span className="text-blue">
            <span className="text-dark-blue">
              <NL>Een Revolutie in de Industrie:</NL>
              <EN>Revolutionizing Industry:</EN>
            </span>
            <br />
            <NL>De Synergie van Digital&nbsp;Twins en&nbsp;AI</NL>
            <EN>The Synergy of Digital&nbsp;Twins and&nbsp;AI</EN>
          </span>
        ),
        description: (
          <>
            <NL>{description.nl}</NL>
            <EN>{description.en}</EN>
          </>
        ),
      }}
      quote={{
        quote: (
          <>
            <NL>
              Door nauw samen te werken en klanten te helpen begrijpen hoe ze de beste data kunnen leveren, hebben we
              geweldige resultaten gezien.
            </NL>
            <EN>
              By working closely together with our clients, and helping them to understand how to deliver the best data,
              we've seen great results.
            </EN>
          </>
        ),
        name: 'John Gunn',
        img: John,
        subtitle: (
          <>
            <NL>Van onze data scientist & consultant John</NL>
            <EN>From our data scientist & consultant John</EN>
          </>
        ),
      }}
      image={{
        src: Photo,
        alt: '"Digital Twin" by DALL-E',
      }}
    >
      <h3 className="mt-5 mb-3 text-dark-green">
        <NL>Wat zijn Digital Twins?</NL>
        <EN>What Are Digital Twins?</EN>
      </h3>
      <p>
        <NL>
          Een digital twin is een virtuele kopie van een fysiek systeem of object dat het gedrag van het systeem en alle
          veranderingen die in de echte wereld plaatsvinden nauwkeurig weerspiegelt. John Gunn, datawetenschapper en
          consultant bij Landscape, legt uit: "Digitale tweelingen helpen ons om inzichten af te leiden over de fysieke
          entiteit en experimenten uit te voeren in een virtuele omgeving zonder reële risico's." Ze maken het mogelijk
          om echte en hypothetische situaties te testen, met snelheden die ordes van grootte sneller zijn dan in het
          echte leven.
        </NL>
        <EN>
          A digital twin is a virtual copy of a physical system or object that closely mirrors the behaviour of the
          system and reflects any changes that happen in the real world. John Gunn, data scientist and consultant at
          Landscape, explains: "Digital twins help us to deduce insights about the physical entity and carry out
          experiments in a virtual environment without any real-life risks." They allow testing of real and hypothetical
          situations, orders of magnitude faster than real life.
        </EN>
      </p>

      <h3 className="mt-5 mb-3 text-dark-green">
        <NL>De Kracht van AI en Digital Twins</NL>
        <EN>The Power of AI and Digital Twins</EN>
      </h3>
      <p>
        <NL>
          De digitale, gesimuleerde aard van de digital twin maakt het een uniek en krachtig oefenterrein voor AI.
          Meestal wordt een AI model getraind met data uit het verleden, maar er zijn veel gevallen waar zulke data niet
          beschikbaar is. In die situaties is het mogelijk om toch een AI te trainen, door het direct te laten werken
          met de digital twin en te simuleren wat er vervolgens zou gebeuren. Het trainen van een AI op deze manier,
          waarbij het moet leren van de (gesimuleerde) gevolgen van zijn eigen acties, heet Reinforcement Learning.
          John: "De primaire kracht van AI ligt in het vermogen om patronen te herkennen, voorspellingen te doen en van
          fouten te leren." Het kan patronen identificeren in het gedrag van de digitale tweeling, op basis van de
          resultaten van zijn eigen acties. Deze combinatie geeft een uniek voordeel: de AI kan optimalere, vaak nieuwe
          strategieën vinden die onmogelijk te vinden zouden zijn als het alleen getraind zou zijn op historische
          gegevens.
        </NL>
        <EN>
          The digital, simulated nature of the digital twin makes it a unique and powerful training ground for AI.
          Usually, an AI model gets trained using data from the past, but there are many cases where such data is not
          available. In such situations, it is possible to nevertheless train an AI, by having it directly interact with
          the digital twin, and simulating what would happen. Training an AI like this, where it must learn from the
          (simulated) consequences of its own actions, is called Reinforcement Learning. John: "AI's primary strength
          lies in its ability to recognize patterns, make predictions, and learn from mistakes." It can identify
          patterns in the behaviour of the digital twin, from the results of its own actions. This combination gives a
          unique advantage: the AI can find more optimal, often novel strategies, that would be impossible to find if it
          were trained only historical data.
        </EN>
      </p>

      <h3 className="mt-5 mb-3 text-dark-green">
        <NL>Landscape's Innovatieve Gebruik van Digital Twins</NL>
        <EN>Landscape's Innovative Use of Digital Twins</EN>
      </h3>
      <p>
        <NL>
          Landscape heeft digital twin-technologie en kunstmatige intelligentie ingezet in verschillende industrieën.
          Een bijzonder voorbeeld is de creatie van een digital twin voor een stadsverwarmingsnetwerk in Duitsland. John
          legt uit: "We hebben een digitaal model van het warmtenet ontwikkeld, waarmee we de warmtebronnen,
          waterstromen en warmteconsumptie van een stad en een ziekenhuis konden simuleren. Deze simulatie stelt ons in
          staat om te experimenteren met verschillende operationele strategieën, zonder risico's voor het echte netwerk.
          Het stelt ons ook in staat om een willekeurig aantal randgevallen te simuleren en een jarenlange werking in
          een fractie van de tijd te modelleren."
        </NL>
        <EN>
          Landscape have utilized digital twin technology and artificial intelligence across various industries. A
          notable example of their work involves the creation of a digital twin for a district heating network in
          Germany. John explains, "We developed a digital model of the heat network, which enabled us to simulate the
          heat sources, water flow, and heat consumption by a city and a hospital. This simulation allows us to
          experiment with different operational strategies, without any risks to the real-life network. It also enables
          us to simulate any number of edge cases, and model years worth of operation in a fraction of the time."
        </EN>
      </p>

      <div
        className="mx-auto my-3 border border-blue position-relative bg-transparent-blue small d-flex"
        style={{ minWidth: '45%' }}
      >
        <div className="d-flex p-3">
          <div className="d-flex flex-column me-4 gap-2">
            <span className="title-font text-dark-blue fs-5">
              <NL>We leerden een AI een energiecentrale aansturen</NL>
              <EN>We Taught An AI To Run A Power Plant</EN>
            </span>
            <span className="lh-sm">
              <NL>Ontdek hoe we dat hebben gedaan</NL>
              <EN>Discover how we did it</EN>
            </span>
          </div>
        </div>
        <Button
          to={{ nl: '/portfolio-industrie#ekusim', en: '/portfolio-industry#ekusim' }}
          className="align-self-end btn-dark-blue text-white stretched-link ms-auto my-0 p-1 pb-0 lh-1"
          noArrow
        >
          <i className="material-icons lh-1" style={{ height: '27px' }}>
            arrow_forward
          </i>
        </Button>
      </div>

      <h3 className="mt-5 mb-3 text-dark-green">
        <NL>Baanbrekende Resultaten in Efficiëntie van Stadswarmte</NL>
        <EN>Breakthrough Results in District Heating Efficiency</EN>
      </h3>
      <p>
        <NL>
          John beschrijft hoe Landscape's digitale tweelingproject van een stadsverwarmingsnetwerk zeer waardevolle
          resultaten opleverde. "De simulaties die in de twin worden gebruikt hebben een zeer hoge getrouwheid, waardoor
          een fijnmazige controle van de gedetailleerde onderdelen van het stadsverwarmingssysteem mogelijk is. Het
          resultaat van het project is een AI-gestuurd regelsysteem dat het verwarmingssysteem dynamisch aanstuurt om te
          reageren op veranderende eisen van eindgebruikers." Het maakt gebruik van slimme operationele strategieën die
          niet alleen mensen en gebouwen van warmte voorzien, maar ook het energieverbruik optimaliseren, de uitstoot
          van broeikasgassen verminderen en voorkomen dat het netwerk onnodig onder druk komt te staan. Het beperken van
          belasting is vooral waardevol bij componenten voor warmteopslag, die notoir moeilijk optimaal te bedienen
          zijn. Het project toont het potentieel van digital twins en AI om een intelligentere en duurzamere toekomst
          voor stedelijke infrastructuur te creëren.
        </NL>
        <EN>
          John describes how Landscape's digital twin project of a district heating network produced very valuable
          results. "The simulations used in the twin have very high fidelity, allowing fine-grained control of the
          detailed parts of the district heating system. The result of the project is an AI-driven control system that
          dynamically controls the heating system to respond to changing end-user demands." It uses smart operational
          strategies that not only provide people and buildings with heat, but also optimizes energy use, reduces green
          house gas emissions, and limits the network from being exposes to unnecessary stress. Limiting stresses is
          especially valuable in components like heat storage units, which are notoriously difficult to operate
          optimally. The projects demonstrates the potential for digital twins and AI to create more intelligent and
          sustainable future for urban infrastructures.
        </EN>
      </p>

      <h3 className="mt-5 mb-3 text-dark-green">
        <NL>Uitdagingen en Risico's Overwinnen</NL>
        <EN>Overcoming Challenges and Risks</EN>
      </h3>
      <p>
        <NL>
          Het combineren van digital twins en AI heeft een enorm potentieel, maar zoals bij elk huwelijk zijn er
          uitdagingen. John benadrukt dat een van de grootste uitdagingen de kwaliteit en getrouwheid van de simulatie
          is. Wil het AI-model bruikbaar zijn in de praktijk, dan moet de digitale tweeling voldoende representatief
          zijn. Anders zal de AI alleen leren om de tekortkomingen van de simulatie uit te buiten, in plaats van
          werkelijke patronen in de echte wereld op te pikken. Dit kwaliteitsniveau vereist nauwkeurige gegevens over de
          onderdelen van het gesimuleerde systeem. Daarom houdt Landscape een open dialoog over de gegevens, de
          simulatie en hoe bruikbaar de AI-uitkomsten zijn. "Door nauw samen te werken en klanten te helpen begrijpen
          hoe ze de beste data kunnen leveren, hebben we geweldige resultaten gezien."
        </NL>
        <EN>
          The combination of digital twins and AI has immense potential, but as with any marriage, there are challenges.
          John highlights that one of the biggest challenges is the quality and fidelity of the simulation. In order for
          the AI model to be useful in real-life, the digital twin needs to be sufficiently representative. Otherwise,
          the AI will only learn to exploit the shortcomings of the simulation, rather than picking up on actual
          real-world patterns. This level of quality requires accurate data on the parts that make up the system being
          simulated. Obtaining such data can be challenging, which is why Landscape keeps an open dialogue on the data,
          the simulation and how useful the AI outcomes are. "By working closely together and helping clients to
          understand how to deliver the best data, we've seen great results."
        </EN>
      </p>
      <p>
        <NL>
          De kracht en mogelijkheden van AI en digital twins zijn met succes aangetoond, maar de grootschalige
          implementatie van AI-technologie brengt bepaalde risico's met zich mee. Net als mensen kunnen AI-systemen
          fouten maken, falen en gevaarlijke situaties creëren. Maar in tegenstelling tot mensen kan een AI alleen
          begrijpen waar het op getraind is en kan er niet altijd gevraagd worden waarom het een bepaalde beslissing
          heeft genomen. "We moeten altijd streven naar een verantwoord gebruik van AI," benadrukt John. "We weten hoe
          we de risico's kunnen beperken die gepaard gaan met het loslaten van de kracht van AI in de echte wereld. Maar
          je moet het wel goed doen."
        </NL>
        <EN>
          The power an possibilities of AI and digital twins have been successfully showcased, but the large scale
          implementation of AI technology carries certain risks. Just like humans, AI systems can make mistakes, fail
          and create dangerous situations. But unlike humans, an AI can only understand what it is trained on and it
          cannot always be questioned on why it made a certain decision. "We must always aim for responsible use of AI,"
          John emphasizes. "We know how to mitigate the risks that come with unleashing the power of AI in the real
          world. But you have to get it right."
        </EN>
      </p>

      <div
        className="mx-auto my-3 border border-blue position-relative bg-transparent-blue small d-flex"
        style={{ minWidth: '45%' }}
      >
        <div className="d-flex p-3">
          <div className="d-flex flex-column me-4 gap-2">
            <span className="title-font text-dark-blue fs-5">
              <NL>Wij hebben een uitgesproken mening over wat goed en verantwoord AI gebruik is...</NL>
              <EN>We have a pretty strong opinion on what is good and responsible AI use...</EN>
            </span>
            <span className="lh-sm">
              <NL>Lees waar wij in geloven</NL>
              <EN>Read what we believe</EN>
            </span>
          </div>
        </div>
        <Button
          to={{ nl: '/portfolio-industrie#ekusim', en: '/portfolio-industry#ekusim' }}
          className="align-self-end btn-dark-blue text-white stretched-link ms-auto my-0 p-1 pb-0 lh-1"
          noArrow
        >
          <i className="material-icons lh-1" style={{ height: '27px' }}>
            arrow_forward
          </i>
        </Button>
      </div>

      <h3 className="mt-5 mb-3 text-dark-green">
        <NL>De Toekomst voor AI en Digital Twins</NL>
        <EN>The Future for AI and Digital Twins</EN>
      </h3>
      <p>
        <NL>
          De toekomst ziet er rooskleurig uit voor het gecombineerde gebruik van digital twins en AI. John is ervan
          overtuigd dat we krachtige innovaties zullen zien op het gebied van operational excellence, automatisering,
          systeem-design en nog veel meer industriële toepassingen. "Het gebied van AI ontwikkelt zich snel, modellen
          worden steeds geavanceerder en digital twin simulaties worden steeds realistischer. Dit heeft een enorm
          potentieel in verschillende industrieën."
        </NL>
        <EN>
          The future is bright for the combined use of digital twins and AI. John is confident we will see powerful
          innovations in operational excellence, automation, system design, and many more industrial applications. "The
          field of AI is rapidly advancing, models are becoming more sophisticated and digital twin simulations are
          becoming more and more realistic. This has enormous potential across a variety of industries."
        </EN>
      </p>
    </InsightsPage>
  )
}
