// @flow
import * as React from 'react'
import { Page, EN, NL, Hero, HeroTitle, HeroBody, Button } from '../components'
import { Cookies } from 'react-cookie-consent'
import { CONSENT_COOKIE } from '../components'

const CookiePolicy = (): React.Node => {
  const deny = (
    <>
      <NL>Je accepteert onze cookies niet.</NL>
      <EN>You chose to reject our cookies.</EN>
    </>
  )
  const allow = (
    <>
      <NL>Je accepteert onze cookies.</NL>
      <EN>You chose to accept our cookies.</EN>
    </>
  )
  const undecided = (
    <>
      <NL>Je hebt nog geen keuze gemaakt.</NL>
      <NL>You haven't made a choice yet.</NL>
    </>
  )
  const consent = Cookies.get(CONSENT_COOKIE)
  const message = consent === 'allow' ? allow : consent === 'deny' ? deny : undecided
  return (
    <Page
      title={{
        nl: 'Cookies & Privacy',
        en: 'Cookies & Privacy',
      }}
      description={{
        nl: 'Als Data Science bedrijf houden we van data verzamelen. Maar natuurlijk niet als jij dat niet wilt, want we vinden jouw recht op privacy veel belangrijker.',
        en: "As a data (science) company, we value gathering data. But not about you if you don't want that! Because we value the right to privacy a lot more.",
      }}
      location="/cookie-policy"
    >
      <Hero>
        <HeroTitle>Cookies & Privacy</HeroTitle>
        <HeroBody>
          <NL>
            We willen graag heel open zijn over de data die we over je verzamelen. We gebruiken cookies om te zien hoe
            je onze website gebruikt, en <strong>dat is alles</strong>.
          </NL>
          <EN>
            We aim to be very open about your data. We use cookies to see how you use our website{' '}
            <strong>and that's all</strong>.
          </EN>
        </HeroBody>
      </Hero>

      <div className="text-container">
        <h2 className="text-blue">
          <NL>Wat meten we?</NL>
          <EN>What do we track?</EN>
        </h2>
        <p>
          <NL>
            Het is niet ons doel om gebruikers data te verkopen, ongeacht van wie of aan wie, en we tonen of verkopen
            geen advertenties. <strong>We willen alleen weten welke pagina's worden gelezen op onze website. :)</strong>{' '}
            We gebruiken Google Analytics en HotJar om bezoekersstatistieken te meten, en zij slaan namens ons een
            aantal cookies op in je browser om dat mogelijk te maken.
          </NL>
          <EN>
            We're not in the business of selling user data from anyone, to anyone, and we don't sell ads.{' '}
            <strong>We just want to know which pages visitors read on our site. :)</strong> Our website uses Google
            Analytics and HotJar for that, and they will store several cookies in your browser on our behalf to allow us
            to do so.
          </EN>
        </p>
        <p>
          <NL>
            Tenminste, zolang je je toestemming daarvoor niet intrekt. Als je dat doet wordt je gedrag volledig
            geanonimiseerd en worden cookies nooit langer bewaard dan je bezoek aan onze website.
          </NL>
          <EN>
            That is, unless you opt out below. If so, we disable HotJar and set up Google Analytics to forget everything
            about you once you leave our website. It's like you were never here.
          </EN>
        </p>
      </div>

      <div className="mt-5 text-container">
        <h2 className="text-dark-blue">
          <NL>Jouw voorkeuren instellen</NL>
          <EN>Set your preferences</EN>
        </h2>
        <p>
          <NL>
            In overeenstemming met EU-wetten en -regels kun je je toestemming voor cookie-tracking en data-verwerking op
            elk moment wijzigen. Als je niet wilt bijdragen aan het verbeteren van onze website dan snappen we dat, klik
            dan op <strong>Cookies niet toestaan</strong>. Of als je denkt dat een paar cookies voor generieke analyses
            helemaal prima zijn, klik dan op <strong>Cookies accepteren</strong>.
          </NL>
          <EN>
            In accordance with EU laws, you can at any time change your mind about allowing us to place third-party
            cookies in your browser and track multiple visits to our site. If you don't want to help us improve our
            website, that's fine, just click <span className="strong">Reject cookies</span>. Or if you think the few
            analytical cookies we place are perfectly ok, click <span className="strong">Allow cookies</span>.
          </EN>
        </p>
        <p className="small text-muted">
          <NL>
            Gebruik de knoppen hieronder om je keuze aan te geven wat betreft het opslaan van (niet-essentiële)
            analytische cookies in je browser.
          </NL>
          <EN>
            Use the buttons below to change your preferences on whether to allow us to save (non-essential) cookies in
            your browser.
          </EN>
        </p>
        <div className="mb-2">
          <Button
            className="cookie-button me-2"
            outline={consent !== 'allow'}
            color="green"
            onClick={() => {
              Cookies.set(CONSENT_COOKIE, 'allow')
              window.location.reload()
            }}
          >
            <NL>Cookies accepteren</NL>
            <EN>Allow cookies</EN>
          </Button>
          <Button
            className="cookie-button"
            outline={consent !== 'deny'}
            color="danger"
            onClick={() => {
              Cookies.set(CONSENT_COOKIE, 'deny')
              window.location.reload()
            }}
          >
            <NL>Cookies niet toestaan</NL>
            <EN>Reject cookies</EN>
          </Button>
        </div>
        <p>{message}</p>
      </div>
    </Page>
  )
}

export default CookiePolicy
