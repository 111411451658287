// @flow
import * as React from 'react'
import './Hero.scss'

type Props = {
  children: ?React.Node,
  className?: string,
}

type TitleProps = {
  children: ?React.Node,
  className?: string,
}

type BodyProps = {
  children: ?React.Node,
  className?: string,
}

export const Hero = ({
  children,
  className = '',
}: Props): React.Element<'div'> => (
  <div className={`hero container ${className}`}>{children}</div>
)

export const HeroTitle = ({
  children,
  className = '',
}: TitleProps): React.Element<'div'> => (
  <div className="row">
    <div className="col-lg-9 col-xxl-7 mb-4 title-wrapper">
      <h1 className={`title ${className}`}>{children}</h1>
    </div>
  </div>
)

export const HeroBody = ({
  children,
  className = '',
}: BodyProps): React.Element<'div'> => (
  <div className="text-container p-0 ms-0">
    <div className={`lead ${className}`}>{children}</div>
  </div>
)
