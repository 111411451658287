// @flow
import * as React from 'react'

type Props = {
  img: string,
  color: string,
  alt: string,
}

export const BigIcon = ({ img, color, alt }: Props): React.Node => (
  <div
    className={`bg-transparent-${color} rounded-circle d-inline-block position-absolute`}
    style={{ transform: 'translateX(-115%)' }}
  >
    <img src={img} alt={alt || 'icon'} className="big-icon" />
  </div>
)

export const BigNumber = ({ n }: { n: number }): React.Node => (
  <div
    className="position-absolute text-transparentize"
    style={{
      fontSize: '9rem',
      lineHeight: 0.7,
      transform: 'translateX(-115%)',
    }}
  >
    {n}
  </div>
)
