// @flow
import * as React from 'react'
import { EN, NL, ProjectPanel, Link, Button, BackToTop } from '../../components'
import ReviewPhoto from '../../assets/projects/systematic-review-thumbnail.jpg'

export const PallasShort = (): React.Node => (
  <ProjectPanel
    title={
      <>
        <NL>Literatuuronderzoek in 1/3 van de tijd</NL>
        <EN>Literature research in 1/3 of the time</EN>
      </>
    }
    photo={ReviewPhoto}
    subtitle={
      <>
        <NL>Selectical | Literatuuronderzoek | NLP | Active Learning</NL>
        <EN>Selectical | Literature Research | NLP | Active Learning</EN>
      </>
    }
  >
    <p>
      <NL>
        Onderzoekers die <strong>(systematisch) literatuuronderzoek</strong> doen, moeten vaak véle duizenden artikelen
        screenen om die artikelen te vinden die relevant zijn voor hun onderzoek. Daarbij moeten ze grondig zijn, omdat
        er vaak niets gemist mag worden. Echter zijn van die duizenden papers vaak slechts een paar honderd relevant.
        Deze selectie{' '}
        <strong className="text-green">
          handmatig doen kost ontzettend veel nuttige tijd en is simpelweg niet leuk
        </strong>
        .
      </NL>
      <EN>
        Researchers doing <strong>(systematic) literature reviews</strong> need to screen many thousands research papers
        and select the ones that are relevant to a specific subject. Usually out of these thousands of papers, only a
        few hundred are actually useful. Making this selection{' '}
        <strong className="text-green">manually takes a lot of useful time and is simply boring.</strong>
      </EN>
    </p>
    <h3>
      <NL>Zonder vooraf gelabelde data</NL>
      <EN>Without pre-labeled data</EN>
    </h3>
    <p>
      <NL>
        Normaal gesproken train je een AI-model op basis van gelabelde data. Op basis van voorbeelden. Maar dat kan in
        dit geval niet! De uitdaging is namelijk dat gezien elke literatuurstudie anders is, er geen voorbeelden zijn.
        Dat labelen van die voorbeelden is eigenlijk precies je werk. Maar dat betekent niet dat je dan geen AI kunt
        trainen!
      </NL>
      <EN>
        Usually we train an AI model using labeled data. Using examples. But that is not possible in this case! The
        challenge is that since every literature study is different, there are no examples yet. Labeling these examples
        just so happens to be your job. That doesn't mean that you can't use AI though!
      </EN>
    </p>
    <h3>
      <NL>En toch AI inzetten</NL>
      <EN>And still use AI</EN>
    </h3>
    <p>
      <NL>
        Hiervoor hebben we{' '}
        <Link to="https://en.wikipedia.org/wiki/Active_learning_(machine_learning)" className="text-green strong">
          Active Learning
        </Link>{' '}
        gebruikt: een type AI dat <strong>leert van menselijke input</strong> en zich continu verbetert tijdens het
        werken. Zo kan de onderzoeker op dezelfde manier aan de slag met hun werk, terwijl de AI meeleert. Op het moment
        dat de AI zeker genoeg is dat het de <strong>juiste patronen</strong> heeft opgepikt, neemt deze het labelen van
        de artikelen over en hoeft de onderzoeker dit alleen nog te valideren of corrigeren. Scheelt een hoop werk!
      </NL>
      <EN>
        For this we use a technique called{' '}
        <Link to="https://en.wikipedia.org/wiki/Active_learning_(machine_learning)" className="text-green strong">
          Active Learning
        </Link>
        : this type of AI <strong>learns from human input</strong>, and can improve itself constantly while working.
        This way the researcher can start working the same way as usual, but with an AI learning in the background. Once
        the AI is certain enough that it has identified the <strong>right patterns</strong>, it will take over the
        labeling. This way the researcher only has to validate or correct the AI's work. This saves a lot of time!
      </EN>
    </p>
    <Button to={{ nl: '/whitepapers/selectical', en: '/whitepapers/selectical' }} className="me-3" color="green">
      <NL>Lees meer over Selectical</NL>
      <EN>Read more about Selectical</EN>
    </Button>
    <Button to={{ nl: '/contact', en: '/contact' }} color="green" outline>
      <NL>Vraag direct een demo aan</NL>
      <EN>Ask for a demo!</EN>
    </Button>
    <BackToTop></BackToTop>
  </ProjectPanel>
)
