// @flow
import * as React from 'react'
import {
  Page,
  Section,
  WallpaperSection,
  WebinarUpdateInput,
  EN,
  NL,
  ACTIVE_WEBINAR,
} from '../components'
import Traffic from '../assets/traffic.jpg'

const Team = (): React.Node => (
  <Page
    title={{
      nl: 'Webinar Big Data & AI: Wanneer is het nuttig?',
      en: 'Webinar Big Data & AI: When is it useful?',
    }}
    description={{
      nl: 'Schrijf je in voor onze webinar op vrijdag 17 april om 15:00 en leer wanneer Big Data en AI nuttig zijn voor *jouw* organisatie.',
      en: 'Sign up for our webinar (in Dutch) on Friday April 17th, 15:00 CEST and learn when and how Big Data and AI can useful for *your* organisation.',
    }}
    location="/webinar"
  >
    <WallpaperSection
      size="small"
      wallpaper={Traffic}
      divider="bottom"
      className="mt-0"
    >
      <div className="text-container text-white">
        <div className="subtitle">Webinar</div>
        <h1 className="display-4">
          Big Data & AI:
          <br />
          <span className="text-light-green">
            <NL>Wanneer is het nuttig?</NL>
            <EN>When is it useful?</EN>
          </span>
        </h1>
        <p className="lead fw-bold">
          <EN>
            How do you know as an organisation that you should start using AI?
            Or that you should become more data-driven? Where do you even start
            with those things?
          </EN>
          <NL>
            Hoe weet je als organisatie dat je AI moet gaan inzetten? Of dat er
            meer datagedreven gewerkt moet worden? Hoe begin je aan zoiets?
          </NL>
        </p>
        {ACTIVE_WEBINAR ? (
          <p className="lead fw-bold">
            <EN>
              In this webinar we'll bring you up to speed on{' '}
              <span className="text-light-green">
                Friday, April 17th at 15.00 CEST
              </span>
              .
            </EN>
            <NL>
              In onze webinar praten we je bij, op{' '}
              <span className="text-light-green">
                vrijdag 17 april om 15.00u
              </span>
              .
            </NL>
          </p>
        ) : (
          <>
            <p className="lead">
              <EN>
                In our webinar we can bring you up to speed. Want to know when
                the next one is? Leave your maill address here:
              </EN>
              <NL>
                In onze webinar praten we je bij. Wil je weten wanneer de
                volgende is? Laat hier je e-mail achter:
              </NL>
            </p>
            <WebinarUpdateInput />
          </>
        )}
        {ACTIVE_WEBINAR ? (
          <EN>
            <p>
              Note that this webinar is <strong>in Dutch</strong>.
            </p>
          </EN>
        ) : null}
      </div>
    </WallpaperSection>
    <Section className="text-container" size="small">
      <div className="row">
        <div className="col">
          <h2 className="h3">
            <EN>Is AI something for my organisation?</EN>
            <NL>Is AI iets voor mijn organisatie?</NL>
          </h2>
          <p>
            <EN>
              Organisations of all sizes want to "do more with data" and to have
              "Big Data and AI solutions". Will you miss out if you don't also
              start with that? How do you know your organisation is ready? And
              what are realistic expectations?
              <br />
              If you want an answer to any of these questions, register for our
              webinar!
            </EN>
            <NL>
              Organisaties van groot tot klein willen "meer met data doen" en
              "Big Data en AI-oplossingen". Mis je de boot als je daar niet in
              meegaat? Hoe weet je of jouw organisatie daar klaar voor is? En
              wat zijn realistische verwachtingen?
              <br />
              Wil je op een of meer van deze vragen het antwoord, schrijf je dan
              in!
            </NL>
          </p>
          {ACTIVE_WEBINAR ? (
            <p className="text-muted small">
              <EN>
                Register to be able to see the webinar. You'll recieve a
                confirmation of your registration per email.
              </EN>
              <NL>
                Schrijf je nu in om de webinar te kunnen bekijken. Je ontvangt
                een bevestiging van je registratie per e-mail.
              </NL>
            </p>
          ) : null}
        </div>
        <div
          className="col bg-light-green px-3 text-body"
          style={{ flex: '0 0 325px' }}
        >
          {ACTIVE_WEBINAR ? (
            <iframe
              title="Schrijf je in voor de webinar!"
              id="geek-iframe-0"
              src="https://embed.webinargeek.com/905057cfc84981ad18bc8f5c997b066c31894c0411cecbd1ef?"
              style={{
                overflow: 'hidden',
                height: '286px',
                marginLeft: '-15px',
                marginRight: '-15px',
              }}
              scrolling="no"
              width="325"
              frameBorder="0"
            />
          ) : (
            <p className="mt-3">
              <EN>There is no next date planned for this webinar yet.</EN>
              <NL>
                Er is op dit moment nog geen volgende datum voor deze webinar
                gepland.
              </NL>
            </p>
          )}
          <p className="small">
            <span className="fw-bold">
              <EN>Presentation by: </EN>
              <NL>Presentatie door: </NL>
            </span>
            Jacob Boon
            <br />
            <span className="fw-bold">
              <EN>Duration: </EN>
              <NL>Duur: </NL>
            </span>
            <EN>75 minutes</EN>
            <NL>75 minuten</NL>
          </p>
          <p className="text-muted small">
            <EN>
              You can see this webinar on your computer, tablet or smartphone.
            </EN>
            <NL>
              Je kunt de webinar volgen vanaf je computer, tablet of telefoon.
            </NL>
          </p>
        </div>
      </div>
    </Section>
  </Page>
)
export default Team
