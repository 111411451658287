// @flow
import * as React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { CallButton, Section } from '../components'

import { Link, Button, EN, NL, ClientLogo } from '../components'

import './Footer.scss'

const ContactBlock = () => (
  <Section className="contact-footer-block m-0 bg-transparent-orange">
    <div className="text-container">
      <h1 className="text-orange">
        <NL>Geïnspireerd om iets moois te maken?</NL>
        <EN>Inspired to create something amazing?</EN>
      </h1>
      <p>
        <NL>
          We horen graag jouw visie! Deel je ideeën, ook als ze nog niet helemaal scherp zijn. We&nbsp;denken graag met
          je&nbsp;mee!
        </NL>
        <EN>
          We'd love to hear your vision! Share your ideas, even if they're still forming. We're&nbsp;happy to brainstorm
          with&nbsp;you!
        </EN>
      </p>
      <div>
        <Button size="lg" to="/contact" color="orange" className="me-3">
          <NL>Maak een afspraak</NL>
          <EN>Contact us</EN>
        </Button>
        <CallButton size="lg" color="orange" outline />
      </div>
    </div>
  </Section>
)

const Footer = (): React.Node => {
  let contactPage = useRouteMatch('/contact')

  return (
    <>
      {contactPage ? null : <ContactBlock />}
      <footer>
        <div className="container pt-5">
          <div className="row">
            <div className="col-md-4 col-lg-3 sitemap">
              <h2 className="h3">
                <NL>Wat we doen</NL>
                <EN>What we do</EN>
              </h2>
              <Link to={{ nl: '/diensten', en: '/services' }}>
                <NL>Van AI-idee naar Oplossing</NL>
                <EN>From AI idea to Solution</EN>
              </Link>
              <Link to={{ nl: '/portfolio', en: '/portfolio' }}>
                <NL>Ons Portfolio</NL>
                <EN>Our Portfolio</EN>
              </Link>
            </div>
            <div className="col-md-4 col-lg-3 mt-4 mt-md-0 sitemap">
              <h2 className="h3">
                <NL>Over ons</NL>
                <EN>About us</EN>
              </h2>
              <Link to={{ nl: '/over-ons#vision', en: '/about-us#vision' }}>
                <NL>Onze Visie</NL>
                <EN>Our Vision</EN>
              </Link>
              <Link to={{ nl: '/over-ons#values', en: '/about-us#values' }}>
                <NL>Onze Waarden</NL>
                <EN>Our Values</EN>
              </Link>
              <Link to={{ nl: '/over-ons#team', en: '/about-us#team' }}>
                <NL>Ons Team</NL>
                <EN>Our Team</EN>
              </Link>
              <Link to="/cookie-policy">Cookies & Privacy</Link>
              <Link to="/contact">Contact</Link>
            </div>
            <div className="col-md-4 col-lg-3 mt-4 mt-md-0">
              <h2 className="h3">
                <NL>Deelnemer van</NL>
                <EN>Participant in</EN>
              </h2>
              <a href="https://nlaic.com/" target="_blank" rel="noreferrer noopener">
                <ClientLogo name="NLAIC" />
              </a>
            </div>
            {/*<div className="col-md-6 mt-4 mt-md-0"></div>*/}
          </div>
          <div className="mt-5">
            <a href="https://www.linkedin.com/company/landscape-data" className="social-media-link">
              <i className="fa fa-linkedin-square" />
            </a>
            <a className="pretty-link" href="mailto:hello@wearelandscape.nl">
              hello@wearelandscape.nl
            </a>{' '}
            • KvK: 74306758 • BTW: NL859847901B01
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
