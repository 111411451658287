// @flow
import * as React from 'react'
import _ from 'lodash'
import { Route, Switch } from 'react-router-dom'

import InsightsHome from './InsightsHome'

import type { ContextRouter } from 'react-router-dom'
import type { LangString } from '../../App'

export type StoryMetadata = {
  slug: string,
  title: LangString,
  description: LangString,
  date: string,
  published: boolean,
  thumbnail: string, // imported image
}

export type Story = {
  component: any,
  metadata: StoryMetadata,
  key: string,
}

export type Stories = { [key: string]: Story }

function getStories() {
  // $FlowFixMe[prop-missing]
  const requireComponent = require.context('./stories', true, /\.js$/)

  const stories: Stories = {}
  requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName)
    const key = fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')
    stories[key] = {
      component: componentConfig.default,
      metadata: componentConfig.metadata,
      key,
    }
  })
  return _.pickBy(stories, (story) => process.env.NODE_ENV !== 'production' || story.metadata.published)
}
export const stories: Stories = getStories()

export default function Insights({ match }: ContextRouter): React.Node {
  return (
    <Switch>
      <Route path={match.path} exact render={(props): React.Node => <InsightsHome {...props} stories={stories} />} />
      {_.map(stories, ({ metadata, component }) => (
        <Route key={metadata.slug} path={`${match.path}/${metadata.slug}`} component={component} />
      ))}
    </Switch>
  )
}
