// @flow
import * as React from 'react'

import './Section.scss'

export type Props = {
  ...React.ElementConfig<'section'>,
  children?: React.Node,
  size?: "small" | "regular" | "big" | "huge" | null, // null is 'regular'
  className?: string,
  style?: Object,
  gradient?: string
}

export const Section = (props: Props): React.Element<"section"> => {
  const {
    className,
    size,
    gradient,
    style,
    children
  } = props
  const classes = [
    className != null ? className : '',
    size != null ? 'section-' + size : '',
    gradient != null ? 'gradient-' + gradient : ''
  ].join(' ')

  return (
    <section {...props} className={classes} style={style}>
      {children}
    </section>
  )
}
