// @flow
import * as React from 'react'
import { useState, useEffect } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { Button, EN, NL } from './'

import type { Props as ButtonProps } from './Button'

// import Erwin from '../assets/team/erwin.jpg'
import Rohiet from '../assets/team/rohiet.jpg'

const T = ({ children }: { children: React.Node }) => <span className="me-2">{children}</span>

type CallMeProps = {
  name: string,
  photo: string,
  number: string,
  numberDisplay: string,
  countryCode?: string,
  copyToClipboard: (text: string) => void,
}

const CallMe = ({ name, photo, number, numberDisplay, countryCode = '31', copyToClipboard }: CallMeProps) => (
  <div className="d-flex align-items-start align-items-md-center flex-column flex-md-row">
    <img src={photo} alt={name} className="rounded-circle img-fluid" style={{ maxWidth: '80px' }} />
    <div className="ms-md-3 w-100">
      <NL>Bel {name}</NL>
      <EN>Call {name}</EN>
      <div className="d-flex">
        <div className="me-2 fs-4 align-self-center title-font text-muted">(+{countryCode})</div>
        <div className="m-0 h1">
          {numberDisplay.split(' ').map((t, idx) => (
            <T key={idx}>{t}</T>
          ))}
        </div>
        <a href={`tel:${number}`} className="text-muted ms-auto d-flex align-items-center hover-text-green">
          <i className="material-icons fs-3">phone_enabled</i>
        </a>
        <div
          className="text-muted cursor-pointer ms-3 d-flex align-items-center hover-text-green"
          onClick={() => copyToClipboard(number)}
        >
          <i className="material-icons fs-3">content_copy</i>
        </div>
      </div>
    </div>
  </div>
)

type Props = {
  ...ButtonProps,
  buttonContent?: ?React.Node,
  modalProps?: Object,
}

export const CallButton = ({ buttonContent, modalProps, ...buttonProps }: Props): React.Node => {
  const [isOpen, setIsOpen] = useState(false)
  const [message, setMessage] = useState<React.Node | null>(null)
  const toggle = () => {
    setIsOpen(!isOpen)
    setMessage(null)
  }
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    setMessage(
      <span className="fade-in" key={text}>
        <NL>Nummer gekopiëerd naar klembord!</NL>
        <EN>Number copied to clipboard!</EN>
      </span>
    )
  }
  useEffect(() => {
    return () => {
      setIsOpen(false)
      setMessage(null)
    }
  }, [])
  return (
    <>
      <Button {...buttonProps} onClick={() => setIsOpen(true)}>
        {buttonContent != null ? (
          buttonContent
        ) : (
          <span>
            <NL>Bel ons nu</NL>
            <EN>Call us now</EN>
          </span>
        )}
      </Button>
      <Modal toggle={toggle} isOpen={isOpen} centered {...modalProps} style={{ maxWidth: '560px' }}>
        <ModalBody>
          <CallMe
            name="Rohiet"
            photo={Rohiet}
            number="+31611331137"
            numberDisplay="06 11 33 11 37"
            copyToClipboard={copyToClipboard}
          />
        </ModalBody>
        {/* <hr className="m-0" />
        <ModalBody>
          <CallMe
            name="Erwin"
            photo={Erwin}
            number="+31624412942"
            numberDisplay="06 24 41 29 42"
            copyToClipboard={copyToClipboard}
          />
        </ModalBody> */}
        {message ? (
          <>
            <hr className="m-0" />
            <ModalBody className="small text-muted">{message}</ModalBody>
          </>
        ) : null}
      </Modal>
    </>
  )
}
