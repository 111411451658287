// @flow
import * as React from 'react'
import { Hero, HeroTitle, HeroBody, Section, WallpaperSection, Button, Page, NL, EN } from '../../components'

import Traffic from '../../assets/traffic.jpg'
import { CHDRShort as CHDR } from './CHDR'
import { PallasShort as Pallas } from './Pallas'
import { DHDShort as DHD } from './DHD'
import { ArtisShort as Artis } from './Artis'
import { WhoozShort as Whooz } from './Whooz'
import { AOGShort as AOG } from './AOG'
import { RAGShort as RAG } from './RAG'
import { EKuSIMshort as EKuSIM } from './EKuSIM'
import { PseuduckShort as Pseuduck } from './Pseuduck'

const ProjectOverview = (): React.Node => (
  <Page
    title={{ nl: 'Portfolio', en: 'Portfolio' }}
    description={{
      nl: 'We helpen organisaties data-gedreven oplossingen te realiseren met AI, en hebben dat al gedaan in allerlei branches en sectoren',
      en: 'Our previous work has been with diverse partners in a variety of fields. We help organisations move from ideas to data-driven results, with AI and Machine Learning.',
    }}
    location={'/portfolio'}
  >
    <Hero>
      {/* <div className="subtitle mb-3">
        <NL>Wat we deden</NL>
        <EN>What we did</EN>
      </div> */}
      <HeroTitle>
        <NL>Ons eerdere werk</NL>
        <EN>Our previous work</EN>
      </HeroTitle>
      <HeroBody>
        <NL>
          <p>
            Door de jaren heen hebben wij gewerkt met veel verschillende typen organisaties. De overeenkomst? De wens om{' '}
            <span className="strong text-green">beter en slimmer</span> hun werk te kunnen doen.{' '}
            <span className="strong text-green">Met data, machine learning en AI.</span>
          </p>
          <p>Lees hier hoe wij hen hielpen!</p>
        </NL>
        <EN>
          <p>
            Over the years we have worked with quite a variety of organisations. What they had in common? The wish to do
            their <span className="strong text-green">work better and smarter</span>.{' '}
            <span className="strong text-green">With data, machine learning and AI.</span>
          </p>
          <p>Read below how we helped them!</p>
        </EN>
        <Button color="dark-blue" to={{ nl: '/portfolio#all', en: '/portfolio#all' }} className="me-3">
          <NL>Scroll door alle projecten</NL>
          <EN>Scroll through all projects</EN>
        </Button>
        {/* <br /> */}
        {/* <h3 className="mt-4">
          <NL>Of lees meer over AI in de:</NL>
          <EN>Or read more about AI in:</EN>
        </h3> */}
        <Button color="blue" to={{ nl: '/portfolio-zorg', en: '/portfolio-healthcare' }} className="me-3">
          <NL>Gezondheid en zorg</NL>
          <EN>Healthcare</EN>
        </Button>
        <Button color="aqua" to={{ nl: '/portfolio-industrie', en: '/portfolio-industry' }} className="me-3">
          <NL>Industrie</NL>
          <EN>Industry</EN>
        </Button>
        <Button color="teal" to={{ nl: '/portfolio-ai-producten', en: '/portfolio-ai-products' }} className="me-3">
          <NL>AI-producten</NL>
          <EN>AI products</EN>
        </Button>
      </HeroBody>
    </Hero>
    <WallpaperSection size="small" wallpaper={Traffic} id="all">
      <div className="text-container text-white">
        <h3>
          <NL>
            Laat onze case studies je inspireren voor jullie volgende stap met data en AI.{' '}
            <span className="text-light-green">Wil je direct jouw eigen casus bespreken?</span>
          </NL>
          <EN>
            Let our previous work inspire you to take your next step with data and AI.{' '}
            <span className="text-light-green">Want to start tackling your own case now?</span>
          </EN>
        </h3>
        <div className="text-end mt-3">
          <Button color="white" outline to="/contact">
            <NL>Neem contact op!</NL>
            <EN>Contact us!</EN>
          </Button>
        </div>
      </div>
    </WallpaperSection>

    <Section size="small" className="text-container" id="selectical">
      <Pallas />
    </Section>

    <Section size="small" className="text-white" gradient="green-teal" id="dhd">
      <div className="text-container">
        <DHD />
      </div>
    </Section>

    <Section size="small" className="text-container" id="rag">
      <RAG />
    </Section>

    <Section size="small" className="text-white" gradient="teal-blue" id="ekusim">
      <div className="text-container">
        <EKuSIM />
      </div>
    </Section>

    <Section size="small" className="text-container" id="artis">
      <Artis />
    </Section>

    <Section size="small" className="text-white" gradient="green-teal" id="pseuduck">
      <div className="text-container">
        <Pseuduck />
      </div>
    </Section>

    <Section size="small" className="text-container" id="chdr">
      <CHDR />
    </Section>

    <Section size="small" className="text-white" gradient="teal-blue" id="aog">
      <div className="text-container">
        <AOG />
      </div>
    </Section>

    {/* <hr /> */}
    <Section size="small" className="text-container" id="whooz">
      <Whooz />
    </Section>
  </Page>
)
export default ProjectOverview
