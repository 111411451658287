// @flow
import * as React from 'react'
import { BackgroundImage, Section } from './'
import './WallpaperSection.scss'

import type { Props as SectionProps } from './Section'
import type { Properties } from 'csstype'
type Props = {
  ...SectionProps,
  wallpaper?: string,
  className?: string,
  divider?: 'top' | 'bottom' | 'none',
  dividerClassName?: string,
  dividerGradient?: string,
  wallpaperStyle?: Properties<>,
}

export const WallpaperSection = (props: Props): React.Node => {
  const {
    wallpaper,
    className = '',
    children,
    divider = 'top',
    dividerClassName = '',
    wallpaperStyle,
    dividerGradient = 'green-teal-blue',
    ...sectionProps
  } = props
  const computedClassName = `divider ${divider} ${dividerClassName} gradient-${dividerGradient}`
  return (
    <Section {...sectionProps} className={`wallpaper-section ${className}`}>
      {divider !== 'none' ? <div className={computedClassName} /> : null}
      {children}
      {wallpaper != null ? (
        <BackgroundImage
          image={wallpaper}
          alt="wallpaper"
          style={wallpaperStyle}
        />
      ) : null}
    </Section>
  )
}
