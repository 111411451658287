// @flow
import * as React from 'react'
import { NL, EN, ProjectPanel, BackToTop } from '../../components'
import RoofsThumb from '../../assets/projects/roofs-thumbnail.jpg'

export const WhoozShort = (): React.Node => (
  <ProjectPanel
    title={
      <>
        <NL>Alle daken van Nederland gemeten en beoordeeld</NL>
        <EN>All rooftops in the Netherlands measured and categorised</EN>
      </>
    }
    photo={RoofsThumb}
    subtitle={
      <>
        <NL>Bedrijfsvoering | Beeldanalyse</NL>
        <EN>Business Services | Image Recognition</EN>
      </>
    }
  >
    <p>
      <NL>
        Hoe hoog is een gebouw? Wat voor dak zit erop? De klant, een data-aggregator, wilde een dataset met daarin{' '}
        <strong>hoogteprofielen en daktypes van alle gebouwen in Nederland</strong>.
      </NL>
      <EN>
        How high is a building? What type of roof does it have? The client, a data aggregator, wanted a dataset
        containing <strong>the heights and roof profiles of all buildings in the Netherlands</strong>.
      </EN>
    </p>
    <h4>
      <NL>Wat hebben we gedaan?</NL>
      <EN>What did we do?</EN>
    </h4>
    <ul>
      <li>
        <NL>
          Data uit satellietbeelden gekoppeld met (open) kaartdata en daarmee de hoogte van elk Nederlands gebouw
          bepaald
        </NL>
        <EN>Coupled satellite and open map data and determined the height of every Dutch building</EN>
      </li>
      <li>
        <NL>Tienduizenden 'gelabelde voorbeelden' verzameld van types van daken uit verschillende bronnen</NL>
        <EN>Gathered and combined tens of thousands of 'labelled examples' of roof types from various sources</EN>
      </li>
      <li>
        <NL>Een AI getraind om de meest gangbare daktypes automatisch te herkennen</NL>
        <EN>Trained an AI model to recognize the most common types of roofs</EN>
      </li>
    </ul>
    <p>
      <NL>
        Ons model bouwde de gevraagde dataset door <strong>het type dak van elk gebouw in Nederland</strong> te
        categoriseren. Met deze nieuwe data kan de klant hun eigen modellen verbeteren, waarmee ze bijvoorbeeld kunnen
        zien hoe waarschijnlijk het is dat iemand binnenkort gaat verhuizen.
      </NL>
      <EN>
        Our model constructed the required dataset,{' '}
        <strong>assigning a roof type to each and every building in the Netherlands</strong>. With this new data the
        client can improve their own models. They use these for instance to predict how likely someone is to move in the
        near future.
      </EN>
    </p>
    <BackToTop />
  </ProjectPanel>
)
