// @flow
import * as React from 'react'
import { Route, Switch } from 'react-router-dom'

import ProjectsHome from './ProjectsHome'
import { AOG } from './AOG'
import { RAG } from './RAG'

import type { ContextRouter } from 'react-router-dom'
export type ProjectRoute =
  | {|
      type: 'short',
      title: string,
      summary: string,
    |}
  | {|
      type: 'detailed',
      title: string,
      summary: string,
      path: string,
      component: any,
    |}

const projects: ProjectRoute[] = [
  {
    type: 'short',
    title: 'DHD',
    summary: 'Summary of the project',
  },
  {
    type: 'short',
    title: 'CHDR',
    summary: 'Summary of the project',
  },
  {
    type: 'detailed',
    title: 'AOG',
    summary: 'Summary of the project',
    path: '/aog',
    component: AOG,
  },
  {
    type: 'detailed',
    title: 'RAG',
    summary: 'Summary of the project',
    path: '/rag',
    component: RAG,
  },
  {
    type: 'short',
    title: 'Artis',
    summary: 'Summary of the project',
  },
  {
    type: 'short',
    title: 'Whooz',
    summary: 'Summary of the project',
  },
  {
    type: 'short',
    title: 'Rendement',
    summary: 'Summary of the project',
  },
]

const Projects = ({ match }: ContextRouter): React.Node => (
  <Switch>
    <Route path={match.path} exact>
      <ProjectsHome />
    </Route>
    {projects.map((project, i) =>
      project.type === 'detailed' ? (
        <Route
          key={i}
          path={`${match.path}${project.path}`}
          component={project.component}
        />
      ) : null
    )}
  </Switch>
)
export default Projects
