// @flow
import * as React from 'react'

import './ProjectPanel.scss'

type Props = {|
  title: React.Node,
  subtitle?: React.Node,
  photo?: string,
  children: ?React.Node,
  className?: string,
  titleClass?: string,
  titleTag?: string,
|}

export const ProjectPanel = (props: Props): React.Element<'div'> => {
  const Title = props.titleTag != null ? props.titleTag : 'h2'
  return (
    <div
      className={`project-panel flex-column flex-md-row media ${
        props.className != null ? props.className : ''
      }`}
    >
      {props.photo != null ? (
        <img
          src={props.photo}
          alt={typeof props.title === 'string' ? props.title : 'Project'}
          className="rounded-circle img-fluid me-3 mb-3"
        />
      ) : null}
      <div className="media-body">
        {props.subtitle != null ? (
          <h5 className="subtitle">{props.subtitle}</h5>
        ) : null}
        <Title
          className={`${props.titleClass != null ? props.titleClass : ''}`}
        >
          {props.title}
        </Title>
        {props.children}
      </div>
    </div>
  )
}
