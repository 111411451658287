// @flow
import * as React from 'react'

import {
  Button,
  // EN,
  Hero,
  // HeroBody,
  HeroTitle,
  // Link,
  // NL,
  Page,
  Section,
  // NavigationBlock,
  WallpaperSection,
} from '../components'
import Traffic from '../assets/traffic.jpg'
import Screenshot from '../assets/projects/ekusim-screenshot.jpg'

const DistrictHeating = (): React.Node => (
  <Page
    title="District Heating"
    description={{
      nl: '',
      en: '',
    }}
    location="/district-heating"
  >
    <Hero>
      <HeroTitle>
        Transforming <span className="text-teal">District Heating</span> through{' '}
        <span className="text-green">AI Solutions</span>
      </HeroTitle>
      {/* <HeroBody>
        hoi
      </HeroBody> */}
    </Hero>
    <WallpaperSection size="small" wallpaper={Traffic} id="all">
      <div className="text-container text-white">
        <h1>
          The Challenge of Energy Transition: <span className="text-teal">Navigating the New Landscape</span>
        </h1>
        <p className="lead">
          Transitioning from fossil fuels and nuclear energy to renewable sources represents a seismic shift in the
          energy sector—one of the most significant challenges of our generation. Amidst this landscape change,{' '}
          <span className="strong text-teal">district heating networks</span> emerge as a critical component. However,
          with the new norm comes increasing complexities:
        </p>
        <ul className="lead strong">
          <li>Discontinuation of traditional energy sources</li>
          <li>Centralization of district heating supplies</li>
          <li>Changes in regulatory requirements and technical specifications</li>
          <li>Rising demands on operational personnel</li>
        </ul>
      </div>
    </WallpaperSection>
    <Section size="small">
      <div className="text-container">
        <h1>
          Our Solution: <span className="text-orange">AI-Driven Optimization of District Heating Systems</span>
        </h1>
        <p>
          Landscape AI focuses on real-world, AI-driven solutions that optimize and modernize district heating systems.
        </p>

        <h3 className="text-green mt-3">Rapid Analyses</h3>

        <p>
          Our AI learns optimal control patterns by simulating years of operational scenarios within hours, thus
          eliminating the risk to your actual operations.
        </p>

        <h3 className="text-teal mt-3">Real-Time Adjustments</h3>

        <p>
          Dynamic, on-the-fly recommendations adapt to real-world fluctuations in demand, supply, and system components.
        </p>

        <h3 className="text-aqua mt-3">Adaptability and Resilience</h3>

        <p>
          Built to adapt to disruptions and changes in system components seamlessly, ensuring uninterrupted operations.
        </p>

        <h3 className="text-blue mt-3">Optimization of Resources</h3>

        <p>
          Our AI suggestions empower operators to reduce operational costs, energy wastage, and system failures while
          extending component lifespan.
        </p>
      </div>
    </Section>
    <Section size="small" style={{ backgroundColor: 'rgb(86, 75, 157)' }}>
      <div className="container text-white">
        <div className="text-container m-0 p-0">
          <h1>
            <span className="text-transparent-orange">Game-Based Learning:</span>
            <br />
            The ThermoCity Simulation
          </h1>
          <p>
            Want a hands-on experience of a day in the life of a district heating network operator? Try the ThermoCity
            Simulation Game. Navigate real-world challenges and measure your performance in a risk-free, simulated
            environment.
          </p>
        </div>

        <img
          src={Screenshot}
          className="img-fluid"
          alt="Simulation screenshot showing different producers, routes and users of heat."
        />

        <div className="d-flex mt-5">
          <Button size="lg" color="white" to="/contact" className="mx-auto strong">
            Request a demo
          </Button>
        </div>
      </div>
    </Section>
    <Section size="small">
      <div className="text-container">
        <h1>Why Landscape AI?</h1>
        <p>
          As a pioneer in AI solutions for critical infrastructures, Landscape AI specializes in bringing intelligent
          optimization to complex systems. Our AI assistant integrates seamlessly with EKu. SIM's robust simulation
          platform showcases an unparalleled toolset for managing district heating systems.
        </p>
        <p>
          We're committed to helping you navigate the intricate terrain of energy transition and district heating
          management.{' '}
        </p>
        <Button color="green" to="/contact" className="strong">
          Get in touch
        </Button>
      </div>
    </Section>
  </Page>
)
export default DistrictHeating
