// @flow
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import ReactGA from 'react-ga4'
import { Button } from './Button'
import { NL, EN } from './Lang'

// Change this depending on if there's a next webinar planned
// We use UTC datetime component values
// see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
export const NEXT_WEBINAR: Date = new Date(2020, 3, 17, 13) // 2020-04-17, 15:00 UTC+2 (CEST)
export const ACTIVE_WEBINAR = NEXT_WEBINAR > new Date()

export const WEBINAR_COOKIE = 'webinar-banner-2020-04-17'
const oneDay = 60 * 60 * 24

export const WebinarMessage = (): null | React.Node => {
  const [cookies, setCookie] = useCookies([WEBINAR_COOKIE])
  const history = useHistory()

  // If there is no webinar right now
  if (!ACTIVE_WEBINAR) {
    return null
  }

  // If there is a webinar, this is the code that makes the site-wide message
  const hideBanner = cookies[WEBINAR_COOKIE] === 'closed'
  if (hideBanner) return null
  return (
    <>
      <EN>
        <div
          className="position-sticky sticky-top bg-light-green py-3 cursor-pointer text-body"
          onClick={() => history.push('/en/webinar')}
        >
          <div className="container d-flex align-items-center">
            <div>
              Register for our webinar{' '}
              <strong>"Big Data & AI: When is it useful?"</strong> (in Dutch)
            </div>

            <Button
              to="/webinar"
              size="sm"
              color="green"
              className="ms-4 mb-0 d-flex flex-nowrap align-items-center me-3"
            >
              Register&nbsp;now
            </Button>

            <i
              className="material-icons ms-auto cursor-pointer text-dark"
              style={{ opacity: '0.2' }}
              onClick={(event) => {
                event.stopPropagation()
                event.preventDefault()
                ReactGA.event({
                  category: 'Banners',
                  action: 'Closed',
                  label: 'EN Webinar Top Banner',
                })
                setCookie(WEBINAR_COOKIE, 'closed', { maxAge: oneDay })
              }}
            >
              cancel
            </i>
          </div>
        </div>
      </EN>
      <NL>
        <div
          className="position-sticky sticky-top bg-light-green py-3 cursor-pointer text-body"
          onClick={() => history.push('/webinar')}
        >
          <div className="container d-flex align-items-center">
            <div>
              Schrijf je in voor onze webinar{' '}
              <strong>"Big Data & AI: Wanneer is het nuttig?"</strong>
            </div>

            <Button
              to="/webinar"
              size="sm"
              color="green"
              className="ms-4 mb-0 d-flex flex-nowrap align-items-center me-3"
            >
              Schrijf&nbsp;je&nbsp;in
            </Button>

            <i
              className="material-icons ms-auto cursor-pointer text-dark"
              style={{ opacity: '0.2' }}
              onClick={(event) => {
                event.stopPropagation()
                event.preventDefault()
                ReactGA.event({
                  category: 'Banners',
                  action: 'Closed',
                  label: 'NL Webinar Top Banner',
                })
                setCookie(WEBINAR_COOKIE, 'closed', { maxAge: oneDay })
              }}
            >
              cancel
            </i>
          </div>
        </div>
      </NL>
    </>
  )
}
