// @flow
import type { LangString } from '../App'

import * as React from 'react'
import { useContext } from 'react'

type Props = {
  language: string,
  children: React.Node,
}

export const LanguageContext: React$Context<?string> = React.createContext<?string>('nl')

export const Lang = (props: Props): React.Node | null => {
  const context = useContext(LanguageContext)
  if (props.children == null) return null
  if (context == null && props.language === 'nl') {
    return props.children
  }
  if (context === props.language) {
    return props.children
  }
  return null
}

type NoProps = {|
  children: React.Node,
|}

export const NL = (props: NoProps): React.Node => <Lang {...props} language={'nl'} />
export const EN = (props: NoProps): React.Node => <Lang {...props} language={'en'} />

export function LangContent({ content }: { content: LangString }): React.Node {
  if (typeof content === 'string') return content
  return (
    <>
      {content.nl && <NL>{content.nl}</NL>}
      {content.en && <EN>{content.en}</EN>}
    </>
  )
}
