// @flow
import { LanguageContext } from './Lang'

import * as React from 'react'
import { useContext } from 'react'

type Props = {
  dateString?: string,
  dateObj?: Date
}

export const LocalDate = ({ dateString, dateObj }: Props): ?React.Element<'span'> => {
  const context = useContext(LanguageContext)
  const language = context || 'nl'
  const date = dateString ? new Date(dateString) : dateObj
  if (!date) return null
  return (
    <span>
      {date.toLocaleDateString(language, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })}
    </span>
  )
    }