// @flow
import * as React from 'react'
import { Hero, HeroTitle, HeroBody, Section, Link } from '../components'
import ServiceLinks from './ServiceLinks'
import { EN, NL } from '../components'

// import Traffic from '../assets/traffic.jpg'

const NoMatch = (): React.Element<'div'> => (
  <div className="page">
    <Hero>
      <HeroTitle>404</HeroTitle>
      <HeroBody>
        <EN>
          Oops, this page doesn't exist. But you can <Link to="/">go to the homepage</Link>.
        </EN>
        <NL>
          Oeps, deze pagina bestaat niet. Maar je kunt <Link to="/">naar de homepage gaan</Link>.
        </NL>
      </HeroBody>
    </Hero>

    <Section size="small">
      <div className="container">
        <ServiceLinks />
        <div className="my-3 py-3" />
        <h2 className="text-blue">
          <EN>Were you looking for our previous work?</EN>
          <NL>Was je op zoek naar wat we eerder hebben gedaan?</NL>
        </h2>
        <NL>Ontdek</NL>
        <EN>Discover our track record at </EN>{' '}
        <Link to="/portfolio">
          <NL>ons portfolio</NL>
          <EN>our portfolio</EN>
        </Link>
        .
      </div>
    </Section>
  </div>
)

export default NoMatch
