// @flow
import * as React from 'react'
import {
  Button,
  ClientLogo,
  EN,
  NL,
  Link,
  ProjectPanel,
  Section,
  WallpaperSection,
  Page,
  BackToTop,
} from '../../components'
import RAGThumb from '../../assets/projects/rag-thumbnail.jpg'
import RAGWallpaper from '../../assets/projects/rag-zollverein.jpg'
import FNFigureEN from '../../assets/projects/rag_website_fig_EN.jpg'
import FNFigureNL from '../../assets/projects/rag_website_fig_NL.jpg'
import Swier from '../../assets/team/swier.jpg'

export const RAGShort = (): React.Node => (
  <ProjectPanel
    title={
      <>
        <NL>Kostbare mijnoperatie beter beheersen</NL>
        <EN>Better management of a massive mining operation</EN>
      </>
    }
    photo={RAGThumb}
    subtitle={
      <>
        <NL>Industrie | Inzicht | Predictie</NL>
        <EN>Industry | Insights | Prediction</EN>
      </>
    }
  >
    <p>
      <NL>
        RAG, ooit Duitslands grootste mijnbouwbedrijf voor steenkool, moet dagelijks tonnen water uit oude mijnschachten
        pompen om aan veiligheidsregels te voldoen. Het precieze proces waarmee water de mijn instroomt is echter niet
        goed begrepen. Landscape heeft een model gemaakt van real-world data om die hoeveelheid water maanden van
        tevoren te voorspellen. Hoeveel water er gepompt zal moeten worden is hiermee ook van tevoren duidelijk,{' '}
        <strong>waardoor RAG hun resources beter en efficiënter kan managen!</strong>
      </NL>
      <EN>
        RAG, formerly Germany's largest coal mining company, has to pump tonnes of water out of old mineshafts every
        day. The exact process of which water flows into the mine and when is not well understood. Landscape has built a
        model using real world data to predict this amount of water months in advance. This also indicates the required
        amount of pumping,
        <strong>which enables RAG to improve their resource management!</strong>
      </EN>
    </p>
    <Button to={{ nl: '/portfolio/rag', en: '/portfolio/rag' }} color="orange">
      <NL>Lees er alles over</NL>
      <EN>Read all about it</EN>
    </Button>
    <BackToTop />
  </ProjectPanel>
)

export const RAG = (): React.Node => (
  <Page
    title={{
      nl: 'Efficiëntie verhogen van de grootste pomp-operatie van Duitsland [Case Study]',
      en: "Increasing efficiency for Germany's biggest pumping operation [Case Study]",
    }}
    description={{
      nl: 'Neurale netwerken inzetten op de geologische data van het grootste mijnbouwbedrijf van Duitsland (RAG). Met explainable AI verhoogden zij hun efficiëntie.',
      en: 'Applying neural networks to the geological data of Germany’s biggest mining company (RAG). Using explainable AI they increased their efficiency.',
    }}
    location={'/portfolio/rag'}
  >
    <WallpaperSection
      wallpaper={RAGWallpaper}
      size="small"
      className="mt-0"
      divider="none"
      wallpaperStyle={{ filter: 'brightness(45%)' }}
    >
      <div className="container hero">
        <div className="row">
          <div className="col-lg-9 mb-md-4">
            <div className="subtitle">Case study RAG</div>
            <h1 className="text-blue">
              <NL>
                Efficiëntie verhogen <span className="text-light-blue">voor Duitsland's grootste pomp-operatie</span>
              </NL>
              <EN>
                Increasing efficiency <span className="text-light-blue">for Germany's biggest pumping operation</span>
              </EN>
            </h1>
          </div>
        </div>
        <div className="row align-items-start">
          <div className="col-lg-9 lead">
            <NL>
              <dl className="row">
                <dt className="col-lg-3 pe-lg-0 text-teal">De challenge</dt>
                <dd className="col-lg-9">Waterpeil voorspellen in lege mijnen in het Ruhrgebied.</dd>
                <dt className="col-lg-3 pe-lg-0 text-aqua">Onze oplossing</dt>
                <dd className="col-lg-9">
                  Met regen-data en geologische data is een AI getraind dat het waterpeil correct voorspelt.
                </dd>
                <dt className="col-lg-3 pe-lg-0 text-green">De toekomst</dt>
                <dd className="col-lg-9">
                  Landscape helpt RAG op dit moment om het AI model te verbeteren en adviseert hoe zij AI-gedreven
                  beslissingen kunnen nemen.
                </dd>
              </dl>
            </NL>
            <EN>
              <dl className="row">
                <dt className="col-lg-3 pe-lg-0 text-teal">The challenge</dt>
                <dd className="col-lg-9">Predict the water level in abandoned mines in the Ruhr Valley.</dd>
                <dt className="col-lg-3 pe-lg-0 text-aqua">The solution</dt>
                <dd className="col-lg-9">
                  Rainfall data and geological data are used to train an AI that accurately predicts the water level.
                </dd>
                <dt className="col-lg-3 pe-lg-0 text-green">The future</dt>
                <dd className="col-lg-9">
                  Landscape is currently helping RAG improve the AI model and advising how to make decisions using AI.
                </dd>
              </dl>
            </EN>
          </div>
          <div className="col-6 col-lg-3">
            <Link to="https://www.rag.de/">
              <ClientLogo name="RAG" style={{ maxWidth: '250px' }} className="color" /* This logo is in color */ />
            </Link>
          </div>
        </div>
      </div>
    </WallpaperSection>
    <Section size="small" className="">
      <div className="text-container">
        <p className="lead mb-0 fw-bold text-orange" style={{ fontSize: '1.7rem' }}>
          “The cooperation with Landscape exceeded all our expectations. The results of the challenge are very promising
          and gaining insight in Machine Learning was valuable for future projects.”
        </p>
        <p className=" fw-bold text-orange text-end">
          <NL>– Isabelle, Geoloog (mijnwatermanagement) bij RAG</NL>
          <EN>— Isabelle, Geologist (mine water management) at RAG</EN>
        </p>
      </div>
    </Section>
    <hr />
    <Section size="small">
      <div className="text-container">
        <NL>
          <p>
            Nadat Duitsland stopte met het ontginnen van steenkool werd RAG verantwoordelijk voor het onderhouden van de
            vele mijnen die nu niet langer in gebruik waren. Hun hoofdactiviteit bestaat uit het dagelijks wegpompen van
            tonnen regenwater uit de mijnschachten, om te voorkomen dat mijnwater en ondergrond drinkwater met elkaar
            vermengen.
          </p>
          <p>
            Door het waterpeil in de mijn te kunnen voorspellen zal RAG hun pomp-activiteiten preciezer kunnen plannen,
            wat kosten bespaart.
          </p>
          <p className="fw-bold">
            Maar hoe voorspel je het waterpeil in eeuwenoude mijnen, zonder kaarten en zonder exacte kennis van de
            omliggende grondlagen?
          </p>
          <p>
            En zelfs als hierin slaagt, hoe weet je dan dat deze voorspellingen ook later nog correct zijn, aangezien de
            pompinstallaties voor onbepaalde tijd zullen blijven pompen?
          </p>
        </NL>
        <EN>
          <p>
            After Germany stopped mining for coal, RAG became responsible for managing many of the mines that were now
            no longer in use. Their main activity consists of pumping tonnes of rainwater daily from these mines to
            prevent the mixing of mine water with drinking water.
          </p>
          <p>
            Predicting the level of the water in the mines will enable RAG to plan their pumping operation more
            precisely, lowering costs.
          </p>
          <p className="fw-bold">
            But how do you predict the water level of centuries old mines, without maps, and without exact knowledge of
            the surrounding soil?
          </p>
          <p>
            And even if you succeed in making predictions, how can you be certain that these predictions stay valid for
            long periods of time, as the pumping installations will have to be employed indefinitely?
          </p>
        </EN>
        <h2 className="text-green my-5">
          <NL>Onze oplossing: Explainable AI aan het werk</NL>
          <EN>Our solution: Explainable AI in action</EN>
        </h2>
        <NL>
          <p>
            Precies om deze vragen te beantwoorden vroeg RAG de hulp van Landscape. Tijdens de pilotfase hebben we het
            waterpeil in 3 mijnen voorspeld aan de hand van o.a. geologische kaarten, data over hoeveelheden regen en
            sensordata uit de mijn.
          </p>
          <p className="lead fw-bold text-dark-green">
            Dit neurale netwerk slaagde erin om het waterpeil 8 weken vooruit te voorspellen met een nauwkeurigheid van
            78-84%.
          </p>
        </NL>
        <EN>
          <p>
            It was exactly to answer these questions that RAG employed the help of Landscape. During the pilot we
            predicted water levels in 3 mines by using a combination of geological maps, rainfall data, sensor data and
            more.
          </p>
          <p className="lead fw-bold text-dark-green">
            This neural network was able to predict the water level 8 weeks in advance in the mines with a 78-84%
            accuracy.
          </p>
        </EN>
        <div className="bg-white my-4">
          <NL>
            <img className="img-fluid" src={FNFigureNL} alt="Pumping Predictions" />
          </NL>
          <EN>
            <img className="img-fluid" src={FNFigureEN} alt="Pumping Predictions" />
          </EN>
        </div>
        <h2 className="text-green my-5">
          <EN>Next steps</EN>
          <NL>Vervolgstappen</NL>
        </h2>
        <NL>
          De pilot heeft bewezen dat AI de efficiëntie van RAG kan verhogen. Het gaf RAG ook hun eerste succes op het
          gebied van data science. In toekomstige projecten zullen we het model verder verbeteren, de scope uitbreiden
          naar alle mijnen, en verder blijven werken aan datagedreven beslissingen nemen.
        </NL>
        <EN>
          The pilot proved that AI will improve the efficiency of RAG. It also gave RAG a first success in the field of
          data science. Future projects will refine the model, expand the scope to include all mines, and continue to
          use data to guide decision making.
        </EN>
      </div>
    </Section>
    <Section size="small">
      <div className="text-container">
        <div className="bg-light-green p-4">
          <div className="row g-4">
            <div className="col-md-3 position-relative">
              <img
                src={Swier}
                alt="Swier"
                className="rounded-circle img-fluid"
                // style={{ maxWidth: '150px' }}
              />
            </div>
            <div className="col-md-9">
              <div className="subtitle mb-0">
                <NL>Van onze data scientists</NL>
                <EN>From our data scientists</EN>
              </div>
              <h3 className="h2">Swier Heeres</h3>
              <p>
                <NL>
                  Swier was Lead data scientist bij dit project met RAG. Zijn achtergrond in de natuurkunde en
                  klimaatwetenschappen combineerde uitzonderlijk goed met zijn kennis van neurale netwerken en
                  Explainable AI.
                </NL>
                <EN>
                  Swier was principle data scientist on the RAG project. His background in Physics and Climate Science
                  combined exceptionally well with his knowledge of neural networks and Explainable AI.
                </EN>
              </p>
              <div className="text-end">
                <Button size="lg" color="green" to="/contact" className="mb-0">
                  <NL>Maak een afspraak</NL>
                  <EN>Get in touch</EN>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  </Page>
)
