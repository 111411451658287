// @flow
import * as React from 'react'
import {
  Button,
  // ClientLogo,
  EN,
  // Hero,
  // HeroTitle,
  // HeroBody,
  NL,
  // Link,
  ProjectPanel,
  // Section,
  BackToTop,
} from '../../components'
import PseuduckThumb from '../../assets/projects/pseuduck.png'

export const PseuduckShort = (): React.Node => (
  <ProjectPanel
    title={
      <>
        <NL>Privacy waarborgen bij data delen</NL>
        <EN>Ensuring privacy when sharing data</EN>
      </>
    }
    photo={PseuduckThumb}
    subtitle={
      <>
        <NL>Pseuduck | Pseudonimiseren | Anonimiseren | Privacy</NL>
        <EN>Pseuduck | Pseudonymising | Anonymising | Privacy</EN>
      </>
    }
  >
    <p>
      <NL>
        Data of bestanden delen, kan niet altijd zomaar. Vaak bevat deze namelijk persoonsgegevens of overige
        privacyschendende informatie. Als je aan de <strong>AVG</strong> wilt voldoen, moet je dus eerst wat werk steken
        in het anonimiseren of pseudonimiseren van de data, voordat je er iets mee mag doen.
      </NL>
      <EN>
        'Just sharing data or documents' is not that simple. Often it contains personal and private information. So if
        you want to comply to the <strong>GDPR</strong>, you will often need to put some time and effort into
        anonymising or pseudonymising the data, before you can work with it.
      </EN>
    </p>
    <p>
      <NL>
        Tot nu wordt het meeste anonimiseringswerk met de hand gedaan. Dit is een hoop werk, kost veel tijd en is – net
        als elke langdurige rotklus – gevoelig voor fouten. Zeker als het gaat om vrije tekst!
      </NL>
      <EN>
        Today, most anonymisation work is done by hand. This a lot of work, costs quite some time and is – like any
        other crappy job – prone to errors. Especially when it concerns free text!
      </EN>
    </p>
    <h3>
      <NL>
        Pseuduck to the rescue voor <span className="text-light-green">AVG-proof teksten</span>
      </NL>
      <EN>
        Pseuduck to the rescue for <span className="text-light-green">GDPR proof texts</span>
      </EN>
    </h3>
    <p>
      <NL>
        Pseuduck is een door ons ontwikkelde pseudonimiseringstool die het gemakkelijker maakt om jouw vrije teksten –{' '}
        <span className="text-light-green strong">van kleine tekstvelden tot grote (collecties van) documenten</span> –
        AVG-proof te maken.
      </NL>
      <EN>
        Pseuduck is a pseudonymisation tool developed by us that makes it easier to make your free texts –{' '}
        <span className="text-light-green strong">
          ranging from small text fields to large (collections of) documents
        </span>{' '}
        – GDPR proof.
      </EN>
    </p>
    <p>
      <NL>
        Het mooie van Pseuduck is dat we het kunnen bijtrainen{' '}
        <span className="strong text-light-green">in verschillende vakgebieden én in het Nederlands</span>, om jouw
        teksten, in jouw vakjargon en naar jouw behoeften te kunnen pseudonimiseren of anonimiseren.
      </NL>
      <EN>
        The benefits of Pseuduck are that we can teach it to specialise further{' '}
        <span className="strong text-light-green">in different fields and sectors</span>, to pseudonymise or anonymise
        your texts, using your jargon and according to your needs and requirements.
      </EN>
    </p>
    {/* <p>
      <NL>
        <strong>Voor zij geïnteresseerd in de data science: </strong> <br />
        <em>
          We maken gebruik van{' '}
          <Link to="https://en.wikipedia.org/wiki/Natural_language_processing">
            Natural Language Programming (NLP)
          </Link>{' '}
          en{' '}
          <Link to="https://en.wikipedia.org/wiki/Named-entity_recognitiong">
            Named Entity Recognition (NER)
          </Link>{' '}
          en tot nu toe hebben we modellen die getraind zijn in de media en in
          de zorg.
        </em>
      </NL>
      <EN>
        <strong>For those interested in the data science:</strong>
        <br />
        <em>
          We use{' '}
          <Link to="https://en.wikipedia.org/wiki/Natural_language_processing">
            Natural Language Programming (NLP)
          </Link>{' '}
          and{' '}
          <Link to="https://en.wikipedia.org/wiki/Named-entity_recognitiong">
            Named Entity Recognition (NER)
          </Link>{' '}
          and up until now, we have trained models in the media and in the
          healthcare sector.
        </em>
      </EN>
    </p> */}
    <h3>
      <NL>Wat voor gevoelige teksten moet jij mee werken?</NL>
      <EN>What sensitive texts do you have to work with?</EN>
    </h3>

    <Button to={{ nl: '/contact', en: '/contact' }} color="white" outline>
      <NL>Neem contact op voor een trial!</NL>
      <EN>Contact us for a trial!</EN>
    </Button>
    <BackToTop />
  </ProjectPanel>
)
