// @flow
import * as React from 'react'
import { NL, EN, ProjectPanel, BackToTop } from '../../components'
import ZooThumb from '../../assets/projects/zoo-thumbnail.jpg'

export const ArtisShort = (): React.Node => (
  <ProjectPanel
    title={
      <>
        <NL>Bezoekersaantallen voorspellen</NL>
        <EN>Predict the visitors</EN>
      </>
    }
    photo={ZooThumb}
    subtitle={
      <>
        <NL>Cultuur | Voorspellen</NL>
        <EN>Culture | Prediction</EN>
      </>
    }
  >
    <p>
      <NL>
        Hoe druk zal het in het park zijn? <strong>Hoeveel gasten kunnen we deze week verwachten?</strong> Een
        dierentuin wilde kunnen voorspellen wat de drukke en rustige momenten van de weken erop zouden zijn.
      </NL>
      <EN>
        How full will the park be? <strong>How many guests can we expect this week?</strong> A zoo wanted to be able to
        predict what the busy and slow hours would be the following weeks.
      </EN>
    </p>
    <h3>
      <NL>Op basis van het verleden</NL>
      <EN>Based on historical data</EN>
    </h3>
    <p>
      <NL>Om dat te kunnen doen hebben we:</NL>
      <EN>In order to do this, we:</EN>
    </p>
    <ul>
      <li>
        <NL>Hun historische data gecombineerd met datasets als het weer, vakanties, evenementen in de stad, etc.</NL>
        <EN>Combined their historical data with datasets like the weather, holidays, events around town, etc.</EN>
      </li>
      <li>
        <NL>Onderzocht welke factoren de grootste impact hebben op bezoekersaantallen</NL>
        <EN>Examined which factors had the largest impact on visitor numbers</EN>
      </li>
      <li>
        <NL>Een AI-model ontwikkeld dat het aantal bezoekers per uur voorspelt</NL>
        <EN>Developed an AI model that predicts the amount of visitors per hour</EN>
      </li>
      <li>
        <NL>
          Alle historische data, de voorspellingen en de externe factoren in een handig dashboard toegankelijk gemaakt
        </NL>
        <EN>
          Represented the historical data, the predictions and the influencing factors in a user friendly dashboard
        </EN>
      </li>
    </ul>
    <h3>
      <NL>Om beter te kunnen plannen</NL>
      <EN>To improve planning</EN>
    </h3>
    <p>
      <NL>
        Met dit AI-model kon de dierentuin{' '}
        <strong>twee weken vooruit, per uur, voorspellen hoeveel bezoekers ze zouden hebben en van welk type</strong>.
        Dit is handig als je betere planningen voor bijvoorbeeld je personeel, aanbiedingen, prijzen of inkoop wilt
        maken.
      </NL>
      <EN>
        This AI is able to make predictions for{' '}
        <strong>how many visitors the park will receive by hour, two weeks in advance</strong>. These numbers are split
        by the type of guest, like card holders – who enter for free, tourists from abroad or groups of schoolchildren.
      </EN>
    </p>
    <h3>
      <NL>En je klanten nóg beter te snappen</NL>
      <EN>And understand your customers even better</EN>
    </h3>
    <p>
      <NL>
        Niet alleen is het fijn om beter te kunnen plannen en zo grote overschotten en tekorten te voorkomen: door{' '}
        <strong>patronen in je data</strong> te zoeken, kan je ook nog eens <strong>tot nieuwe inzichten komen</strong>.
        Jij kent je klanten natuurlijk als geen ander, maar vaak zien we dat er toch nog dingen uit data komen, waar de
        experts nog niet van op de hoogte waren. Bonus!
      </NL>
      <EN>
        Not only is it nice to be able to plan better and thus prevent big surpluses and deficits: by discovering{' '}
        <strong>patterns in your data</strong>, you can also get new insights. Of course you know your customer like no
        other, but we have seen that the{' '}
        <strong>insights from data science can often still surprise even the experts</strong>. Nice bonus!
      </EN>
    </p>
    <BackToTop></BackToTop>
  </ProjectPanel>
)
