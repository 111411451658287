// @flow
import * as React from 'react'
import { useContext } from 'react'
import { HashLink } from 'react-router-hash-link'
import { LanguageContext } from './Lang'
import { navPath } from '../utils/language'

import type { LangString } from '../App'

type Props = {
  ...React.ElementConfig<typeof HashLink>,
  className?: string,
  to: ?LangString,
  target?: string,
  pretty?: boolean,
}

export const Link = (props: Props): React.Node => {
  const context = useContext(LanguageContext)
  const langBase = context != null ? `/${context}` : ''
  const {
    className: extendedClassName = '',
    target,
    to: path,
    pretty = true,
    ...restProps
  } = props
  const className = `${pretty ? 'pretty-link ' : ''}${extendedClassName}`
  const to = path != null ? navPath(path, context) : null
  const isHyperlink =
    to != null &&
    (to.startsWith('http') || to.startsWith('mailto') || to.startsWith('tel'))
  const isHashlink = to != null && to.startsWith('#')
  let destination = {}
  if (isHyperlink) {
    destination = { href: to }
  } else {
    if (isHashlink) {
      destination = { to }
    } else {
      destination = { to: `${langBase}${to != null ? to : ''}` }
    }
  }
  const Component = isHyperlink ? 'a' : HashLink
  return (
    <Component
      {...restProps}
      className={className}
      target={target == null && isHyperlink ? '_new' : target}
      smooth={isHyperlink ? undefined : true}
      {...destination}
    />
  )
}

export const MailtoLink = ({ email, subject, body, component, ...props }: { ...React.ElementProps<typeof Link>, email: string, subject: string, body: string, component?: React.Node}): React.Node => {
  const Component = component || Link
  const url = `mailto:${email}?subject=${encodeURIComponent(subject || '')}&body=${encodeURIComponent((body || '').replace(/<br>/g, '\r'))}`
  const destination = Component === Link ? { to: url } : { href: url }
  return (
  <Component
    {...destination}
    {...props}
  />
  )
}
