// @flow
import * as React from 'react'

type Props = {
  name: string,
  photo: string,
  jobTitle: React.Node,
  hobby: React.Node,
  children: React.Node,
  linkedin: string,
}

export const Teammember = (props: Props): React.Element<'div'> => (
  <div className="team-panel px-xxl-5 col-xl-5 col-md-6 col-12 mb-5">
    <div className="text-center">
      <div className="px-3 mb-3">
        <img
          src={props.photo}
          alt={props.name}
          className="rounded-circle img-fluid"
          style={{ maxWidth: '190px' }}
        />
      </div>
      <h2 className="text-aqua">{props.name}</h2>
      <h4 className="fw-bold text-blue mb-0">{props.jobTitle}</h4>
      <h5 className="my-2 subtitle text-muted mb-2">{props.hobby}</h5>
      <a
        href={props.linkedin}
        target="_blank"
        className="social-media-link"
        rel="noreferrer"
      >
        <i className="fa fa-linkedin-square-blue" />
      </a>
    </div>
    <div style={{ fontSize: '1.05rem' }}>{props.children}</div>
  </div>
)
