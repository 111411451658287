// @flow
import * as React from 'react'
import { useMemo } from 'react'
import _ from 'lodash'
import { Hero, HeroTitle, HeroBody, Section, LocalDate, Link, Page, NL, EN, LangContent } from '../../components'

import type { Stories, Story } from './index'
import type { ContextRouter } from 'react-router-dom'

type Props = {
  ...ContextRouter,
  stories: Stories,
}

function StoryListItem({ story: { metadata } }: { story: Story }) {
  return (
    <div className="card border-0">
      <div className="row g-0">
        <div className="col-md-5">
          <img src={metadata.thumbnail} className="img-fluid" alt={metadata.title} />
        </div>
        <div className="col-md-7">
          <div className="card-body d-flex flex-column h-100 p-4">
            <div className="subtitle mb-1">
              <LocalDate dateString={metadata.date} />
            </div>
            <h5 className="card-title fw-bold">
              <LangContent content={metadata.title} />
            </h5>
            <p className="small d-none d-xl-block">
              <LangContent content={metadata.description} />
            </p>
            <div className="text-end mt-auto">
              <Link to={`/insights/${metadata.slug}`} className="stretched-link small">
                <NL>Lees meer</NL>
                <EN>Read more</EN>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function InsightsHome({ stories }: Props): React.Node {
  const storiesByDate = useMemo(() => _.map(_.orderBy(stories, ['metadata.date'], ['desc']), 'key'), [stories])
  // const colors = ['orange', 'green', 'teal', 'aqua', 'blue', 'dark-blue']
  return (
    <Page
      title={{ nl: 'Insights', en: 'Insights' }}
      description={{
        nl: 'Onze inzichten in AI. In 10 jaar tijd hebben we veel geleerd over AI. Lees onze inzichten over deze hypersnel veranderende wereld.',
        en: 'Learn from our experience in AI. In 10 years time we have learned a lot about AI. Read our insights on this hyper fast changing world.',
      }}
      location={'/portfolio'}
    >
      <Hero className="mb-0 pb-0">
        <div className="subtitle mb-3">
          <NL>Onze inzichten in AI</NL>
          <EN>Learn from our experience in AI</EN>
        </div>
        <HeroTitle>
          <NL>Insights</NL>
          <EN>Insights</EN>
        </HeroTitle>
        <HeroBody>
          <NL>
            In 10 jaar tijd hebben we veel geleerd over AI. Lees onze inzichten over deze hypersnel veranderende wereld.
          </NL>
          <EN>In 10 years time we have learned a lot about AI. Read our insights on this hyper fast changing world.</EN>
        </HeroBody>
      </Hero>

      <Section size="small">
        <div className="container d-flex flex-column" style={{ gap: '1.5rem' }}>
          {storiesByDate.map((key, i) => (
            <StoryListItem key={i} story={stories[key]} />
          ))}
        </div>
      </Section>
    </Page>
  )
}
