// @flow
import * as React from 'react'
import { EN, NL, ProjectPanel, BackToTop } from '../../components'
import Sensordata from '../../assets/projects/sensor-data-thumbnail.jpg'

export const CHDRShort = (): React.Node => (
  <ProjectPanel
    title={
      <>
        <NL>Biometrische sensoren in klinisch onderzoek</NL>
        <EN>Sensordata in clinical research</EN>
      </>
    }
    photo={Sensordata}
    subtitle={
      <>
        <NL>Gezondheid & Zorg | Sensordata</NL>
        <EN>Healthcare | Sensor Data</EN>
      </>
    }
  >
    <p>
      <NL>
        <strong>Hoe kunnen we wearables inzetten in klinisch onderzoek?</strong> Onze klant doet veel medisch onderzoek
        bij proefpersonen en wil graag sensordata voor studies kunnen gebruiken en de grote hoeveelheden data die dat
        oplevert effectief kunnen verwerken.
      </NL>
      <EN>
        <strong>How can we use wearables in clinical research?</strong> Our client frequently does medical research on
        test subjects and wants to utilise sensor data for clinical studies, and be able to process the huge amounts of
        data that these sensors generate.
      </EN>
    </p>
    <h4>
      <NL>Wat hebben wij gedaan?</NL>
      <EN>What did we do?</EN>
    </h4>
    <ul>
      <li>
        <NL>De data onderzocht op bruikbaarheid</NL>
        <EN>Examined sensor data for usability</EN>
      </li>
      <li>
        <NL>Geholpen bij het ontwerp van een onderzoek om dit soort sensordata klinisch te valideren</NL>
        <EN>Helped design a study for clinical validation of sensor-based technology</EN>
      </li>
      <li>
        <NL>Strategisch advies over een in te richten Big-Data-omgeving en te gebruiken tooling</NL>
        <EN>Strategic advice in setting up a Big Data environment and tooling</EN>
      </li>
    </ul>
    <p>
      <NL>
        Gebaseerd op onze <strong>resultaten</strong> is een klinische studie gestart waarin het doel is om patiënten te
        onderscheiden van gezonde proefpersonen.
      </NL>
      <EN>
        Based on our <strong>results</strong> a clinical trial was started in which the principal objective is to
        automatically classify patients of a certain disease from healthy controls.
      </EN>
    </p>
    <p>
      <NL>
        Samen met de klant{' '}
        <strong>
          ontwikkelen we AI-modellen om op basis van biometrische sensoren patiënten direct te kunnen diagnosticeren
        </strong>
        .
      </NL>
      <EN>
        Together with this client we are{' '}
        <strong>developing AI models that can directly diagnose patients from sensor data</strong>.
      </EN>
    </p>
    <BackToTop />
  </ProjectPanel>
)
