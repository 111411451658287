// @flow
import * as React from 'react'
import { useContext } from 'react'

import { Button as ReactstrapButton } from 'reactstrap'
import { HashLink as Link } from 'react-router-hash-link'

import { LanguageContext } from './Lang'

import './Button.scss'
import { navPath } from '../utils/language'
import type { LangString } from '../App'

export type Props = Partial<{
  // our own props
  to: LangString,
  href: string,
  noArrow: boolean,
  type: string,
  target?: string,
  rel?: string,

  // this is taken from the propTypes of the reactstrap Button component
  active: boolean,
  'aria-label': string,
  block: boolean,
  color: string, // default: 'secondary'
  disabled: boolean,
  outline: boolean,

  // Pass in a Component to override default button element
  // example: react-router Link
  // default: 'button'
  // tag: React.ElementType,

  // ref will only get you a reference to the Button component, use innerRef to get a reference to the DOM element (for things like focus management).
  innerRef: Function,

  onClick: Function,
  size: string,
  children: React.Node,
  className: string,

  // use close prop for BS4 close icon utility
  close: boolean,
}>

const whiteText = {
  green: true,
  teal: true,
  aqua: true,
  blue: true,
  orange: true,
}

export const Button = (props: Props): React.Node => {
  const context = useContext(LanguageContext)
  const langBase = context != null ? `/${context}` : ''
  const {
    className: classes,
    href,
    to: path,
    noArrow,
    children,
    size = 'lg',
    ...otherProps
  } = props
  const arrow = noArrow ? null : (
    <span className="arrow">
      <i className="material-icons">keyboard_arrow_right</i>
    </span>
  )
  const textWhite =
    otherProps.color && whiteText[otherProps.color]
      ? !otherProps.outline
        ? 'text-white'
        : 'hover-text-white'
      : ''
  const className = [classes, textWhite, 'Button'].join(' ')
  if (path != null) {
    const to = navPath(path, context)
    const isHashlink = to != null && to.slice(0, 1) === '#'
    return (
      <ReactstrapButton
        {...otherProps}
        size={size}
        className={className}
        tag={Link}
        to={isHashlink ? to : `${langBase}${to}`}
        smooth
      >
        {children}
        {arrow}
      </ReactstrapButton>
    )
  }
  if (href != null) {
    let { target, rel } = props
    if (target === '_blank' && rel == null) {
      rel = 'noopener noreferrer'
    }
    return (
      <ReactstrapButton
        {...otherProps}
        target={target}
        rel={rel}
        size={size}
        className={className}
        tag="a"
        href={href}
      >
        {children}
        {arrow}
      </ReactstrapButton>
    )
  }
  return (
    <ReactstrapButton {...props} size={size} className={className}>
      {children}
      {arrow}
    </ReactstrapButton>
  )
}
