// @flow

import type { LangString } from '../App'

export const navPath = (path: LangString, lang: ?string): string => {
  if (typeof path === 'string') return path
  return path[lang || 'nl']
}

export const chooseLang = (options: LangString, lang: ?string): string => {
  if (typeof options === 'string') return options
  return options[lang || 'nl']
}
