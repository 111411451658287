// @flow
import * as React from 'react'
import ReactGA from 'react-ga4'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { Link, EN, NL } from './'

import type { ContextRouter } from 'react-router-dom'

export const CONSENT_COOKIE: string = 'cookieconsent_status'
export const GOOGLE_TRACKING_ID: string = 'UA-49898681-1'
export const GOOGLE_TRACKING_COOKIE: string = '_ga'
export const GOOGLE_ID_COOKIE: string = '_gid'
export const HOTJAR_TRACKING_ID: number = 447897
export const HOTJAR_SV_ID: number = 6
export const HOTJAR_ID_COOKIE: string = '_hjid'

export function withAnalytics<C: React.ComponentType<any>>(
  WrappedComponent: C
): React.ComponentType<C & ContextRouter> {
  const track = (page: string) => {
    ReactGA.pageview(page)
  }
  return class extends React.Component<ContextRouter> {
    componentDidMount() {
      const page = this.props.location.pathname
      track(page)
    }
    componentDidUpdate(prevProps: ContextRouter) {
      const prevPage = prevProps.location.pathname
      const page = this.props.location.pathname

      if (prevPage !== page) {
        track(page)
      }
    }
    render(): React.Node {
      return <WrappedComponent {...this.props} />
    }
  }
}

const addHotjar = (id: number, sv: number) => {
  const e = document.getElementById('hotjar-script')
  if (e == null)
    // $FlowFixMe[missing-local-annot]
    ((h, o, t, j, a, r) => {
      h.hj =
        h.hj ||
        function () {
          ;(h.hj.q = h.hj.q || []).push(arguments)
        }
      h._hjSettings = { hjid: id, hjsv: sv }
      a = o.getElementsByTagName('head')[0]
      r = o.createElement('script')
      r.setAttribute('id', 'hotjar-script')
      r.async = true
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
      a.appendChild(r)
    })(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=')
}

type Props = {
  setReady: () => void,
}
type State = { consent: ?boolean }

export class Analytics extends React.Component<Props, State> {
  state: State = {
    consent: null,
  }

  componentDidMount() {
    const consent = Cookies.get(CONSENT_COOKIE) === 'allow'
    this.setState({ consent })
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.consent !== this.state.consent) {
      if (this.state.consent) {
        this.allowTracking()
      } else {
        this.denyTracking()
      }
    }
  }

  allowTracking() {
    addHotjar(HOTJAR_TRACKING_ID, HOTJAR_SV_ID)
    ReactGA.initialize(GOOGLE_TRACKING_ID, {
      debug: true || process.env.NODE_ENV === 'development',
      gaOptions: {
        cookieExpires: 63072000,
      },
    })
    ReactGA.set({ anonymizeIp: false })
    this.props.setReady()
  }

  denyTracking() {
    Cookies.remove(GOOGLE_TRACKING_COOKIE)
    Cookies.remove(GOOGLE_ID_COOKIE)
    Cookies.remove(HOTJAR_ID_COOKIE)
    ReactGA.initialize(GOOGLE_TRACKING_ID, {
      gaOptions: {
        cookieExpires: 0,
      },
    })
    ReactGA.set({ anonymizeIp: true })
    this.props.setReady()
  }

  render(): React.Node {
    return (
      <CookieConsent
        buttonText="Ok!"
        declineButtonText="Nope"
        enableDeclineButton
        cookieValue="allow"
        declineCookieValue="deny"
        acceptOnScrollPercentage={101}
        cookieName={CONSENT_COOKIE}
        onAccept={() => this.allowTracking()}
        onDecline={() => this.denyTracking()}
        style={{
          background: '#ffffff',
          color: '#343a40',
        }}
        buttonStyle={{
          background: 'transparent',
          border: '2px solid #1fc8db',
          color: '#1fc8db',
        }}
        declineButtonStyle={{
          background: 'transparent',
          color: '#343a40',
        }}
        // debug={process.env.NODE_ENV === 'development'}
      >
        <NL>
          Wij gebruiken cookies om te zien hoe onze site wordt gebruikt (en
          niets meer).
        </NL>
        <EN>
          We use cookies to analyse how you use our website (and nothing more).
        </EN>
        <Link to="/cookie-policy" className="ms-3 pretty-link small">
          <NL>Lees meer</NL>
          <EN>Learn more</EN>
        </Link>
      </CookieConsent>
    )
  }
}
