// @flow
import * as React from 'react'
import { useState, useContext } from 'react'
import ReactGA from 'react-ga4'
import { LanguageContext, Button, NL, EN } from './'

const SuccessMsg = () => (
  <div className="mt-2 text-success small">
    <NL>Gelukt! We houden je op de hoogte!</NL>
    <EN>Done! We'll keep you posted!</EN>
  </div>
)
const ErrorMsg = () => (
  <div className="mt-2 text-danger small">
    <NL>Er ging iets niet goed, sorry. Probeer het later nog eens.</NL>
    <EN>Sorry, something went wrong. Try again later...</EN>
  </div>
)

export const WebinarUpdateInput = (): React.Element<'form'> => {
  const language = useContext(LanguageContext) || 'nl'
  const [mail, setMail] = useState(null)
  const [feedback, setFeedback] = useState<null | React.Node>(null)
  const host = `${window.location.protocol}//${window.location.host}/webinar-update`
  return (
    <form
      onSubmit={async (event) => {
        event.preventDefault()
        event.stopPropagation()
        try {
          const response = await fetch(host, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mail, location: `${language}/webinar` }),
          })
          if (response.ok) {
            setFeedback(SuccessMsg)
            ReactGA.event({
              category: 'Forms',
              action: 'Submitted',
              label: 'Webinar Email Update Subscription',
            })
          } else throw new Error('HTML Fetch Error')
        } catch (e) {
          setFeedback(ErrorMsg)
          ReactGA.event({
            category: 'Forms',
            action: 'Submission Error',
            label: 'Webinar Email Update Subscription',
          })
        }
      }}
    >
      <div className="form-row align-items-center">
        <div className="col-auto">
          <label htmlFor="mail" className="sr-only">
            Email
          </label>
          <input
            type="email"
            className="form-control form-control-lg"
            id="mail"
            placeholder="Email"
            onChange={(event) => setMail(event.target.value)}
            required
          />
        </div>
        <div className="col-auto">
          <Button type="submit" className="btn btn-lg btn-primary-light mb-0">
            <NL>Verstuur</NL>
            <EN>Send</EN>
          </Button>
        </div>
      </div>
      {feedback}
    </form>
  )
}
