// @flow
import * as React from 'react'
import { EN, NL, ProjectPanel, ClientLogo, Link, BackToTop } from '../../components'
import HealthcareThumb from '../../assets/projects/healthcare-thumbnail.jpg'

export const DHDShort = (): React.Node => (
  <ProjectPanel
    title={
      <>
        <NL>Honderdduizenden medische dossiers automatisch labelen</NL>
        <EN>Labeling hundreds of thousands medical case reports automatically</EN>
      </>
    }
    photo={HealthcareThumb}
    subtitle={
      <>
        <NL>Gezondheid & Zorg | Privacy | NLP</NL>
        <EN>Healthcare | Privacy | NLP</EN>
      </>
    }
  >
    <p>
      <NL>
        Ziekenhuizen in Nederland zijn bij wet verplicht om universele diagnosecodes toe te wijzen aan patiëntdossiers
        (en dit te rapporteren aan de NZa en het CBS). Ziekenhuizen hebben hier specifiek getrainde mensen voor in
        dienst, die deze codetoekenning handmatig doen.{' '}
        <strong>Dit – vaak saaie – mensenwerk kost veel tijd en is (daardoor) ook nog eens vatbaar voor fouten.</strong>
      </NL>
      <EN>
        Hospitals in the Netherlands are required to assign universal diagnosis codes to diagnosed patients, in order to
        report to the Health Authority (NZa) and Statistical Bureau (CBS). These hospitals hire specifically trained
        staff to do this code assigning.{' '}
        <strong>This – often boring – human work costs a lot of time and is therefore also prone to errors.</strong>
      </EN>
    </p>
    <p>
      <NL>
        <Link to="https://dhd.nl">DHD</Link> wilde een manier vinden om de ziekenhuizen en deze codeurs te ondersteunen
        bij deze bewerkelijke taak. Bijvoorbeeld in de vorm van een tool die 'makkelijke gevallen' automatisch verwerkt
        en codeurs ondersteunt bij lastigere gevallen.
      </NL>
      <EN>
        <Link to="https://dhd.nl">DHD</Link> wanted to find a way to support hospitals and labelers in this laborious
        task. For instance a tool that automatically processes 'easy cases' and supports coders with the harder ones.
      </EN>
    </p>
    <h3>
      <NL>Met behulp van AI, zonder privacyschending</NL>
      <EN>Using AI, without privacy violations</EN>
    </h3>
    <p>
      <NL>
        Dit kan met AI. Er bestaan namelijk al miljoenen gelabelde patiëntendossiers (voorbeelden) waarvan geleerd kan
        worden. Deze voorbeelden:{' '}
      </NL>
      <EN>
        This is possible with AI, since millions of patient dossiers (examples) have been labeled before, from which can
        be learned. These examples, however:
      </EN>
    </p>
    <ul>
      <li>
        <NL>
          bevatten echter wel <strong>gevoelige data</strong> van patiënten en artsen die je niet mee mag nemen, en
        </NL>
        <EN>
          contain quite some <strong>sensitive patient and doctor data</strong>, and
        </EN>
      </li>
      <li>
        <NL>
          zijn verdeeld over een hoop verschillende ziekenhuizen die hun <strong>data niet zomaar mogen delen</strong>.
        </NL>
        <EN>
          are spread over a lot of hospitals, who <strong>can't just simply share their data</strong>.{' '}
        </EN>
      </li>
    </ul>
    <p>
      <NL>Daarom hebben we gebruik gemaakt van:</NL>
      <EN>That is why we have used:</EN>
    </p>
    <ul>
      <li>
        <NL>
          <span className="strong text-light-green">
            <Link to="https://en.wikipedia.org/wiki/Natural_language_processing">
              Natural Language Programming (NLP)
            </Link>
          </span>
          ,
        </NL>
        <EN>
          <span className="strong text-light-green">
            <Link to="https://en.wikipedia.org/wiki/Natural_language_processing">
              Natural Language Programming (NLP)
            </Link>
          </span>
          ,
        </EN>
      </li>
      <li>
        <NL>
          <span className="strong text-light-green">
            <Link to="https://nl.wikipedia.org/wiki/Pseudonimiseren">pseudonimisering</Link>
          </span>{' '}
          en
        </NL>
        <EN>
          <span className="strong text-light-green">
            <Link to="https://en.wikipedia.org/wiki/Pseudonymization">pseudonymisation</Link>
          </span>{' '}
          and
        </EN>
      </li>
      <li>
        <NL>
          <span className="strong text-light-green">
            <Link to="https://en.wikipedia.org/wiki/Federated_learning">federated learning</Link>
          </span>
        </NL>
        <EN>
          <span className="strong text-light-green">
            <Link to="https://en.wikipedia.org/wiki/Federated_learning">federated learning</Link>
          </span>
        </EN>
      </li>
    </ul>
    <p>
      <NL>
        om een <strong>tool</strong> te ontwikkelen die uit de <strong>vrije tekst van patiëntendossiers</strong> (bijv.
        ontslagbrieven en OK-verslagen) kan herkennen welke <strong>ICD-10-codes</strong> van toepassing zijn en deze
        toekent.
      </NL>
      <EN>
        to develop a <strong>tool</strong> that can recognise what <strong>ICD-10</strong> codes are applicable to
        patient dossiers from the <strong>free text</strong> in e.g. discharge letters and OR reports.
      </EN>
    </p>
    <h3>
      <NL>Tijdswinst met zekerheid en uitlegbaarheid</NL>
      <EN>Saving time with certainty and explainability</EN>
    </h3>
    <p>
      <NL>
        Bij het ontwikkelen van een AI-model moet je altijd oppassen dat het <strong>de juiste dingen</strong> leert.
        Zeker als het om <strong>gevoelige toepassingen</strong> gaat, moet je ervoor zorgen dat het zo min mogelijk
        fouten maakt. En dat je snapt waarom een model bepaalde beslissingen maakt. Pas dan kan je taken volledig
        automatiseren. Als je dat überhaupt al zou willen.
      </NL>
      <EN>
        When developing AI models, you should make sure that they are learning
        <strong>the right things</strong>. Especially when it concerns <strong>sensitive applications</strong>, you
        should make sure the AI makes only few mistakes. And that you understand why it makes what decisions. Only then
        you can fully automate tasks. Which you should not always want.
      </EN>
    </p>
    {/*<h5>
      <strong>
        <NL>Liever zeker dan automatisch</NL>
        <EN>Safety over automation</EN>
      </strong>
        </h5>*/}
    <p>
      <NL>
        Met de voorwaarde dat het model 80% zeker moet zijn, kon ons AI-model al snel{' '}
        <strong>meer dan 30% van de codes volledig automatisch toekennen</strong>. Dat klinkt misschien laag, maar stel
        je voor dat je een derde van je werktijd zou kunnen besparen!
      </NL>
      <EN>
        Given the requirement that it should be at least 80% certain, our AI model (algorithm) was able to{' '}
        <strong>automatically assign over 30%</strong> of the codes. This might sound low, but imagine that you could
        already save a third of your work time!
      </EN>
    </p>
    <p>
      <NL>
        Voor gevallen waar de zekerheid van de AI onder de gewenste drempel valt, wordt de gebruiker een{' '}
        <strong>top 5 van waarschijnlijkste codes voorgelegd (met uitleg!)</strong>, zodat de juiste gemakkelijk
        handmatig gekozen kan worden. Dit bespaart nog steeds veel tijd t.o.v. het met de hand opzoeken van het juiste
        label zonder voorselectie.
      </NL>
      <EN>
        For cases where the AI's certainty was below this very high threshold, the algorithm can give a{' '}
        <strong>top 5 of codes most likely to match the case (with explanation!)</strong>, to drastically reduce the
        time required to manually assign the label.
      </EN>
    </p>
    <ClientLogo name="DHD" />
    <BackToTop />
  </ProjectPanel>
)
