// @flow
import * as React from 'react'

import {
  BackToTop,
  BigIcon,
  Button,
  ClientLogos,
  EN,
  FinServicesLogos,
  HealthcareLogos,
  IndustryLogos,
  NL,
  Page,
  ResearchAndMediaLogos,
  Section,
  ServiceBlock,
  WallpaperSection,
} from '../components'

// Images
// import Arch from '../assets/landscape-arch.jpg'
import DictWhite from '../assets/noun_dictionary_2684619_white.png'
import MapWhite from '../assets/noun_Map_2473704_white.png'
import BrainWhite from '../assets/noun_Brain_3663976_white.png'
import DictGreen from '../assets/noun_dictionary_2684619_green.png'
import MapTeal from '../assets/noun_Map_2473704_teal.png'
import BrainAqua from '../assets/noun_Brain_3663976_aqua.png'

import Traffic from '../assets/traffic.jpg'
import LandscapeWorkshop from '../assets/landscape-workshop-teal.jpg'
//import LandscapeDesk from '../assets/landscape-working-environment.jpg'
import UitlegFedde from '../assets/Borduitleg_Fedde_aqua.png'
import Physics from '../assets/Physics_rommeltje_green.png'
import DemoThomas from '../assets/Demo_Thomas_orange.png'

const Home = (): React.Node => (
  <Page
    title={{}} // use defaults
    description={{
      nl: 'Van idee naar waardevolle AI-oplossing: laat Artificial Intelligence en Machine Learning slagen binnen je organisatie en ontdek wat Big Data voor jouw organisatie kan doen.',
      en: 'From idea to valuable AI solution: create value from data with Artificial Intelligence and Machine Learning and learn what Big Data can mean for your organisation.',
    }}
    location="/"
  >
    <WallpaperSection
      wallpaper={Traffic}
      divider="none"
      className="my-0"
      wallpaperStyle={{ filter: 'brightness(40%)' }}
    >
      <div className="container">
        <div className="p-0">
          <h1 className="giant-responsive">
            <NL>
              De potentie van je <span className="text-light-green">data en AI </span>benutten.
              <br />
              <span className="text-light-green">Ook voorbij de hype.</span>
            </NL>
            <EN>
              Want to utilize your full <span className="text-light-green">data and AI</span> potential.
              <br />
              <span className="text-light-green">Also beyond the hype.</span>
            </EN>
          </h1>
          <div className="text-container ms-0">
            <p className="lead strong">
              <NL>
                Wil je ontdekken wat AI voor jouw organisatie kan betekenen? Weet je al welke AI-oplossingen je nodig
                hebt, maar heb je expertise niet in huis? Ben je op zoek naar een data-partner die meedenkt en -werkt?
                Wij helpen je graag!
              </NL>
              <EN>
                Want to discover how AI can be of value for your organisation? Do you already know what AI solutions you
                need, but don't have the needed expertise? Looking for a data partner that thinks and works along with
                you? Let's talk!
              </EN>
            </p>
          </div>
        </div>
      </div>
    </WallpaperSection>

    <Section>
      <div className="text-container">
        <div className="subtitle">
          <NL>Hoe wij je helpen met data en AI</NL>
          <EN>How we help you with data and AI</EN>
        </div>
        <h1 className="text-dark-blue mb-3">
          <NL>Van idee naar waardevolle AI-oplossing</NL>
          <EN>From idea to valuable AI solution</EN>
        </h1>
        <NL>
          <p>
            Waardevolle AI-oplossingen komen niet zomaar uit de lucht vallen. En als ze dat deden zou het de vraag zijn
            of ze begrepen, geaccepteerd en gebruikt worden. Zo niet, is dat natuurlijk hartstikke zonde van je
            investering.
          </p>
          <p>
            Daarom is het belangrijk dat je de ontwikkeling van AI-oplossingen goed aanpakt. Met maatwerk. Van begin
            (een AI-idee) tot eind (een AI-implementatie) en daar voorbij (uitbreiding en optimalisatie).
          </p>
          <p>Volgens ons doe je dat als volgt!</p>
        </NL>
        <EN>
          <p>
            Valuable AI solutions don't just come falling from the sky. And if they did, the question would be whether
            they would be understood, accepted and used. If not, this would be a complete waste of your investment of
            course.
          </p>
          <p>
            That's why it is important to approach developing AI solutions the right way. With a customized approach.
            From start (an AI idea) to finish (an AI solution) and beyond (expansion and optimization).
          </p>
          <p>According to us, you should do this as follows!</p>
        </EN>

        <Button color="aqua" to="#ontdek" className="me-3" size="lg">
          <NL>Ontdek</NL>
          <EN>Discover</EN>
        </Button>
        <Button color="teal" to="#onderzoek" className="me-3" size="lg">
          <NL>Onderzoek & Ontwerp</NL>
          <EN>Detect & Design</EN>
        </Button>
        <Button color="green" to="#ontwikkel" className="me-3" size="lg">
          <NL>Ontwikkel</NL>
          <EN>Develop</EN>
        </Button>
      </div>
    </Section>

    <WallpaperSection
      size="small"
      wallpaper={UitlegFedde}
      wallpaperStyle={{
        filter: 'brightness(80%) blur(1px)',
        top: '50%',
        transform: 'translate(-50%, -48%)',
      }}
      style={{ minHeight: '350px' }}
    >
      <div className="text-container text-white">
        <div className="subtitle">
          <NL>Ontdek | Onderzoek | Ontwerp | Ontwikkel</NL>
          <EN>Discover | Detect | Design | Develop</EN>
        </div>
        <h2 className="h1 mb-0">
          <NL>In logische stappen naar AI-succes!</NL>
          <EN>Take logical steps to AI success!</EN>
        </h2>
      </div>
    </WallpaperSection>

    <Section size="small" className="mt-0 pt-0">
      <div className="text-container">
        <Section
          size="small"
          id="ontdek"
          className="offset-3 col-9 col-lg-12 offset-lg-0 step-section step-section-aqua"
        >
          <BigIcon img={BrainAqua} color="aqua" alt="Learn" />
          <h2 className="text-aqua">
            <NL>Ontdek jouw data- en AI-potentie</NL>
            <EN>Discover your data and AI potential</EN>
          </h2>
          <p className="text-grey lead fw-bold">
            <NL>Wat kan jij eigenlijk met jouw data en AI?</NL>
            <EN>What can you actually do with your data and AI?</EN>
          </p>
          <p>
            <NL>
              Heb je het gevoel dat je je data onvoldoende benut? Dat je op bergen kennis zit die je niet gebruikt? Heb
              je het gevoel dat je concurrentie veel innovatiever werkt dan jij? Wil jij ook tijd, energie en moeite
              besparen door je data en AI goed in te zetten? Maar weet je eigenlijk niet waar je moet beginnen? Of weet
              je überhaupt nog te weinig over data en AI?
            </NL>
            <EN>
              Do you have the feeling that you are under-utilizing your data? That you are leaving heaps of knowledge
              unused? Do you feel like your competition is way more innovative than you are? Would you like to save
              time, energy, and effort by optimally utilizing your data and AI? But don't really know where to start? Or
              do you know too little about data and AI in the first place?
            </EN>
          </p>
          <Button className="me-3" color="aqua" to={{ nl: '/diensten#ontdek', en: '/services#ontdek' }}>
            {' '}
            <NL>Begin dan hier</NL>
            <EN>Then start here</EN>
          </Button>
          <Button className="me-3" color="aqua" to="#onderzoek" outline>
            {' '}
            <NL>Ik heb al een idee</NL>
            <EN>I already have an idea</EN>
          </Button>
        </Section>

        <Section
          size="small"
          id="onderzoek"
          className="offset-3 col-9 col-lg-12 offset-lg-0 step-section step-section-teal"
        >
          <BigIcon img={MapTeal} color="teal" alt="Map" />
          <h2 className="text-teal">
            <NL>Onderzoek en ontwerp jouw AI-aanpak</NL>
            <EN>Determine and design your AI approach</EN>
          </h2>
          <p className="text-grey lead fw-bold">
            <NL>Ga van AI-idee naar een plan van aanpak</NL>
            <EN>Go from AI idea to action plan</EN>
          </p>
          <p>
            <NL>
              Een tof AI-idee is soms niet meer dan dat: 'een tof idee', dat niet realiseerbaar is of geen echte
              toegevoegde waarde zal leveren. Maar soms wel! Dit hangt af van een hoop factoren: je data, je mensen, je
              infrastructuur, je processen, de wet... Het is zonde om tijd en geld in een AI-product te stoppen om er
              later achter te komen dat het niet bruikbaar is. Daarom is het belangrijk eerst die randvoorwaarden te
              onderzoeken. Pas dan kan je een waardevolle oplossing ontwerpen!
            </NL>
            <EN>
              A cool AI idea is sometimes not more than that. But sometimes it is! This depends on a lot of factors:
              your data, your people, your infrastructure, your processes, the law... It would be a pity to invest in an
              AI product to later discover that it is not applicable in practice. That is why it is important to first
              determine what these conditions are. Only then you can start designing a valuable solution!
            </EN>
          </p>
          <Button
            className="me-3"
            color="teal"
            to={{
              nl: '/diensten#onderzoek',
              en: '/services#onderzoek',
            }}
          >
            <NL>Meer over Solution Design</NL>
            <EN>More on Solution Design</EN>
          </Button>
          <Button className="me-3" color="teal" to="#ontwikkel" outline>
            <NL>Beginnen met ontwikkelen</NL>
            <EN>Start developing</EN>
          </Button>
        </Section>

        <Section
          size="small"
          id="ontwikkel"
          className="offset-3 col-9 col-lg-12 offset-lg-0 step-section step-section-green"
        >
          <BigIcon img={DictGreen} color="green" alt="AI Solutions" />
          <h2 className="text-green">
            <NL>Ontwikkel waardevolle AI-oplossingen</NL>
            <EN>Develop valuable AI solutions</EN>
          </h2>
          <p className="text-grey lead fw-bold">
            <NL>Samen maken we veilige, transparante en uitlegbare AI-modellen</NL>
            <EN>Together we make safe, transparent and explainable AI models</EN>
          </p>
          <p>
            <NL>
              Overtuigd van het plan? Onze data scientists gaan graag met en voor je aan de slag. Samen met jouw team
              ontwikkelen we de AI- en machine-learning-modellen die je nodig hebt. Dat doen we in sprints, gericht op
              efficiënt werken aan de oplossing die de meeste waarde biedt. Van proof of concept naar werkende
              AI-implementatie. Altijd met een oog op veilig en fijn gebruik!
            </NL>
            <EN>
              Convinced by the the plan? Our data scientists can't wait to get to work for and with you. Together with
              your team – from business to engineers and also data scientists – we develop the AI and ML models that you
              need. We do this in sprints, so we efficiently work on the solutions that create the most value with the
              least risk. From proof of concept to operational AI implementation. Always with an eye on safe and
              friendly use!
            </EN>
          </p>
          <Button className="me-3" color="green" to={{ nl: '/diensten#ontwikkel', en: '/services#ontwikkel' }}>
            <NL>Meer over AI ontwikkelen</NL>
            <EN>More on developing AI</EN>
          </Button>
          <Button className="me-3" color="green" to={{ nl: '/portfolio', en: '/portfolio' }} outline>
            <NL>Bekijk ons portfolio</NL>
            <EN>Check out our portfolio</EN>
          </Button>
        </Section>
        <BackToTop />
      </div>
    </Section>

    <WallpaperSection
      size="small"
      wallpaper={LandscapeWorkshop}
      wallpaperStyle={{
        filter: 'brightness(80%) blur(1px)',
        top: 0,
        transform: 'translate(-50%, 0)',
      }}
      style={{ minHeight: '350px' }}
    >
      <div className="text-container text-white">
        <div className="subtitle">
          <NL>Wat we eerder deden</NL>
          <EN>What we did before</EN>
        </div>
        <h2 className="h1 mb-0">
          <NL>Dit is wat je met AI & ML kunt bereiken</NL>
          <EN>This is what you can achieve with AI & ML</EN>
        </h2>
      </div>
    </WallpaperSection>

    <Section size="small">
      <div className="text-container">
        <NL>
          <p>
            AI en ML (Machine Learning) komen in vele verschillende vormen. En al zou je je tot één sector of vorm
            beperken, dan kunnen oplossingen en resultaten er alsnog anders uit zien bij verschillende organisaties. Je
            zult bij AI en ML altijd te maken hebben met maatwerk!
          </p>
          <p>
            Dat neemt niet weg dat je je wel kunt laten inspireren door de resultaten van anderen. Kennis en code zijn
            namelijk wel herbruikbaar, en laten we die nu net hebben!
          </p>
        </NL>
        <EN>
          <p>
            AI and ML (Machine Learning) come in many forms. And even if you would limit yourself to one sector and one
            form, the solutions and results would still look different at different organizations. With AI and ML you
            will always need customization!
          </p>
          <p>
            That doesn't mean that you can't let yourself be inspired by solutions and results of others. Knowledge and
            code are reusable and let us just have that!
          </p>
        </EN>
        {/* Insert witregel / ruimte */}
        <div className="row g-2 my-lg-5 my-4 flex-column flex-md-row">
          <ServiceBlock
            color="green"
            icon={DictWhite}
            iconHover={DictGreen}
            to="/portfolio#selectical"
            title={
              <>
                <NL>Literatuuronderzoek in 1/3 van de tijd</NL>
                <EN>Literature research in 1/3 of the time</EN>
              </>
            }
            linkText={
              <>
                <NL>met Active Learning</NL>
                <EN>Discover your AI potential</EN>
              </>
            }
          />
          <ServiceBlock
            color="teal"
            icon={MapWhite}
            iconHover={MapTeal}
            to="/portfolio#dhd"
            title={
              <>
                <NL>Miljoenen dossiers automatisch labelen</NL>
                <EN>Labelling millions of dossiers automatically</EN>
              </>
            }
            linkText={
              <>
                <NL>met NLP</NL>
                <EN>with NLP</EN>
              </>
            }
          />
          <ServiceBlock
            color="aqua"
            icon={BrainWhite}
            iconHover={BrainAqua}
            to="/portfolio#artis"
            title={
              <>
                <NL>Druk(te) voorspellen</NL>
                <EN>Predicting demand</EN>
              </>
            }
            linkText={
              <>
                <NL>met historische data</NL>
                <EN>with historic data</EN>
              </>
            }
          />
        </div>
        <Button
          color="teal"
          to={{
            nl: '/portfolio',
            en: '/portfolio',
          }}
        >
          <NL>Bekijk ons portfolio</NL>
          <EN>Check out our portfolio</EN>
        </Button>
        <BackToTop />
      </div>
    </Section>

    <WallpaperSection
      size="regular"
      wallpaper={Physics}
      wallpaperStyle={{
        filter: 'blur(4px)',
      }}
      dividerGradient="teal-green-orange"
      style={{ minHeight: '300px' }}
    >
      <div className="container text-white">
        <div className="text-container">
          <div className="subtitle">
            <NL>Met wie we werken</NL>
            <EN>Who we work with</EN>
          </div>
          <h2 className="h1">
            <NL>Van meerdere markten thuis</NL>
            <EN>At home in multiple sectors</EN>
          </h2>
          <div className="strong lead">
            <NL>
              We werken niet slechts met of voor één sector. Graag werken we multidisciplinair en met meerdere typen
              organisaties om:
              <ul className="mt-2">
                <li>onszelf scherp en uitgedaagd te houden,</li>
                <li>jou te laten profiteren van 'best practices' waar je wellicht niet aan gedacht hebt.</li>
              </ul>
            </NL>
            <EN>
              We don't only work with or for 1 specific sector. We prefer to work interdisciplinarily and with different
              types of organisations to:
              <ul className="mt-2">
                <li>stay sharp and keep challenging ourselves,</li>
                <li>let you profit from best practices you might not have thought of before.</li>
              </ul>
            </EN>
          </div>
        </div>
      </div>
    </WallpaperSection>

    <Section size="small">
      <div className="text-container">
        <h3 className="text-dark-blue">
          <NL>Scroll snel door naar:</NL>
          <EN>Quickly scroll on to:</EN>
        </h3>
      </div>
      <div className="text-center">
        <Button className="me-3" color="blue" size="lg" to="#Healthcare">
          <NL>Gezondheid & Zorg</NL>
          <EN>Healthcare</EN>
        </Button>
        <Button className="me-3" color="aqua" size="lg" to="#Industry">
          <NL>Industrie</NL>
          <EN>Industry</EN>
        </Button>
        <Button className="me-3" color="teal" size="lg" to="#FinServices">
          <NL>Dienstverlening</NL>
          <EN>Services</EN>
        </Button>
        <Button className="me-3" color="green" size="lg" to="#ResearchAndMedia">
          <NL>Onderzoek & Media</NL>
          <EN>Research & Media</EN>
        </Button>
        <Button className="me-3" color="orange" size="lg" to="#all">
          <NL>Overig</NL>
          <EN>Other</EN>
        </Button>
      </div>
    </Section>

    <Section size="small" id="Healthcare">
      <div className="text-container">
        <div className="subtitle">
          <NL>Druk verlagen in de</NL>
          <EN>Reducing pressure in</EN>
        </div>
        <h2 className="text-blue mb-3">
          <NL>Gezondheid & Zorg</NL>
          <EN>Healthcare</EN>
        </h2>
        <NL>
          <p>
            De werkdruk in de zorg is ontzettend hoog – en sterk groeiende – en verzorgt nu al uitstel en uitval. Met AI
            kunnen we deze druk verlagen. Niet door de zorg te automatiseren waardoor deze onmenselijker wordt, maar
            door te focussen op de administratie, zodat de mens weer meer aandacht voor de mens kan hebben.
          </p>
        </NL>
        <EN>
          <p>
            The pressure in the healthcare sector is incredibly high – and increasing – and is already causing waiting
            lines and drop-outs. With AI we can reduce this pressure. Not by automating care tasks, which dehumanises
            them, but by focussing on administration, so that people can continue focussing on people.
          </p>
        </EN>
      </div>
      <div className="text-container">
        <div className="row g-4 g-lg-5 mb-3">
          {/* [Insert logo's DHD, LUMC, VerenSO, AmsterdamUMC, CHDR, Levels,
            Astellas] */}
          <HealthcareLogos className="col-4 col-sm-4 col-md-3" />
        </div>
      </div>
      {/* Insert witregel / ruimte */}
      <div className="text-container">
        <Button className="me-3" color="blue" to={{ nl: '/portfolio-zorg', en: '/portfolio-healthcare' }}>
          <NL>Meer over AI in de zorg</NL>
          <EN>More about AI in healthcare</EN>
        </Button>
      </div>
    </Section>

    <Section size="small" id="Industry">
      <div className="text-container">
        <div className="subtitle">
          <NL>Processen optimaliseren in de</NL>
          <EN>Optimizing processes in the</EN>
        </div>
        <h2 className="text-aqua mb-3">
          <NL>Industrie</NL>
          <EN>Industry</EN>
        </h2>
        <NL>
          <p>
            Als het gaat om industrie en AI, denkt men al snel aan robots. Maar dat is zeker niet de enige manier waarop
            je AI in kunt zetten. Het kan ook ingezet worden om (natuurlijke) processen beter te begrijpen of juist te
            optimaliseren.
          </p>
        </NL>
        <EN>
          <p>
            When thinking about industry and AI, people usually picture robots. But that is definitely not the only form
            in which AI can be found there. You can also leverage it to better understand (natural) processes or
            optimize these.
          </p>
        </EN>
      </div>
      <div className="text-container">
        <div className="row g-4 g-lg-5">
          {/* [Insert logo's RAG, Steag, EKu.SIM, DFC, Airborne, Inventeers, Strohm] */}
          <IndustryLogos className="col-4 col-sm-4 col-md-3" />
        </div>
      </div>
      {/* Insert witregel / ruimte */}
      <div className="text-container">
        <Button className="me-3" color="aqua" to={{ nl: '/portfolio-industrie', en: '/portfolio-industry' }}>
          <NL>Meer over AI in de industrie</NL>
          <EN>More about AI in industry</EN>
        </Button>
      </div>
    </Section>

    <Section size="small" id="FinServices">
      <div className="text-container">
        <div className="subtitle">
          <NL>Grip op je klanten in de</NL>
          <EN>Understanding your clients in the</EN>
        </div>
        <h2 className="text-teal mb-3">
          <NL>Financiële en zakelijke dienstverlening</NL>
          <EN>Financial and business services</EN>
        </h2>
        <NL>
          <p>
            Weet je hoe je (potentiële) klanten zich gedragen? Hoe je ze het beste aan kunt spreken? Wat de kans is dat
            ze een bepaald product zullen afnemen? Wanneer je drukte kunt verwachten? Je data en AI kan je inzetten om
            hier meer grip op te krijgen!
          </p>
        </NL>
        <EN>
          <p>
            Do you know your (potential) clients behavioural patterns? How you can best address them? What's the chance
            that they will buy a certain product? When you can expect a lot of demand? Your data and AI can be used to
            get more insight into these patterns!
          </p>
        </EN>
      </div>
      <div className="text-container">
        <div className="row g-4 g-lg-5">
          {/* [Insert logo's Univé, Zorg en Zekerheid, Zig, Whooz, Nijestee, Blaauwberg] */}
          <FinServicesLogos className="col-4 col-sm-4 col-md-3" />
        </div>
      </div>
      {/* Insert witregel / ruimte */}
      <div className="text-container">
        <Button className="me-3" color="teal" to={{ nl: '/portfolio', en: '/portfolio' }}>
          <NL>Bekijk ons portfolio</NL>
          <EN>Check out our portfolio</EN>
        </Button>
      </div>
    </Section>

    <Section size="small" id="ResearchAndMedia">
      <div className="text-container">
        <div className="subtitle">
          <NL>Wegwijs in tekst als</NL>
          <EN>Working magic with text as</EN>
        </div>
        <h2 className="text-green mb-3">
          <NL>Onderzoek, Media & Uitgeverijen</NL>
          <EN>Research, Media & Publishing</EN>
        </h2>
        <NL>
          <p>
            Tot op heden zit er ontzettend veel kennis 'verborgen' in grote hoeveelheden 'vrije tekst'. Dat is zonde EN
            onnodig, want met de huidige NLP-technieken is hier veel waarde uit te halen!
          </p>
          <p>
            Dus heb jij of werk jij met grote hoeveelheden tekst? Wil je dat deze beter vindbaar en doorzoekbaar is? Ben
            je veel tijd kwijt aan samenvatten en administreren? Dan zou je zomaar AI in kunnen zetten!
          </p>
        </NL>
        <EN>
          <p>
            To this day a lot of knowledge is 'trapped' in huge amounts of 'free text'. That's a pity AND unnecessary,
            since with the current NLP techniques we can extract a lot of value from these texts!{' '}
          </p>
          <p>
            So do you have or work with big amounts of (free) text? Want these to be easily findable and searchable? Are
            you losing a lot of time on summarizing and administration? Then you might just be able to use AI to lighten
            the load!
          </p>
        </EN>
      </div>
      <div className="text-container">
        <div className="row g-4 g-lg-5">
          {/* [Insert logo's TNO, Universiteit Leiden, Brill, Pallas, Wepublic] */}
          <ResearchAndMediaLogos className="col-4 col-sm-4 col-md-3" />
        </div>
      </div>
      {/* Insert witregel / ruimte */}
      <div className="text-container">
        <Button className="me-3" color="green" to={{ nl: '/portfolio', en: '/portfolio' }}>
          <NL>Bekijk ons portfolio</NL>
          <EN>Check out our portfolio</EN>
        </Button>
      </div>
    </Section>

    <Section size="small" id="all">
      <div className="text-container">
        <div className="subtitle">
          <NL>Waarde met data en AI in</NL>
          <EN>Value with data and AI in</EN>
        </div>
        <h2 className="text-orange mb-3">
          <NL>Alle sectoren</NL>
          <EN>All sectors</EN>
        </h2>
        <p>
          <NL>
            Net als I(C)T in het algemeen, kan je data en zo machine learning en AI in alle sectoren inzetten. Daar
            helpen wij dan ook graag bij. Als jij én wij denken waarde te kunnen creëren in jouw sector, maken wij daar
            ons hard voor! Of dit nu de culturele, publieke, juridische of andere sector is.
          </NL>
          <EN>
            Just like I(C)T in general, data and as such machine learning and AI can be used in any sector. And that's
            what we like to help with! If you ánd we believe that you can create value with AI in your sector, let's go
            for it! Whether this is the cultural, public, legal or other sector.
          </EN>
        </p>
      </div>
      <div className="text-container">
        <div className="row g-4 g-lg-5">
          {/* [Insert logo's Artis, HiiL, Naturalis ?] */}
          <ClientLogos className="col-4 col-sm-4 col-md-3" subset={['Artis', 'HiiL', 'Naturalis']} />
        </div>
      </div>
      <div className="text-container">
        <Button className="me-3" color="orange" to={{ nl: '/portfolio', en: '/portfolio' }}>
          <NL>Bekijk ons portfolio</NL>
          <EN>Check out our portfolio</EN>
        </Button>
      </div>
    </Section>

    <WallpaperSection
      size="small"
      wallpaper={DemoThomas}
      wallpaperStyle={{
        filter: 'blur(2px)',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      dividerGradient="teal-green-orange"
      style={{ minHeight: '350px' }}
    >
      <div className="container text-white">
        <div className="row">
          <div className="col-12 col-lg-10 offset-lg-1">
            <div className="subtitle">
              <NL>Over ons</NL>
              <EN>About us</EN>
            </div>
            <h2 className="h1 mb-0">
              <NL>
                Samen maken we mensgerichte AI-oplossingen <i>wanneer we die waardevol achten</i>
              </NL>
              <EN>
                Together we make human centered AI solutions <i>when we deem them valuable</i>
              </EN>
            </h2>
          </div>
        </div>
      </div>
    </WallpaperSection>
    <Section size="small">
      <div className="text-container">
        <div className="row">
          <h3 className="title-font text-orange">
            <NL>Als AI-specialist tegen AI?</NL>
            <EN>An AI specialist against AI?</EN>
          </h3>
          <p>
            <NL>
              Van data- en AI-specialisten als wij zou je verwachten dat we graag overal AI zien. "AI kan alles
              oplossen!" Maar dat is zeker niet zo.{' '}
              <b>AI kan écht veel waarde creëren. Maar soms is het simpelweg overbodig</b> en dan zullen we het je ook
              niet proberen aan te smeren! Sterker nog: we zullen het je dan <i>afraden</i>.{' '}
            </NL>
            <EN>
              From data and AI specialists like us you would expect that we'd prefer to see AI implemented everywhere.
              "AI can solve everything!" But that is definitely not true.
              <b>AI can really create a lot of value. But sometimes it's simply unnecessary</b> and then we will not try
              to sell it to you. In fact: we will then <i>advise against using AI</i>.{' '}
            </EN>
          </p>
          <p>
            <NL>
              <b>Is dat slecht voor de AI-business? Nee hoor.</b> Wij zorgen ervoor dat je oplossingen krijgt waar je
              blij van wordt, die je echt helpen en die niet op de plank belanden. Daar worden wij ook blij van.
            </NL>
            <EN>
              <b>Is that bad for the AI business? Nope.</b> We make sure that you get solutions that make you happy,
              that really help you and that don't end up being unused. That makes us happy too.
            </EN>
          </p>
          <h3 className="title-font text-orange">
            <NL>'We are in it for the long-haul'</NL>
            <EN>We are in it for the long-haul</EN>
          </h3>
          <p>
            <NL>
              We zullen dus altijd eerlijk zijn over waar je wel of niet aan moet beginnen wat betreft data en AI. We
              denken graag met je mee van begin tot eind: vanaf het punt dat AI nog onbereikbaar lijkt, tot het
              implementeren en optimaliseren van meerdere waardevolle oplossingen.
            </NL>
            <EN>
              So we will always be honest about which roads to take when it comes to data and AI. We like to think along
              with you from start to finish: from the point that AI still feels unattainable to implementing and
              optimising multiple valuable solutions.
            </EN>
          </p>
          <h3 className="title-font text-orange mb-5">
            <NL>Zie ons als jouw data- en AI-partner</NL>
            <EN>See us as your data and AI partner</EN>
          </h3>
        </div>
      </div>
      <div className="text-container">
        <Button className="me-3" color="orange" to={{ nl: '/over-ons', en: '/about-us' }}>
          <NL>Leer ons nog beter kennen</NL>
          <EN>Get to know us even better!</EN>
        </Button>
        <Button className="me-3" color="orange" outline to={{ nl: '/contact', en: '/contact' }}>
          <NL>Neem direct contact op</NL>
          <EN>Contact us directly</EN>
        </Button>
      </div>
    </Section>
  </Page>
)

export default Home
