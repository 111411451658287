// @flow
import * as React from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const ScrollToTop = (props: { children: React.Node }): React.Node => {
  const location = useLocation()
  const { pathname, hash } = location
  useEffect(() => {
    // Only scroll to top when we shouldn't scroll somewhere else
    if (hash == null || hash === '') window.scrollTo(0, 0)
  }, [pathname, hash])
  return props.children
}
