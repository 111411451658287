// @flow
import * as React from 'react'
import { NL, EN } from './'

type Props = {
  className?: string,
}

export const BackToTop = ({
  className,
  ...props
}: Props): React.Element<'div'> => (
  <div
    {...props}
    className={`small d-flex justify-content-end align-items-center cursor-pointer ${
      className != null ? className : ''
    }`}
    style={{ opacity: 0.6 }}
  >
    <div
      className="ms-auto"
      onClick={() => window.scroll({ top: 0, behavior: 'smooth' })}
    >
      <span>
        <NL>Terug naar boven</NL>
        <EN>Back to top</EN>
      </span>
      <i
        className="material-icons mx-1"
        style={{ transform: 'rotate(-90deg)' }}
      >
        chevron_right
      </i>
    </div>
  </div>
)
