// @flow
import * as React from 'react'

import { Link } from '../components'

import './ServiceBlock.scss'

type Props = {
  icon: string,
  iconHover: string,
  color: string,
  title: React.Node,
  bodyText?: React.Node,
  linkText: React.Node,
  to: string,
}

export const ServiceBlock = ({
  icon,
  iconHover,
  color,
  linkText,
  to,
  title,
  bodyText,
}: Props): React.Element<'div'> => (
  <div className={`col service-block service-block-${color}`}>
    <div
      className={`color-block d-flex flex-column text-${color} p-2 p-lg-3 h-100`}
    >
      <div className="position-relative">
        <img
          className="align-self-start icon icon-white"
          src={icon}
          alt={linkText}
        />
        <img
          className="align-self-start icon icon-color position-absolute top-0 left-0"
          src={iconHover}
          alt={linkText}
        />
      </div>

      <h3 className="title-font flex-grow-1">{title}</h3>
      {bodyText != null ? <p className="fw-bold">{bodyText}</p> : null}
      <Link className="stretched-link align-self-start" to={to}>
        {linkText} →
      </Link>
    </div>
  </div>
)
