// @flow
import * as React from 'react'
import { useContext } from 'react'
import { Helmet } from 'react-helmet'

import { chooseLang, navPath } from '../utils/language'
import { LanguageContext } from './Lang'
import { WebinarMessage } from './WebinarMessage'

import { menuRoutes, otherRoutes } from '../App'
import { useLocation } from 'react-router-dom'
import type { LangString } from '../App'

type Props = {
  title: LangString,
  description: LangString,
  children: React.Node,
  location: LangString,
}

export const Page = (props: Props): React.Element<'div'> => {
  const lang = useContext(LanguageContext)
  const location = useLocation()
  const { title = {}, description = {}, children } = props
  const webinarPage = location.pathname.endsWith('webinar')

  const url = (to: ?string, current: ?string) => {
    const page =
      current == null
        ? location.pathname
        : location.pathname.slice(1 + current.length)
    const siteRoute = [...menuRoutes, ...otherRoutes].find(
      (route) => page === navPath(route.path, current)
    )
    const destination = siteRoute || { path: props.location }
    const lang = to != null ? `/${to}` : ''
    const result = `${lang}${navPath(destination.path, to)}`
    return result
  }
  const titleText = chooseLang(title, lang)
  return (
    <div className="page fade-in">
      <Helmet title={titleText}>
        <html lang={lang || 'nl'} />
        {titleText ? (
          <meta name="title" property="og:title" content={titleText} />
        ) : null}
        <meta
          name="description"
          property="og:description"
          content={chooseLang(description, lang)}
        />
        <link rel="canonical" href={url(null, lang)} />
        <link rel="alternate" hreflang="nl" href={url(null, lang)} />
        <link rel="alternate" hreflang="en" href={url('en', lang)} />
      </Helmet>
      {!webinarPage ? <WebinarMessage /> : null}
      {children}
    </div>
  )
}
