// @flow
import * as React from 'react'
import { Hero, HeroTitle, HeroBody, Link, Page, Section, EN, NL } from '../../components'

type SectionHeaderProps = {
  title: { nl: string, en: string },
  sub: { nl: string, en: string },
  color: string,
}

const SectionHeader = ({ title: { nl, en }, sub: { nl: nlSub, en: enSub }, color }: SectionHeaderProps) => (
  <h2 className={`mb-3 text-${color}`}>
    <NL>{nl}</NL>
    <EN>{en}</EN>
    <div className="mt-2 small text-alt-grey">
      <NL>{nlSub}</NL>
      <EN>{enSub}</EN>
    </div>
  </h2>
)

const Selectical = (): React.Node => (
  <Page
    title={{ nl: 'Selectical Whitepaper', en: 'Selectical Whitepaper' }}
    description={{
      nl: 'Snel en effectieve literatuur reviewen met behulp van AI',
      en: 'Fast and effective literature reviews, powered by AI',
    }}
    location="/whitepapers/selectical"
  >
    <Hero>
      <HeroTitle>
        <NL>Selectical</NL>
        <EN>Selectical</EN>
      </HeroTitle>
      <HeroBody>
        <p>
          <NL>Een whitepaper over sneller en effectiever literatuur reviewen, met behulp van AI.</NL>
          <EN>A whitepaper on faster and more effective literature reviews, powered by AI.</EN>
        </p>
        <p>
          <Link to="#intro">
            <NL>Introductie</NL>
            <EN>Introduction</EN>
          </Link>
        </p>
        <p>
          <Link to="#challenge">
            <NL>De uitdaging</NL>
            <EN>The challenge</EN>
          </Link>
        </p>
        <p>
          <Link to="#solution">
            <NL>De oplossing: Selectical</NL>
            <EN>The solution: Selectical</EN>
          </Link>
        </p>
        <p>
          <Link to="#active-learning">
            <NL>Achtergrond: Active Learning</NL>
            <EN>Background: Active Learning</EN>
          </Link>
        </p>
        <p>
          <Link to="#performance">
            <NL>Performance</NL>
            <EN>Performance</EN>
          </Link>
        </p>
        <p>
          <Link to="#conclusion">
            <NL>Conclusie</NL>
            <EN>Conclusion</EN>
          </Link>
        </p>
      </HeroBody>
    </Hero>
    <Section size="small" gradient="teal-blue" className="mt-0" id="intro">
      <div className="text-container text-white">
        <h2>
          <NL>AI-geassisteerde Literatuurreviews</NL>
          <EN>AI-assisted Literature reviews</EN>
        </h2>
        <p>
          <NL>
            <p>
              Het werk van systematische reviewers in academische literatuurstudies is tijdrovend, duur en repetitief.
              Het is tot nu toe lastig gebleken om hierbij effectieve hulp van de computer te krijgen. We hebben een
              tool ontwikkeld, op basis van AI-technologie, die assisteert bij het selecteren van relevante teksten:
              Selectical.
            </p>
            <p>
              In dit whitepaper leggen we uit wat precies de uitdaging is van systematisch literatuuronderzoek in het
              medische domein en hoe Selectical hier een oplossing voor biedt we de problemen met automatisering hebben
              opgelost. Met Selectical kan 66% van het werk van reviewers bespaard worden, door gebruik te maken van
              nieuwe, realtime zelflerende AI-technologie. Het is hiermee mogelijk om in een fractie van de tijd alsnog
              meer dan 99% van relevante papers bij een studie te identificeren.
            </p>
          </NL>
          <EN>
            <p>
              Systematic literature reviews are time consuming, therefore relatively costly, and have aspects of
              repetitiveness. Despite several attempts by others, it has been difficult so far to develop effective
              computer assistance. We have developed a tool based on artificial intelligence technology that assists
              with selecting relevant titles and abstract: Selectical.
            </p>
            <p>
              This whitepaper explains the challenges faced during systematic literature review in the biomedical field,
              and the solutions Selectical provides. The tool Selectical reduces the workload of reviewers with 66% by
              using new, real time, self-learning AI-technology, and still identifies over 99% of relevant papers.
            </p>
          </EN>
        </p>
      </div>
    </Section>
    <Section size="small" id="challenge">
      <div className="text-container">
        <SectionHeader
          color="green"
          title={{
            nl: 'De uitdaging: veel werk en hoge eisen',
            en: 'The challenge: high workload and high quality',
          }}
          sub={{
            nl: 'De casus van literatuurrevies in het medische domein',
            en: 'The case of literature reviews in the medical domain',
          }}
        />
        <NL>
          <p>
            Het selecteren van wetenschappelijke artikelen die relevant zijn voor een specifieke studie is geen
            gemakkelijke opgave. Omdat in een literatuurreview alle relevante artikelen moeten worden gevonden moet elk
            afzonderlijk artikel (titel en abstract) goed bestudeerd worden. Dat kost per artikel gemiddeld enkele
            minuten. Voor een studie met duizenden potentiële papers kost dit meerdere werkdagen, mogelijk zelfs hele
            werkweken van hoogopgeleide onderzoekers.
          </p>
          <h4 className="fw-bold">Waarom gebeurt dit nog niet automatisch?</h4>
          <p>Er zijn goede redenen waarom dit soort reviews nog niet machinaal gebeuren:</p>
          <ul className="separated">
            <li>Er is weinig marge voor fouten. Meer dan 99% van de relevante papers moet worden geselecteerd.</li>
            <li>
              Natuurlijke tekst interpreteren is moeilijk voor computers. Keyword analyse is niet genoeg gebleken om
              alle relevante (en überhaupt voornamelijk relevante) papers terug te vinden.
            </li>
            <li>
              Bij elke nieuwe review zijn nieuwe selectiecriteria van toepassing. Een algoritme kan dus niet bouwen op
              kennis of resultaten van vroeger.
            </li>
            <li>
              In sommige gevallen zijn titel en abstract niet genoeg om een beoordeling te geven. Zo'n twijfelgeval moet
              wel worden meegenomen. Dit bemoeilijkt geautomatiseerde selectie.
            </li>
          </ul>
          <p>
            De inzet van Artificial Intelligence (AI) wordt door deze punten erg bemoeilijkt, o.a. omdat AI modellen
            vaak alleen effectief zijn als ze vooraf zijn getraind op vergelijkbare situaties. Het vereist een hele
            speciale soort AI-technologie om met de randvoorwaarden hierboven een werkend systeem te bouwen dat de
            menselijke taak van reviewen (deels) overneemt.
          </p>
        </NL>
        <EN>
          <p>
            The selection of relevant scientific articles in a specific literature review is relatively time consuming
            and needs the input of skilled researchers. It is important that the selected titles and abstracts{' '}
            <span className="fw-bold">include all articles that are eventually relevant for data-extraction</span>.
            Therefore each title/abstract requires scrutiny. Considering that literature reviews of thousands of
            titles/abstract are no exception, the workload is huge.
          </p>
          <h4 className="fw-bold">Why has this title/abstract selection task not been automated yet?</h4>
          <p>
            Automation of the title/abstract task seems a logical step, but machine-assisted selection yielding
            satisfactory results is hampered for several reasons:
          </p>
          <ul className="separated">
            <li>
              There is little room for false-negatives. It is important to include more than 99% of the papers that
              eventually qualify for data-extraction.
            </li>
            <li>
              It is difficult for computers to interpret natural text. Keyword analysis is not sufficient to find all
              relevant papers.
            </li>
            <li>
              Every new literature review project requires a new set of selection criteria. Thus, an algorithm cannot
              learn from previous tasks.
            </li>
            <li>
              Title and abstract may not be enough to assess whether a paper needs to be included. However, these
              'doubts' need to be selected by the computer, which complicates automated selection.
            </li>
          </ul>
          <p>
            Applying Artificial Intelligence (AI) is difficult with the above mentioned constraints, among other things
            because AI models will most of the time only be effective if they are trained in similar situations. It
            requires a special type of AI-technology to create a model that is able to replace (part of) the human work
            given those constraints.
          </p>
        </EN>
      </div>
    </Section>
    <hr />
    <Section size="small" id="solution">
      <div className="text-container">
        <h2 className="mb-3 text-teal">
          <NL>De oplossing: Selectical</NL>
          <EN>The solution: Selectical</EN>
          <div className="mt-2 small text-alt-grey">
            <NL>Hoe werkt het?</NL>
            <EN>How does it work?</EN>
          </div>
        </h2>
        <NL>
          <p>
            Selectical is een AI-gedreven tool die bij elke literatuurstudie automatisch leert wat relevante papers zijn
            op basis van wat de onderzoeker invoert. Na korte tijd neemt Selectical het grootste deel van het werk van
            het selecteren over. Zo wordt in 34% van de tijd meer dan 99% van alle relevante papers gevonden.
          </p>
          <p>Selectical werkt in elke browser en is eenvoudig in gebruik:</p>
          <ol className="separated">
            <li>
              Upload een set artikelen
              <ul>
                <li>Het systeem verwerkt de artikelen en bereidt het zelflerende proces voor.</li>
              </ul>
            </li>
            <li>
              Aan de slag: Selectical geeft de reviewer artikelen om te beoordelen of ze voor deze studie relevant zijn.
              De reviewer klikt simpelweg op een van de drie knoppen (of gebruikt de sneltoetsen). Daarna verschijnt
              direct een nieuw artikel in beeld.
              <ul>
                <li>Selectical leert direct van wat de reviewer doet – dit gebeurt volautomatisch.</li>
                <li>
                  Na een aantal beoordelingen (dit varieert per studie) is de reviewer klaar en heeft Selectical bepaald
                  wat alle relevante en niet-relevante artikelen zijn!
                </li>
              </ul>
            </li>
            <li>Exporteer en download de resultaten!</li>
          </ol>
          <h4 className="fw-bold">Toepassingsmogelijkheden</h4>
          <p>
            Er zijn verschillende toepassingsmogelijkheden van Selectical binnen het review proces. Als 'tweede
            reviewer' bij een dubbele selectie (standaard bij systematisch literatuuronderzoek), als 'controle tool' en
            als 'primaire selector'.
          </p>
          <ol className="separated">
            <li>
              Tweede review: na een 100% handmatige selectie door een eerste reviewer, kan Selectical dienen als tweede
              reviewer. Wat de tijdsinvestering van een tweede reviewer tot een derde reduceert. Bij het uploaden van de
              artikelen worden ook de resultaten van de eerste review meegegeven. Selectical ondersteunt dan de tweede
              review via het zelflerend proces en geeft na afloop de mogelijkheid om eventuele verschillen tussen de
              eerste en tweede review te vergelijken.
            </li>
            <li>
              Inzet als controle-tool: Het is ook mogelijk om Selectical in te zetten als controle van een eerdere
              review. Selectical leert van de eerder toegekende labels en identificeert artikelen waarbij de kans
              aanwezig is dat ze verkeerd gelabeld zijn. De reviewer hoeft vervolgens slechts deze kleine selectie
              artikelen te controleren. Na eventuele correcties kunnen de resultaten weer geëxporteerd worden.
            </li>
            <li>
              Uiteraard kan Selectical ook als primaire selector gebruikt worden. Als je de betrouwbaarheid van
              Selectical ervaren heeft in het gebruik als tweede reviewer, kun je Selectical meteen inzetten als hulp
              bij het selecteren van relevante literatuur.
            </li>
          </ol>
        </NL>
        <EN>
          <p>
            Selectical is an AI-driven tool that automatically learns at every literature review project what the
            relevant papers are, based on the researcher's selection input. After a short while Selectical has learned
            enough to do the rest of the title/abstract selection job. On average, Selectical finds in 34% of the manual
            selection time over 99% of all relevant papers.
          </p>
          <p>Selectical works in every browser and is easy to use:</p>

          <ol className="separated">
            <li>
              Upload a set of articles
              <ul>
                <li>The system processes the articles and prepares the self-learning process.</li>
              </ul>
            </li>
            <li>
              Get started: the tool presents articles (titles + abstracts) to the reviewer who will assess the relevance
              for the project. The reviewer clicks on the button for include, exclude or doubt. Afterwards a new article
              will be presented.
              <ul>
                <li>The tool learns directly and automatically from the reviewer's selections.</li>
                <li>
                  After a certain number of assessments by the reviewer, which varies by project, the reviewer is ready
                  and the tool has determined all relevant and non-relevant studies.
                </li>
              </ul>
            </li>
            <li>Export and download the results!</li>
          </ol>
          <h4 className="fw-bold">Applications</h4>
          <p>
            There are several applications of Selectical within the literature review process: as 'second reviewer' in
            case the title/abstract selection needs to be done in duplicate; as 'control tool'; and as 'primary
            selector'.
          </p>
          <ol className="separated">
            <li>
              Second review: after a 100% manual selection by a primary reviewer, Selectical can be used as the second
              reviewer. A 100% duplicate selection of title/abstracts is a requirement for systematic literature
              reviews. Selectical can reduce the time needed for the duplicate selection with one third through the
              self-learning process. The results of the selection by the primary reviewer can be stored in Selectical as
              well, and results of the first and second selection can easily be compared.
            </li>
            <li>
              Control-tool: it is also possible to use Selectical as a control-tool for the manual selection. Selectical
              learns from the labels (include, exclude, doubt) assigned during the manual selection and identifies
              articles with a probability of being labelled incorrectly. Only a small set of articles need to be
              reassessed and labels adapted if needed. The results can be exported after the corrections are made.
            </li>
            <li>
              Primary selector: Of course Selectical can also be used as sole, primary reviewer. If you have acquainted
              yourself with Selectical's capabilities by using it as a second reviewer, you could use it as primary and
              only review tool for your literature reviews as well.
            </li>
          </ol>
        </EN>
      </div>
    </Section>
    <hr />
    <Section size="small" id="how-it-works">
      <div className="text-container">
        <SectionHeader
          color="aqua"
          title={{
            nl: 'Selectical: onder de motorkap',
            en: 'Selectical: how it works',
          }}
          sub={{
            nl: 'Hoe en waarom werkt dit als automatisch systeem?',
            en: 'How and why does this automated tool work?',
          }}
        />
        <NL>
          <h4 className="fw-bold">Voorbereiding</h4>
          <p>
            De geüploade artikelen worden ingelezen door Selectical. De beschikbare informatie (titel, abstract,
            Pubmed/Embase velden) wordt verwerkt en geoptimaliseerd om het zelflerend algoritme snel en efficiënt mee te
            laten werken. Deze opstartstap kost wat tijd en rekenkracht, maar vereist geen enkele input van reviewers na
            de initiële upload. Wanneer dit is afgerond kan de reviewer aan de slag met het labelen van artikelen.
          </p>
          <h4 className="fw-bold">Real-time zelflerende AI</h4>
          <p>
            Zodra het menselijke werk van artikelen selecteren is begonnen, begint ook de AI met leren. Aan de hand van
            de handelingen van de reviewer, wordt de AI getraind om het onderscheid te kunnen maken tussen artikelen die
            'relevant', 'niet relevant' en als 'twijfelgeval moeten worden gelabeld. Dit proces noemen we Active
            Learning. (Zie het kader voor een uitleg in meer detail waarom dit werkt.)
          </p>
          <p>
            Uiteindelijk is de AI slim genoeg om van een groot aantal artikelen te bepalen of ze relevant zijn of niet,
            zonder dat de reviewer deze hoeft te bekijken.
          </p>
          <h4 className="fw-bold">Eindpunt bepalen</h4>
          <p>
            Als we niet alle artikelen laten bekijken door de menselijke reviewer, hoe weten we dan zeker dat we alle
            relevante artikelen terugvinden? Die vraag is een van de belangrijkste redenen die dit tot een moeilijk
            probleem maakt. Selectical gebruikt een innovatieve strategie om deze 'twijfel' over ongeziene artikelen in
            te schatten en te kwantificeren. Als er uiteindelijk geen 'meetbare twijfel' meer is over de resterende
            artikelen, dan is de selectie afgerond en is de reviewer klaar om een export van alle resultaten te maken.
          </p>
        </NL>
        <EN>
          <h4 className="fw-bold">Preparation</h4>
          <p>
            After uploading article information in Selectical, this information (title, abstract, additional info from
            databases such as PubMed and Embase) is processed and optimized by Selectical in order to set up efficient
            processing of the title/abstract selection. This initial step requires some, but limited time and computing
            power, and no time from the reviewer him/herself. After this set up, the reviewer can start labelling the
            titles/abstracts.
          </p>
          <h4 className="fw-bold">Real-time self-learning AI</h4>
          <p>
            When the reviewer starts selecting the articles in Selectical, the AI starts learning. The AI is trained by
            the selections made by the reviewer, to discriminate between 'relevant', 'non-relevant' and 'not sure'. This
            process is called Active Learning (see explanation box). Eventually the AI has learned which articles are
            relevant and which are not, without the interference of the reviewer.
          </p>
          <h4 className="fw-bold">When can the reviewer stop</h4>
          <p>
            If not all articles are labelled by the human reviewer, how will we know for sure that all relevant articles
            will be selected by the AI-tool? That is the challenge in developing a tool for the literature selection
            task. Selectical uses an innovative strategy to quantify the uncertainty about unseen articles. If no
            measurable uncertainty is left for the remaining, unseen articles, the human selection activities will stop.
            An export of the results for all articles can be made.
          </p>
        </EN>
      </div>
    </Section>
    <Section size="small" gradient="green-teal text-white" id="active-learning">
      <div className="text-container">
        <div className="subtitle">
          <NL>De techniek erachter</NL>
          <EN>The technique behind this</EN>
        </div>
        <h3 className="text-light-green">Active Learning</h3>
        <NL>
          <p>
            Bij active learning wordt een zelflerende Artificial Intelligence actief bijgestuurd door input van de
            gebruiker. De AI leert zogezegd door 'af te kijken bij de mens' hoe een taak uitgevoerd wordt.
          </p>
          <p>
            Dit kan omdat het AI-algoritme een notie van zekerheid heeft bij de keuzes die gemaakt worden. De AI kan dus
            'zeker' en 'onzeker' van z'n zaak zijn over een automatische beslissing.
          </p>
          <p>In dit geval moet de AI leren wat relevante artikelen zijn en wat niet. Het werkt als volgt:</p>
          <ol className="separated">
            <li>
              De AI zoekt het artikel waarbij de relevantie het 'meest relevant' is, en legt dit voor aan de gebruiker.
            </li>
            <li>De menselijke gebruiker labelt dit artikel [relevant] of [niet relevant].</li>
            <li>
              De AI gebruikt deze nieuwe informatie om bij te leren en past waar nodig beslissingen over relevantie (en
              de zekerheid daarbij) aan.
            </li>
            <li>Herhaal vanaf 1.</li>
          </ol>
          <p>
            Door dit een aantal keer te herhalen (hoe vaak hangt af van het probleem in kwestie), kan de AI uiteindelijk
            met hoge zekerheid het werk van de mens overnemen.
          </p>
        </NL>
        <EN>
          <p>
            Active Learning means that the self-learning Artificial Intelligence is actively adjusted by the input of
            the human user (reviewer in our case). The AI learns by 'cheating' from the human user how the task should
            be executed.
          </p>
          <p>
            This is possible because the AI-algorithm relates a level of certainty to the decisions made. In other
            words, the AI can be certain or uncertain about the automated decisions made.
          </p>
          <p>
            In the case of selection of titles/abstract the AI has to learn what is a relevant article and what isn't.
            To do so:
          </p>
          <ol className="separated">
            <li>
              The AI presents the user with the title/abstract that has the highest relevance and presents it to the
              user.
            </li>
            <li>The user labels the title/article as relevant or irrelevant.</li>
            <li>
              The AI uses this new piece of information to learn and adjusts, if necessary, its decisions regarding
              relevance and the level certainty attached.
            </li>
            <li>Repeat from 1.</li>
          </ol>
          <p>
            Eventually, the AI can execute the work of the human user with a high level of certainty, by repeating these
            steps several times. How often depends on the problem at hand.
          </p>
        </EN>
      </div>
    </Section>
    <Section size="small" id="performance">
      <div className="text-container">
        <SectionHeader
          color="blue"
          title={{
            nl: 'Selectical: Performance',
            en: 'Selectical: Performance',
          }}
          sub={{
            nl: 'Hoe goed presteert Selectical?',
            en: 'How well is Selectical doing its task?',
          }}
        />
        <NL>
          <p>We kunnen de resultaten van elke review waar Selectical assisteert beoordelen op twee criteria:</p>

          <ul className="separated">
            <li>
              <h4 className="mb-0 fw-bold">Bespaard werk</h4>
              Welk gedeelte van het totaal aantal artikelen hoefde de reviewer niet te lezen?
            </li>
            <li>
              <h4 className="mb-0 fw-bold">Kwaliteit</h4>
              Welk percentage relevante artikelen werden door de AI daadwerkelijk gevonden?
            </li>
          </ul>

          <p>
            Voor de beoordeling van het criterium 'Kwaliteit' is het nodig een test te draaien waarbij alle research
            papers wel al eens door een menselijke reviewer zijn beoordeeld. We kunnen vervolgens simuleren wat er zou
            zijn gebeurd als dit proces door Selectical werd geassisteerd, en de resultaten vergelijken.
          </p>
          <p>
            Om dit op een rigoureuze manier te testen is deze simulatie op verschillende datasets gedraaid met
            verschillende (willekeurige) initiële parameters. De resultaten zijn vervolgens gemiddeld. Het betroffen
            datasets van literatuur reviews met uiteenlopende onderwerpen (bijvoorbeeld een groot scala aan ziekten,
            maar ook over alcoholgebruik, of voeding en gezondheid ), sterk gefocust (bijvoorbeeld de effectiviteit van
            een bepaald vaccin) tot breed (het natuurlijk beloop van een ziekte), en datasets van verschillende omvang
            (100 tot ruim 7000 titels).
          </p>
          <p>
            Voor het testen zijn 36 literatuurreviews ieder 25 keer gesimuleerd. Bij elkaar tellen deze reviews 80
            duizend abstracts, waarvan tweeduizend 'relevant'.
          </p>
          <div className="row">
            <div className="col-lg-7">
              <table className="table table-borderless lead">
                <thead>
                  <tr>
                    <th className="subtitle small" scope="col">
                      Criterium
                    </th>
                    <th className="subtitle small" scope="col">
                      Resultaat
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Hoeveelheid bespaard werk</td>
                    <td className="fw-bold">66%</td>
                  </tr>
                  <tr>
                    <td>Kwaliteit</td>
                    <td className="fw-bold">99.3%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p className="small muted">
            Volledige testresultaten inzien? Mail ons op{' '}
            <a className="pretty-link" href="mailto:hallo@wearelandscape.nl">
              hallo@wearelandscape.nl
            </a>
            .
          </p>
          <h4 className="fw-bold mt-4">Opmerkingen over de resultaten</h4>
          <ul>
            <li>
              Bij kleine reviews, omvang minder dan 1000 titels/abstracts is het bespaarde werk gering. Maar in die
              gevallen biedt Selectical nog steeds een prettige interface om het selectiewerk te doen.
            </li>
            <li>
              Een deel van de artikelen die de AI niet juist beoordeelde in de simulaties bleken fouten in de originele
              dataset te zijn: deze artikelen waren onterecht geselecteerd door menselijke reviewers, maar werden door
              de AI (terecht) niet als relevant beoordeeld.
            </li>
            <li>Literatuuronderzoeken met specifiek geformuleerde doelstellingen geven een betere performance.</li>
            <li>
              Selectical geeft betere resultaten en is meer gebruikersvriendelijk dan andere literatuurselectie tools.
              Een aantal van deze tools zijn door ons getest. Abstrackr includeerde veel titels/abstract die
              uiteindelijk niet relevant waren. Dit levert dan in de volgende selectiestap (het volledige artikel
              beoordelen) veel extra werk op, waardoor het gebruik van Abstrackr uiteindelijk meer tijd kostte dan een
              volledig handmatige selectie. Andere tools (zoals Abstrackr, Rayyan, Bioreader, Colandr, StArt,
              RobotAnalyst) geven niet zelf een duidelijk stopmoment aan. Er wordt dan gewerkt met ratings/inschattingen
              hoe relevant een artikel zal zijn en de gebruiker moet bepalen wanneer te stoppen. Dit kan ertoe leiden
              dat de gebruiker doorgaat met selecteren om het zekere voor het onzekere te nemen en dan alsnog geen tijd
              bespaart. Ook bleken de inschattingen bij onze tests van deze tools vaak niet accuraat of onderscheidend
              genoeg (zoals Rayyan, StArt).
            </li>
          </ul>
        </NL>
        <EN>
          <p>We use two criteria to judge the results of Selectical's work:</p>

          <ul className="separated">
            <li>
              <h4 className="mb-0 fw-bold">Amount of work saved</h4>
              What part of the total amount of titles/abstracts to be reviewed, does not have to be reviewed by the
              human reviewer? In other words, how much human work is saved by Selectical?
            </li>
            <li>
              <h4 className="mb-0 fw-bold">Quality</h4>
              What percentage of relevant articles is found by Selectical?
            </li>
          </ul>

          <p>
            Selectical was challenged on these two criteria by testing it on literature review projects that had already
            been fully labelled by human reviewers. We simulated that Selectical would have been used in these projects
            and compared the results. We tested 36 literature review projects that were each 25 times simulated with
            Selectical. Each simulation round used different random initial parameters. We used literature review
            projects that varied in health/disease area (e.g. infectious diseases, chronic diseases, rare diseases,
            nutrition, alcohol use), focussed subjects (e.g. effectiveness of a certain vaccin) and wider subjects (e.g.
            the natural history of a disease), and with different sizes (100 to over 7000 titles/abstracts).
          </p>
          <p>
            The 36 literature review projects included 80 thousand titles/abstracts of which 2000 labelled by the human
            reviewers as relevant.
          </p>
          <p>The average results of these simulation with respect to efficiency and quality were:</p>
          <div className="row">
            <div className="col-lg-7">
              <table className="table lead">
                <thead>
                  <tr>
                    <th className="subtitle" scope="col">
                      Criterium
                    </th>
                    <th className="subtitle" scope="col">
                      Result
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Amount of work saved</td>
                    <td className="fw-bold">66%</td>
                  </tr>
                  <tr>
                    <td>Quality</td>
                    <td className="fw-bold">99.3%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p className="small muted">
            For the detailed results, please feel free to send us an email at{' '}
            <a className="pretty-link" href="mailto:hello@wearelandscape.nl">
              hello@wearelandscape.nl
            </a>
            .
          </p>
          <h4 className="fw-bold mt-4">Comments regarding the results</h4>
          <ul>
            <li>
              In smaller reviews with less than 1000 titles/abstracts to be screened the amount of work saved is little.
              However, in those cases Selectical still provides a nice interface to do and store the title/abstract
              selection work.
            </li>
            <li>
              Some of the articles the AI did not label similar to the human reviewer, could after scrutiny of the
              original, human results be labelled as human errors. The AI had correctly labelled them differently.
            </li>
            <li>Literature review projects with focussed review objectives lead to a better performance of the AI.</li>
            <li>
              Selectical comes up with better results and is more user friendly as compared to other title/abstract
              selection tools. We tested several of these tools. Abstrackr did include many titles/abstracts that were
              eventually not relevant for data-extraction. This led to extra work in the next stage of article
              selection, namely when the article is assessed in full text. In the end, using Abstrackr increased the
              time needed for article selection as compared to full selection by a human reviewer. A drawback of several
              other tools (such as Abstrackr, Rayyan, Bioreader, Colandr, StArt, RobotAnalyst) is that they do not
              present a clear stop for the human reviewer. Instead, the human reviewer gets feedback about the potential
              relevance of an article (e.g. by a rating/measure of uncertainty), and it is the reviewer's decision
              whether to stop or not.. However, this might trigger the human reviewer to proceed with manual selection
              'just to be sure', which makes using a tool less efficient. In addition, some of the before mentioned
              tools (Rayyan, StArt) performed poorly with respect to their estimate of relevance of articles; the
              feedback received lacked, for example, accuracy or discriminative power.
            </li>
          </ul>
        </EN>
      </div>
    </Section>
    <hr />
    <Section size="small" id="conclusion">
      <div className="text-container">
        <h2 className="text-orange">
          <NL>Conclusie</NL>
          <EN>In Conclusion</EN>
        </h2>
        <NL>
          <p>
            Het automatisch selecteren van research papers voor studies was lang een onneembare horde voor Artificial
            Intelligence. Maar Selectical kan met real-time zelflerende AI meer dan 99% van alle relevante artikelen
            terugvinden terwijl de werklast van de reviewer met 66% afneemt.
          </p>
          <p>
            Uit simulaties en gebruikerservaringen blijkt dat Selectical voor zeer uiteenlopende soorten studies een
            bijzonder nuttige bijdrage kan leveren. En beter presteert dan bestaande tools met een vergelijkbaar doel.
          </p>
          <p>
            Benieuwd naar de resultaten van Selectical op uw eigen literatuur reviews? We kunnen de werking van
            Selectical ook op jouw literatuurreviews toetsen door simulaties te draaien.
          </p>
        </NL>
        <EN>
          <p>
            High quality, time-saving automated selection of research papers has long been a challenge for Artificial
            Intelligence. However, with real-time self-learning AI, Selectical returns more than 99% of the relevant
            articles saving 66% of the reviewer's time.
          </p>
          <p>
            Simulations and the experience of users show that Selectical can assist in literature review projects with a
            wide range of review objectives. In addition, Selectical shows better performance than comparable tools.
          </p>
          <p>
            If you are curious and would like to know the performance of Selectical on your type of literature reviews,
            we offer to do some test simulations on recent projects.
          </p>
        </EN>
      </div>
    </Section>
  </Page>
)
export default Selectical
