// @flow
import * as React from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
//import LandscapeWorkshop from '../assets/landscape-workshop-teal.jpg'
//import LandscapeDesk from '../assets/landscape-working-environment.jpg'
//import Kussens from '../assets/Kussens_orange.png'
import Vergaderzaal from '../assets/Vergaderzaal_bloem_teal.png'
import DeskWatch from '../assets/Desk_watch_orange.png'

import {
  Button,
  CallButton,
  EN,
  Hero,
  HeroTitle,
  HeroBody,
  NL,
  Page,
  Section,
  WallpaperSection,
  BackToTop,
  Link,
} from '../components'

const Contact = (): React.Node => {
  useEffect(() => {
    return () => {
      const scriptToRemove = document.getElementById('calendly-external-js')
      if (scriptToRemove != null) scriptToRemove.remove()
    }
  }, [])
  return (
    <Page
      title={{
        nl: 'Contact',
        en: 'Contact Us',
      }}
      description={{
        nl: 'Ons adres is Bargelaan 26 in Leiden – Laten we jouw organisatie meer laten doen met data en AI. Heb je een project in gedachten, of wil je gewoon meer weten? Laten we praten!',
        en: "We're at Bargelaan 26 - Let's move your organisation from idea to impact. How will AI and Machine Learning techniques impact your business? Not sure where to start? Let's talk!",
      }}
      location="/contact"
    >
      <Hero>
        <HeroTitle>
          <NL>Zo bereik je ons</NL>
          <EN>How to reach us</EN>
        </HeroTitle>
        <HeroBody>
          <p>
            <NL>
              Als je een idee hebt voor een project, als je meer wilt weten over onze werkwijze, als je hulp nodig hebt
              met iets, maar ook als je gewoon eens koffie of thee (of een biertje) wilt drinken en eens praten over
              data en Artificial Intelligence: <span className="strong text-green">neem vooral contact met ons op</span>
              !
            </NL>
            <EN>
              If you have an idea for a project, want to know more about our workshops, need help with something, or
              just want some coffee and talk about data and Artificial Intelligence:{' '}
              <span className="strong text-green">feel free to reach out</span>!
            </EN>
          </p>
          <Button href="mailto:hello@wearelandscape.nl" className="me-3" color="green" size="lg">
            <NL>Stuur een e-mail</NL>
            <EN>Email us</EN>
          </Button>
          <CallButton color="teal" size="lg" className="me-3" />
          <Button className="me-3" color="aqua" size="lg" to="#plan">
            <NL>Plan direct wat in</NL>
            <EN>Plan a meeting directly</EN>
          </Button>
          <Button className="me-3" color="blue" size="lg" to="#visit">
            <NL>Kom langs!</NL>
            <EN>Come by!</EN>
          </Button>
        </HeroBody>
      </Hero>

      {/* <WallpaperSection
        size="small"
        className="p-0 my-0"
        style={{ minHeight: '1rem' }}
      >
        <div style={{ height: '32vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyC5-K8wBD2xz7PM4mvp3NJBAQc2Fz1tIrU',
            }}
            defaultCenter={ourLocation}
            defaultZoom={15}
            onDrag={() => {}}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          />
        </div>
      </WallpaperSection> */}

      <WallpaperSection
        size="small"
        wallpaper={DeskWatch}
        wallpaperStyle={{
          filter: 'brightness(80%) blur(1px)',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        style={{ minHeight: '400px' }}
        id="plan"
      >
        <div className="text-container text-white">
          <div className="subtitle">
            <NL>Prik een moment via Calendly</NL>
            <EN>Pick a moment via Calendly</EN>
          </div>
          <h2 className="h1">
            <NL>Tijdswinst begint hier!</NL>
            <EN>Time saving starts here!</EN>
          </h2>
        </div>
      </WallpaperSection>

      <Section size="small" className="container px-0">
        <div className="text-container">
          <p>
            <NL>
              Ja, we stimuleren graag persoonlijk contact. Maar als we zeggen dat we je tijd kunnen helpen besparen,
              kunnen we daar nu al mee beginnen, toch? :)
            </NL>
            <EN>
              Yes, we really advocate for more personal contact. But if we say that we can help you save time, we might
              as well start here, right? :)
            </EN>
          </p>
          <p>
            <NL>Kies maar een moment dat jou ook uitkomt!</NL>
            <EN>Just choose a moment that also suits you!</EN>
          </p>
        </div>
        {/* <!-- Calendly inline widget begin --> */}
        <div
          className="calendly-inline-widget d-lg-block"
          data-url="https://calendly.com/landscape-data-science/kansen-voor-data-en-ai?hide_gdpr_banner=1"
          style={{ minWidth: '320px', height: '700px', width: '100%' }}
        />
        <Helmet>
          <script
            id="calendly-external-js"
            type="text/javascript"
            src="https://assets.calendly.com/assets/external/widget.js"
          ></script>
        </Helmet>
        {/* <!-- Calendly inline widget end --> */}
        <div className="text-container">
          <p>
            <NL>
              Zie je geen widget? Ga handmatig naar{' '}
              <Link
                className="text-orange"
                to="https://calendly.com/landscape-data-science/kansen-voor-data-en-ai?hide_gdpr_banner=1"
              >
                Calendly
              </Link>
              .
            </NL>
            <EN>
              Don't see a widget? Go to{' '}
              <Link
                className="text-orange"
                to="https://calendly.com/landscape-data-science/kansen-voor-data-en-ai?hide_gdpr_banner=1"
              >
                Calendly
              </Link>{' '}
              manually.
            </EN>
          </p>
        </div>
      </Section>

      <WallpaperSection
        size="small"
        wallpaper={Vergaderzaal}
        wallpaperStyle={{
          filter: 'brightness(80%) blur(1px)',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        style={{ minHeight: '400px' }}
        id="visit"
      >
        <div className="text-container text-white">
          <div className="subtitle">
            <NL>Zo vind je ons</NL>
            <EN>How to find us</EN>
          </div>
          <h2 className="h1">
            <NL>Leuk dat je langskomt!</NL>
            <EN>Cool that you're coming by!</EN>
          </h2>
        </div>
      </WallpaperSection>

      <Section className="my-0">
        <div className="container p-md-5">
          <div className="row g-md-5">
            <div className="col-md-6 mb-5">
              <h2 className="text-green">
                <NL>Extra inspiratie voordat je langskomt</NL>
                <EN>Extra inspiration before you come by</EN>
              </h2>
              <p>
                <NL>
                  Je hebt niet voor niets al een afspraak ingepland, maar mocht je je geheugen willen opfrissen en je
                  inspiratie weer een kick geven: lees hier nog een keertje wat we doen en deden!
                </NL>
                <EN>
                  You didn't plan a meeting for nothing, but if you'd like to refresh your memory and give your
                  inspiration a new kick: check out what we do and did once more!
                </EN>
              </p>
              <Button color="green" to={{ nl: '/diensten', en: '/services' }} className="me-3" size="lg">
                <NL>Wat we doen</NL>
                <EN>What we do</EN>
              </Button>
              <Button color="green" size="lg" to="/portfolio" outline>
                <NL>Wat we deden</NL>
                <EN>What we've done</EN>
              </Button>
            </div>
            <div className="col-md-6 mb-5">
              <h2 className="text-blue">
                <NL>Ons kantoor</NL>
                <EN>Our office</EN>
              </h2>
              <p>
                <NL>Landscape is gevestigd op 1 minuut lopen van Leiden Centraal.</NL>
                <EN>Landscape is located at only 1 minute from Leiden Central Station.</EN>
              </p>
              <address className="text-blue my-3 ps-4 border-start border-blue">
                Landscape
                <br />
                Bargelaan 26 <NL>(3e verdieping)</NL>
                <EN>(3rd floor)</EN>
                <br />
                2333 CT Leiden
              </address>
              <h2 className="title-font mt-5 text-blue">
                <NL>Route</NL>
                <EN>Getting there</EN>
              </h2>
              <h4 className="text-dark-blue fw-bold">
                <NL>Met het OV</NL>
                <EN>By train</EN>
              </h4>
              <p>
                <NL>
                  We zijn heel gemakkelijk te bereiken door de trein of bus te nemen naar{' '}
                  <span className="strong text-dark-blue">Leiden Centraal</span>
                  . Vanaf daar is het 1 minuutje lopen:
                  <br />
                  Ga het station uit aan de Noordzijde/LUMC-kant. Sla rechtsaf bij koffiezaak Lebkov. Ons kantoor is op
                  nr. 26, net na de Multicopy.
                </NL>
                <EN>
                  The fastest way to reach us is by train to{' '}
                  <span className="strong text-dark-blue">Leiden Centraal</span>
                  station. From there it's a 1 minute walk. Exit the station at the north side / LUMC side. Walk past
                  coffeeshop Lebkov (at your right) and go to the right. Our entrance is at number 26, right after
                  Multicopy.
                </EN>
              </p>
              <h4 className="text-dark-blue fw-bold">
                <NL>Met de auto</NL>
                <EN>By car</EN>
              </h4>
              <p>
                <NL>
                  Je kunt je route-navigatie instellen op de parkeergarage in{' '}
                  <span className="strong text-dark-blue">Level</span>, op Bargelaan 180 in Leiden (€&nbsp;1,80 / uur).
                  Vanaf daar is het 1 minuut lopen: ga langs het station en de Lebkov. Onze ingang zit voorbij (links
                  van) de Multicopy. Daar zit onze deur, bij nummer 26.
                </NL>
                <EN>
                  Navigate to car park <span className="strong text-dark-blue">Level</span> at Bargelaan 180, Leiden,
                  where you can park your car for € 1,80 per hour. From Level, it is only a 1 minute walk. Walk past the
                  train station, Lebkov and Multicopy. Our entrance is to the left of the Multicopy at number 26.
                </EN>
              </p>
              <Button
                color="blue"
                href="https://www.google.com/maps/place/Landscape+%7C+Creating+value+with+AI/@52.1672761,4.4798007,17z/data=!3m1!4b1!4m5!3m4!1s0x47c5c6f1efc53e6b:0xb11fdc0329f80651!8m2!3d52.1673006!4d4.4819464"
              >
                <NL>Plan je reis</NL>
                <EN>Plan your journey</EN>
              </Button>
            </div>
            <BackToTop />
          </div>
        </div>
      </Section>
    </Page>
  )
}
export default Contact
