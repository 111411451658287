// @flow
import * as React from 'react'
import {
  Button,
  ClientLogo,
  EN,
  Hero,
  HeroTitle,
  HeroBody,
  NL,
  Link,
  ProjectPanel,
  Section,
  BackToTop,
} from '../../components'
import AOGThumb from '../../assets/projects/aog-thumbnail.jpg'

export const AOGShort = (): React.Node => (
  <ProjectPanel
    title={
      <>
        <NL>Bewaar alleen de data die je nodig hebt</NL>
        <EN>Only save the data you really need</EN>
      </>
    }
    photo={AOGThumb}
    subtitle={
      <>
        <NL>Industrie | Data Management</NL>
        <EN>Industry | Data Management</EN>
      </>
    }
  >
    <p>
      <NL>
        Airborne Oil & Gas, fabrikant van pijpleidingen, wilde sensoren en camera's in hun fabriek gaan inzetten om data
        over hun productieproces te verzamelen. Deze data kan gebruikt worden om een AI verschillende taken uit te laten
        voeren en om een <strong>Digital Twin</strong> te bouwen: een virtuele kopie van het fabrieksproces.
      </NL>
      <EN>
        Airborne Oil & Gas, a pipeline manufacturer, wanted to equip their factory line with sensors and cameras, to
        gather data about their production process. This data can be used to model a <strong>digital twin</strong>, a
        virtual copy of the factory, and to train AI models to do various tasks.
      </EN>
    </p>
    <p>
      <NL>
        Maar voor zoiets kon gebeuren moesten ze eerst <strong>grote hoeveelheden data opslaan</strong> die door deze
        sensoren worden gegenereerd. Ze vroegen ons om vast te stellen hoeveel opslagcapaciteit er nodig zal zijn, welke
        data dan precies opgeslagen moet worden, en wat daarvoor de optimale methode is.
      </NL>
      <EN>
        But before that could happen, they first needed to <strong>store vast amounts of data</strong> that these
        sensors would generate. They asked us to determine the needs for storage, what data to acquire and optimize the
        amount of data stored.
      </EN>
    </p>
    <Button outline to={{ nl: '/portfolio/aog', en: '/portfolio/aog' }} color="white">
      <NL>Lees er alles over</NL>
      <EN>Read all about it</EN>
    </Button>
    <BackToTop />
  </ProjectPanel>
)

export const AOG = (): React.Element<'div'> => (
  <div className="page">
    <Hero>
      <HeroTitle>
        <NL>Strategisch data verzamelen</NL>
        <EN>Strategy to gather data</EN>
      </HeroTitle>
      <HeroBody>
        <p>
          <Link to="https://airborneoilandgas.com/">Airborne Oil &amp; Gas</Link>{' '}
          <NL>
            (AOG) is 's werelds eerste fabrikant van pijpleidingen gemaakt van composieten voor de olie-industrie. Het
            is hun ambitie om hun productieproces grotendeels geautomatiseerd te laten verlopen. Landscape beoordeelde
            hun bestaande IT ecosysteem en hielp een heldere data-strategie te ontwikkelen.
          </NL>
          <EN>
            (AOG) is the world's first and leading manufacturer of composite pipes for the oil and gas industry. They
            had the ambition to largely automate the production environment. Landscape assessed the current IT ecosystem
            and helped developing a clear data strategy.
          </EN>
        </p>
        <ClientLogo name="Airborne Oil & Gas" />
      </HeroBody>
    </Hero>
    <Section size="small" gradient="aqua-blue">
      <div className="text-container text-white">
        <p>
          <NL>
            Omdat AOG recent een zelfstandig bedrijf was geworden na een afsplitsing van het moeder-concern, ontstond de
            noodzaak voor een eigen IT-omgeving, inclusief ERP-software om de business te ondersteunen en de
            gegevensverzameling vereist om het productieproces te optimaliseren.
          </NL>
          <EN>
            Having recently split-off from its parent company, AOG was working on setting up an independent IT
            environment. This included ERP software to support the primary business, and collecting all data necessary
            to optimise the production environment.
          </EN>
        </p>
        <p>
          <NL>
            Wij werden gevraagd om precies uit te zoeken waar de nieuwe eisen aan zo'n systeem verschilden van de oude.
            Daarbij had AOG toen al de ambitie om de productie zoveel mogelijk te automatiseren, wat natuurlijk een
            extra set aan (data-)requirements oplegde.
          </NL>
          <EN>
            While it was obvious that a new system with new requirements was needed, and we were asked to investigate
            exactly where the new needs differed from the old. In addition, AOG had (and still has) the ambition to
            largely automate their production plant, which of course came with its own set of (data) requirements.
          </EN>
        </p>
      </div>
    </Section>
    <Section size="small">
      <div className="text-container">
        <h2>
          <NL>Beoordeling van IT-vereisten</NL>
          <EN>Assessment of IT requirements</EN>
        </h2>
        <p>
          <NL>
            Landscape beoordeelde de benodigdheden voor AOG's primaire bedrijfsvoering. Dit bestond uit stakeholders
            interviewen, kritisch diens IT-behoeften doorlichten de requirements van de vereiste processen en
            databronnen uittekenen, en waar mogelijk adviseren over verbeteringen. Dit overzicht van het IT-landschap en
            de relatie tot de business bleek een zeer effectief middel om beslissingen op te baseren.
          </NL>
          <EN>
            Landscape assessed the application needs for AOG's primary business processes. This involved interviewing
            business stakeholders, critically assessing the stakeholders' IT requirements, mapping the requirements for
            these processes to IT applications and data sources, and advising on improvements. Such an overview of the
            organisation's IT requirements and their connection to business processes proved to be a very effective tool
            for communication and decision making.
          </EN>
        </p>
        <p>
          <NL>
            Op basis van onze analyse en aanbevelingen heeft AOG haar eigen IT-afdeling opgezet en succesvol haar nieuwe
            software kunnen aankopen.
          </NL>
          <EN>
            With our analysis and recommendations, AOG has set up its own IT department and procured new software.
          </EN>
        </p>
      </div>
    </Section>
    <hr />
    <Section size="small">
      <div className="text-container">
        <h2 className="text-green">
          <NL>De data assets begrijpen</NL>
          <EN>Understanding data assets</EN>
        </h2>
        <p>
          <NL>
            Vervolgens ging Landscape aan de slag met de vraag welke data te verzamelen om de productiehal te kunnen
            automatiseren. Om een AI te leren dit te doen zijn vele voorbeelden nodig van hoe omgegaan moet worden met
            verschillende situaties. Een deel van deze data was al voorhanden, een deel niet.
          </NL>
          <EN>
            Next, Landscape worked on the issue of what data to collect in order to largely automate the production
            hall. In order for an AI to be able to do this process on its own, it needs to be trained on a large dataset
            of examples of how to deal with certain scenarios. Some of this data was already available, some of it was
            not.
          </EN>
        </p>
        <p>
          <NL>
            Landscape beoordeelde de primaire databronnen – bestaande en toekomstige – in de productie, waaronder
            temperatuursensoren en videocamera's. Voor elke databron stelde we vast hoeveel data er verzameld zou gaan
            worden – beduidend meer dan je ooit economisch verantwoord op zou willen slaan. Op basis van hoeveel en
            welke data nodig is om een AI te trainen konden we vaststellen wat er precies opgeslagen en bewaard moest
            worden.
          </NL>
          <EN>
            Landscape assessed the primary - existing and future - data assets in the production hall, including
            temperature sensors and video cameras. For each, we estimated how much data was going to be collected, which
            was more than was ever possible to store. We could determine how much of this data should be stored, based
            on the requirements of training an AI to complete the required tasks.
          </EN>
        </p>
        <p>
          <NL>
            Onze analyse en aanbevelingen vormden de basis voor verschillende projecten van AOG waarin ze hun
            data-gebruik hebben verbeterd en efficiënter hebben gemaakt.
          </NL>
          <EN>
            Landscape's analysis and recommendations have formed the basis for several programs to improve data
            practices and to use data more effectively.
          </EN>
        </p>
      </div>
    </Section>
  </div>
)
