// @flow
import * as React from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { navPath } from './utils/language'

import { ScrollToTop, Analytics, LanguageContext, withAnalytics } from './components'

// Navigation
import Navbar from './navigation/Navbar'
import Footer from './navigation/Footer'

// Main pages
import Home from './views/Home'
// import AIStrategy from './views/AIStrategy'
// import AISolutions from './views/AISolutions'
import Services from './views/Services'
import Portfolio from './views/Projects/'
import PortfolioZorg from './views/Projects/PortfolioZorg'
import PortfolioIndustrie from './views/Projects/PortfolioIndustrie'
import PortfolioProducten from './views/Projects/PortfolioProducten'
import AboutUs from './views/AboutUs'
// import Team from './views/Team'
// import Workshops from './views/Workshops/'
// import Careers from './views/Careers/'
import Insights from './views/Insights/'
import Contact from './views/Contact'

// Careers
// import AM from './views/Careers/MediorAccountManager'

// Additional Content
import CookiePolicy from './views/CookiePolicy'
import NoMatch from './views/NoMatch'

// Unlisted – Should be added to package.json as an include for react-snapshot
import Selectical from './views/whitepapers/Selectical'
import Webinar from './views/Webinar'
import DistrictHeating from './views/DistrictHeating'

import type { ContextRouter } from 'react-router-dom'

export type LangString = string | { [lang: string]: string }
export type NavRoute = {
  title: LangString,
  path: LangString,
  component: any,
  exact?: true,
}

export const menuRoutes: NavRoute[] = [
  {
    title: 'Home',
    path: '/',
    component: Home,
    exact: true,
  },
  {
    title: { en: 'About us', nl: 'Over ons' },
    path: { en: '/about-us', nl: '/over-ons' },
    component: AboutUs,
  },
  {
    title: { nl: 'Diensten', en: 'Services' },
    path: { nl: '/diensten', en: '/services' },
    component: Services,
  },
  // {
  //   title: { en: 'AI Strategy', nl: 'AI Strategie' },
  //   path: { en: '/ai-strategy', nl: '/ai-strategie' },
  //   component: AIStrategy,
  // },
  // {
  //   title: { en: 'AI Solutions', nl: 'AI Oplossingen' },
  //   path: { en: '/ai-solutions', nl: '/ai-oplossingen' },
  //   component: AISolutions,
  // },
  {
    title: { en: 'Portfolio', nl: 'Portfolio' },
    path: {
      en: '/portfolio',
      nl: '/portfolio',
    },
    component: Portfolio,
  },
  // {
  //   title: 'Team',
  //   path: '/team',
  //   component: Team,
  // },
  // {
  //   title: { en: 'Work at', nl: 'Werken bij' },
  //   path: { en: '/work-at-landscape', nl: '/werken-bij-landscape' },
  //   component: Careers,
  // },
  {
    title: 'Insights',
    path: '/insights',
    component: Insights,
  },
  {
    title: 'Contact',
    path: '/contact',
    component: Contact,
  },
]
export const otherRoutes: NavRoute[] = [
  // {
  //   title: 'Workshops',
  //   path: '/workshops',
  //   component: Workshops,
  // },
  {
    title: 'Cookie Consent',
    path: '/cookie-policy',
    component: CookiePolicy,
  },
  {
    title: 'Webinar',
    path: '/webinar',
    component: Webinar,
  },
  {
    title: 'Selectical Whitepaper',
    path: '/whitepapers/selectical',
    component: Selectical,
  },
  {
    title: { en: 'Portfolio Healthcare', nl: 'Portfolio Zorg' },
    path: { en: '/portfolio-healthcare', nl: '/portfolio-zorg' },
    component: PortfolioZorg,
  },
  {
    title: { en: 'Portfolio Industry', nl: 'Portfolio Industrie' },
    path: { en: '/portfolio-industry', nl: '/portfolio-industrie' },
    component: PortfolioIndustrie,
  },
  {
    title: {
      en: 'Portfolio Maatwerk-AI-Producten',
      nl: 'Portfolio Custom AI Products',
    },
    path: { nl: '/portfolio-ai-producten', en: '/portfolio-ai-products' },
    component: PortfolioProducten,
  },
  {
    title: 'Transforming District Heating through AI Solutions',
    path: '/district-heating',
    component: DistrictHeating,
  },
  // {
  //   title: 'Vacature Medior+ Account Manager AI',
  //   path: {
  //     nl: '/werken-bij-landscape/medior-account-manager-ai',
  //     en: '/work-at-landscape/medior-account-manager-ai',
  //   },
  //   component: AM,
  // },
]

type Props = {}
type State = { ready: boolean }

const TAGLINE = 'Landscape - Creating value with AI'

class App extends React.Component<Props, State> {
  state: State = { ready: false }

  // eslint-disable-next-line no-unused-vars
  languageRoutes(language: ?string, props: ContextRouter): React.Node {
    const languagePath = language != null ? `/${language}` : ''
    return (
      <LanguageContext.Provider value={language}>
        <Analytics setReady={() => this.setState({ ready: true })} />
        <Helmet htmlAttributes={{ lang: language }} defaultTitle={TAGLINE} titleTemplate={`%s | ${TAGLINE}`}>
          <meta name="title" property="og:title" content={TAGLINE} />
        </Helmet>
        <ScrollToTop>
          <div className="App" key={String(this.state.ready)}>
            <Navbar routes={menuRoutes} otherRoutes={otherRoutes} />
            <Switch>
              {[...menuRoutes, ...otherRoutes].map((route, i) => (
                <Route
                  key={i}
                  component={withAnalytics(route.component)}
                  path={`${languagePath}${navPath(route.path, language)}`}
                  exact={route.exact}
                />
              ))}
              <Route component={withAnalytics(NoMatch)} />
            </Switch>
            <Footer />
          </div>
        </ScrollToTop>
      </LanguageContext.Provider>
    )
  }

  render(): React.Node {
    return (
      <Router>
        <Switch>
          {[...menuRoutes, ...otherRoutes].map((route, i) => (
            <Redirect
              from={`/nl${navPath(route.path, 'nl')}`}
              to={`${navPath(route.path, 'nl')}`}
              key={`redirect-${i}`}
              exact
            />
          ))}
          {/* <Redirect from={'/workshops/big-data-workshop'} to={'/workshops'} />
          <Redirect
            from={'/workshops/data-solutions-workshop'}
            to={'/workshops'}
          /> */}
          <Route path="/en" render={(props) => this.languageRoutes('en', props)} />
          <Route render={(props) => this.languageRoutes(undefined, props)} />
        </Switch>
      </Router>
    )
  }
}

export default App
