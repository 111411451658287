// @flow
import * as React from 'react'
import {
  // Button,
  // ClientLogo,
  EN,
  // Hero,
  // HeroTitle,
  // HeroBody,
  NL,
  Link,
  ProjectPanel,
  // Section,
  BackToTop,
} from '../../components'
import EKuSIMThumb from '../../assets/projects/ekusim-thumbnail.png'

export const EKuSIMshort = (): React.Node => (
  <ProjectPanel
    title={
      <>
        <NL>Een AI een energiecentrale leren aansturen</NL>
        <EN>Teaching an AI to run a power plant</EN>
      </>
    }
    photo={EKuSIMThumb}
    subtitle={
      <>
        <NL>Industrie | Procesoptimalisatie</NL>
        <EN>Industry | Operations Optimisation</EN>
      </>
    }
  >
    <p>
      <NL>
        Voor iedereen geldt: hoe vaker of langer je iets doet, hoe beter je erin wordt. Je maakt verschillende situaties
        en contexten mee, weet die vervolgens vrij snel te herkennen en leert hoe je daarop moet reageren.{' '}
      </NL>
      <EN>
        The same goes for everyone: the longer or more often you do something, the better you get at it. You experience
        different situations and contexts, and subsequently know how to act on these.{' '}
      </EN>
    </p>
    <p>
      <NL>
        Mensen kunnen dit vrij snel – met minder voorbeelden leren. Maar{' '}
        <strong>patronen herkennen zijn computers weer beter in</strong>. Zeker als het om veel variabelen gaat.
      </NL>
      <EN>
        People are quite quick, learning these things with only a few examples. But{' '}
        <strong>computers are definitely better at recognizing patterns</strong>. Especially if it concerns many
        variables.
      </EN>
    </p>
    <p>
      <NL>
        Voor het besturen van een energiecentrale geldt dit net zo. We weten inmiddels goed wat er hoe moet gebeuren,
        maar <strong>kunnen we misschien nóg beter de energie beheren?</strong> Kunnen we nóg eerder aan zien komen
        wanneer er iets fout gaat? Kunnen we onze operatie duurzamer doen? En kan een AI dit ontdekken?
      </NL>
      <EN>
        For operating a power plant the same is true. We know quite well what needs to happen when, but{' '}
        <strong>could we become even better at managing energy?</strong> Could we detect and defer potential anomalies
        sooner? Could we make our operations more sustainable? And could an AI discover this?
      </EN>
    </p>
    <h3>
      <NL>
        Met <Link to="https://en.wikipedia.org/wiki/Digital_twin">Digital Twins</Link> en{' '}
        <Link to="https://en.wikipedia.org/wiki/Reinforcement_learning">Reinforcement Learning</Link>
      </NL>
      <EN>
        With <Link to="https://en.wikipedia.org/wiki/Digital_twin">Digital Twins</Link> and{' '}
        <Link to="https://en.wikipedia.org/wiki/Reinforcement_learning">Reinforcement Learning</Link>
      </EN>
    </h3>
    <p>
      <NL>Bingo! Hypewoordstempelkaart vol.</NL>
      <EN>Bingo! Hype word collection complete.</EN>
    </p>
    <p>
      <NL>
        Maar het ís wel zo. Samen met <Link to="https://www.ekusim.de/en/">EKu.SIM</Link> – die Digital Twins ontwikkelt
        voor kritieke infrastructuren – zijn we aan het uitzoeken hoe we door een AI 'vrij' te trainen met vele
        simulaties nóg beter kunnen worden in het besturen van energiecentrales. Kleine verbeteringen kunnen in deze
        branche namelijk al grote verschillen maken!
      </NL>
      <EN>
        But it ís true. Together with <Link to="https://www.ekusim.de/en/">EKu.SIM</Link> – who develops Digital Twins
        for critical infrastructures – we are researching how we can become better at operating power plants by training
        an AI 'freely' on many simulations. Since in this sector little improvements can already make a big difference!
      </EN>
    </p>
    {/* <Button
      outline
      to={{ nl: '/portfolio/aog', en: '/portfolio/aog' }}
      color="white"
    >
      <NL>Lees er alles over</NL>
      <EN>Read all about it</EN>
    </Button> */}
    <BackToTop />
  </ProjectPanel>
)
