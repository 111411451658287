// @flow
import * as React from 'react'
import { useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { NavHashLink as NavLink } from 'react-router-hash-link'
import { NavbarToggler, Collapse } from 'reactstrap'
import { useCookies } from 'react-cookie'

import { navPath } from '../utils/language'
import { LanguageContext } from '../components'
import { WEBINAR_COOKIE, ACTIVE_WEBINAR } from '../components'

import './Navbar.scss'

import type { NavRoute } from '../App'

type Props = {
  routes: NavRoute[],
  otherRoutes: NavRoute[],
}

const WebinarLink = ({ langBase, onClick }: { langBase: string, onClick: () => void}) => {
  const [cookies] = useCookies([WEBINAR_COOKIE])
  const showWebinar = cookies[WEBINAR_COOKIE] === 'closed' && ACTIVE_WEBINAR
  return (
    <li
      key="webinar"
      className="nav-item"
      style={{
        maxWidth: showWebinar ? '100px' : 0,
        transition: 'all .6s',
        overflow: 'hidden',
      }}
    >
      {showWebinar ? (
        <NavLink
          to={`${langBase}/webinar`}
          className="nav-link"
          onClick={onClick}
        >
          <span className="text-blue">Webinar</span>
        </NavLink>
      ) : null}
    </li>
  )
}

const NavbarContent = (props: Props): React.Node => {
  const context = useContext(LanguageContext)
  const location = useLocation()
  const [isOpen, setisOpen] = useState<boolean>(false)

  const toggle = () => setisOpen(!isOpen)
  const close = () => setisOpen(false)

  useEffect(() => {
    setisOpen(false)
  }, [location])

  const changeLanguage = (to: ?string) => {
    const current = context
    const page =
      current == null
        ? location.pathname
        : location.pathname.slice(1 + current.length)
        const siteRoute = [...props.routes, ...props.otherRoutes].find(
          (route) => page === navPath(route.path, current)
        )
        const pathFromLocation = {path: location.pathname.slice(current ? current.length +1 : 0)}
        const destination = siteRoute || pathFromLocation
    const lang = to != null ? `/${to}` : ''
    const result = `${lang}${navPath(destination.path, to)}${location.hash}`
    return result
  }

  const langBase = context != null ? `/${context}` : ''
  return (
    <div className="container">
      <NavbarToggler onClick={() => toggle()} className="px-0">
        <span
          className={`material-icons fs-1 ${
            isOpen ? 'opacity-0' : 'opacity-100'
          }`}
        >
          menu
        </span>
        <span
          className={`material-icons fs-1 position-absolute ${
            isOpen ? 'opacity-100' : 'opacity-0'
          }`}
        >
          close
        </span>
      </NavbarToggler>
      <NavLink
        to={`${langBase}/`}
        className="navbar-brand"
        activeClassName="active"
      ></NavLink>

      <Collapse isOpen={isOpen} navbar>
        <ul className="navbar-nav ms-auto align-items-baseline mb-4 mb-lg-0">
          {props.routes.map((route, idx) => {
            const title =
              typeof route.title === 'object'
                ? route.title[context || 'nl']
                : route.title
            const className = idx === 0 ? 'nav-item d-lg-none' : 'nav-item'
            return (
              <li className={className} key={idx}>
                <NavLink
                  exact={idx === 0}
                  to={`${langBase}${navPath(route.path, context)}`}
                  className="nav-link"
                  onClick={() => close()}
                >
                  <span>{title}</span>
                </NavLink>
              </li>
            )
          })}
          <WebinarLink langBase={langBase} onClick={() => close()} />
        </ul>
        {/* <hr className="d-lg-none w-50" /> */}
        <ul className="navbar-nav align-items-baseline ms-lg-3 mb-4 mb-lg-0">
          <li className={'ms-lg-2 nav-item'}>
            <NavLink
              className={'nav-link language-link'}
              to={changeLanguage()}
              isActive={() => context == null}
            >
              NL
            </NavLink>
          </li>
          <li className={'nav-item'}>
            <NavLink
              className="nav-link language-link"
              to={changeLanguage('en')}
            >
              EN
            </NavLink>
          </li>
        </ul>
      </Collapse>
    </div>
  )
}

export const Navbar = (props: Props): React.Node => (
  <header className="Navbar sticky-top">
    <nav className="navbar navbar-expand-lg navbar-light">
      <NavbarContent {...props} />
    </nav>
    <nav className="navbar navbar-expand-lg navbar-dark">
      <NavbarContent {...props} />
    </nav>
    <div className="navbar-gradient-bottom" />
  </header>
)

export default Navbar
