// @flow
import * as React from 'react'
import { ServiceLink, NL, EN } from '../components'

const ServiceLinks = (): React.Node => (
  <>
    <ServiceLink
      to={{ nl: '/diensten#ontdek', en: '/services#ontdek' }}
      icon="lightbulb"
      title={
        <>
          <NL>Ontdek</NL>
          <EN>Discover</EN>
        </>
      }
      color="aqua"
      tagline={
        <div style={{ marginTop: '-5px' }}>
          {/* <div
            className="badge bg-aqua text-white rounded-pill px-2 pt-1 mb-1"
            style={{ fontSize: '0.8rem' }}
          >
            <NL>AI nieuw voor jou? Begin hier!</NL>
            <EN>New to AI? Start here!</EN>
          </div> */}
          <div>
            <NL>
              <span className="text-aqua strong">Benieuwd wat AI (voor jou) kan betekenen?</span>
              <br></br>
              Ontdek wat mogelijk is met (jouw) data en AI
            </NL>
            <EN>
              <span className="text-aqua strong">Wondering what AI can do (for you)?</span>
              <br></br>
              Discover what is possible with (your) data and AI
            </EN>
          </div>
        </div>
      }
    />
    <ServiceLink
      to={{ nl: '/diensten#onderzoek', en: '/services#onderzoek' }}
      icon="search"
      title={
        <>
          <NL>Onderzoek & Ontwerp</NL>
          <EN>Determine & Design</EN>
        </>
      }
      color="teal"
      tagline={
        <>
          <NL>
            <span className="text-teal strong">Heb je een idee, maar nog geen concreet plan?</span>
            <br></br>
            Onderzoek en ontwerp jouw data- en AI-aanpak
          </NL>
          <EN>
            <span className="text-teal strong">Have an idea, but no plan yet?</span>
            <br></br>
            Determine and design your data and AI approach
          </EN>
        </>
      }
    />
    <ServiceLink
      to={{ nl: '/diensten#ontwikkel', en: '/services#ontwikkel' }}
      icon="local_pizza"
      title={
        <>
          <NL>Ontwikkel</NL>
          <EN>Develop</EN>
        </>
      }
      color="green"
      tagline={
        <>
          <NL>
            <span className="text-green strong">Heb je een plan? Let's code!</span>
            <br></br>
            Ontwikkel waardevolle data- en AI-oplossingen
          </NL>
          <EN>
            <span className="text-green strong">Have a plan? Let's code!</span>
            <br></br>
            Develop valuable data and AI solutions
          </EN>
        </>
      }
    />
    {/*<ServiceLink
      to="/services#ai-solutions"
      icon="fingerprint"
      title={
        <>
          <NL>AI-Oplossingen</NL>
          <EN>AI Solutions</EN>
        </>
      }
      color="orange"
      tagline={
        <>
          <NL>Ontketen de kracht van Machine Learning voor je bedrijf</NL>
          <EN>Unleash the power of Machine Learning on your business</EN>
        </>
      }
    />*/}
  </>
)

export default ServiceLinks
