// @flow
import * as React from 'react'
import { Hero, HeroTitle, HeroBody, Section, WallpaperSection, Button, Page, NL, EN } from '../../components'

import Traffic from '../../assets/traffic.jpg'
// import { CHDRShort as CHDR } from './CHDR'
import { PallasShort as Pallas } from './Pallas'
// import { DHDShort as DHD } from './DHD'
// import { ArtisShort as Artis } from './Artis'
// import { WhoozShort as Whooz } from './Whooz'
import { AOGShort as AOG } from './AOG'
import { RAGShort as RAG } from './RAG'
import { EKuSIMshort as EKuSIM } from './EKuSIM'
// import { PseuduckShort as Pseuduck } from './Pseuduck'

const PortfolioIndustrie = (): React.Node => (
  <Page
    title={{ nl: 'Portfolio Industrie', en: 'Portfolio Industry' }}
    description={{
      nl: 'We helpen industriële organisaties data-gedreven oplossingen te realiseren met AI en Machine Learning.',
      en: 'We help industrial organisations move from ideas to data-driven results, with AI and Machine Learning.',
    }}
    location={{ nl: '/portfolio-industrie', en: '/portfolio-industry' }}
  >
    <Hero>
      <HeroTitle>
        <NL>
          Ons eerdere werk <br /> <span className="text-aqua">in de industrie</span>
        </NL>
        <EN>
          Our previous work <br /> <span className="text-aqua">in the industry</span>
        </EN>
      </HeroTitle>
      <HeroBody>
        <NL>
          <p>
            Door de jaren heen hebben wij gewerkt met veel verschillende typen organisaties. De overeenkomst? De wens om{' '}
            <span className="strong text-aqua">beter en slimmer</span> hun werk te kunnen doen.{' '}
            <span className="strong text-aqua">Met data, machine learning en AI.</span>
          </p>
          <p>
            Lees hier hoe wij organisaties in de <span className="strong text-aqua">industrie</span> hielpen!
          </p>
        </NL>
        <EN>
          <p>
            Over the years we have worked with quite a variety of organisations. What they had in common? The wish to do
            their <span className="strong text-aqua">work better and smarter</span>.{' '}
            <span className="strong text-aqua">With data, machine learning and AI.</span>
          </p>
          <p>
            Read below how we helped organisations in the <span className="strong text-aqua">industry</span>!
          </p>
        </EN>
        <Button color="aqua" to="#intro" className="me-3">
          <NL>Lees verder!</NL>
          <EN>Read on!</EN>
        </Button>
        <Button color="aqua" outline to={{ nl: '/portfolio', en: '/portfolio' }} className="me-3">
          <NL>Terug naar alle projecten</NL>
          <EN>Back to all projects</EN>
        </Button>
      </HeroBody>
    </Hero>

    <WallpaperSection size="small" wallpaper={Traffic} id="all">
      <div className="text-container text-white">
        <h1>
          <NL>
            Operatoren en experts <span className="text-aqua">ondersteunen met AI</span>
          </NL>
          <EN>
            <span className="text-aqua">"Supporting </span> operators and experts{' '}
            <span className="text-aqua">with AI"</span>
          </EN>
        </h1>
      </div>
    </WallpaperSection>
    {/* <WallpaperSection size="small" wallpaper={Traffic} id="all">
      <div className="text-container text-white">
        <h3>
          <NL>
            Laat onze case studies je inspireren voor jullie volgende stap met
            data en AI.{' '}
            <span className="text-light-green">
              Wil je direct jouw eigen casus bespreken?
            </span>
          </NL>
          <EN>
            Let our previous work inspire you to take your next step with data
            and AI.{' '}
            <span className="text-light-green">
              Want to start tackling your own case now?
            </span>
          </EN>
        </h3>
        <div className="text-end mt-3">
          <Button color="white" outline to="/contact">
            <NL>Neem contact op!</NL>
            <EN>Contact us!</EN>
          </Button>
        </div>
      </div>
    </WallpaperSection> */}

    <Section size="small" id="intro">
      <div className="text-container">
        <div className="subtitle">
          <NL>AI in de Industrie</NL>
          <EN>AI in Industry</EN>
        </div>
        <h2 className="text-aqua mb-3">
          <NL>Processen optimaliseren en verduurzamen</NL>
          <EN>Optimising processes and stimulating sustainability</EN>
        </h2>
        <p>
          <NL>
            Als het gaat om industrie en AI, denkt men al snel aan robots. Maar dat is zeker niet de enige manier waarop
            je AI in kunt zetten in de industrie. Het kan ook ingezet worden om (natuurlijke) processen beter te
            begrijpen of juist te optimaliseren.
          </NL>
          <EN>
            When thinking about industry and AI, people usually picture robots. But that is definitely not the only form
            in which AI can be found there. You can also leverage it to better understand (natural) processes or
            optimize these.
          </EN>
        </p>
        <p>
          <NL>
            Zo kan AI je bijvoorbeeld helpen optimaler je stroom of water te gebruiken, zodat je niet alleen maar{' '}
            <span className="text-aqua strong">gemakkelijker</span> en{' '}
            <span className="text-aqua strong">goedkoper</span>, maar ook{' '}
            <span className="text-aqua strong">duurzamer</span> kunt opereren.
          </NL>
          <EN>
            AI could for example help you better utilize your energy or water, so you can not only operate{' '}
            <span className="text-aqua strong">more easily</span> and{' '}
            <span className="text-aqua strong">economically</span>, but also more
            <span className="text-aqua strong">sustainably</span>.
          </EN>
        </p>
      </div>
      <div className="text-container mt-5">
        <h4 className="strong">
          <NL>Lees bijvoorbeeld meer over:</NL>
          <EN>Read more about for example:</EN>
        </h4>
        <Button className="me-3" color="aqua" to="#ekusim">
          <NL>AI in energiemanagement</NL>
          <EN>AI in energy management</EN>
        </Button>
        <Button className="me-3" color="aqua" to="#rag">
          <NL>Efficiënter pompen</NL>
          <EN>Pumping efficiently</EN>
        </Button>
        <Button className="me-3" color="aqua" to="#aog">
          <NL>Met big data omgaan</NL>
          <EN>Managing big data</EN>
        </Button>
      </div>
    </Section>

    <Section size="small" className="text-white" gradient="teal-blue" id="ekusim">
      <div className="text-container">
        <EKuSIM />
      </div>
    </Section>

    <Section size="small" className="text-container" id="rag">
      <RAG />
    </Section>

    <Section size="small" className="text-white" gradient="teal-blue" id="aog">
      <div className="text-container">
        <AOG />
      </div>
    </Section>

    <Section size="small" className="text-container" id="selectical">
      <Pallas />
    </Section>
  </Page>
)
export default PortfolioIndustrie
