// @flow
import * as React from 'react'
import { Link } from './'

import './ServiceLink.scss'

import type { LangString } from '../App'

type Props = {
  ...React.ElementConfig<typeof Link>,
  className?: string,
  to: LangString,
  icon: string,
  tagline: string | React.Node,
  title: React.Node,
  color: ?string,
}

export const ServiceLink = (props: Props): React.Node => {
  const {
    className = '',
    to,
    color,
    icon,
    tagline,
    title,
    ...restProps
  } = props
  const outerClassName = [
    className,
    'row service-link py-3 position-relative',
  ].join(' ')

  return (
    <Link
      className={outerClassName}
      to={to}
      smooth
      {...restProps}
      pretty={false}
    >
      <div className="col-auto pe-0">
        <i
          className={`material-icons-outlined service-link-icon text-${
            color != null ? color : ''
          }`}
        >
          {icon}
        </i>
      </div>
      <div className="col">
        <div className="row align-items-center">
          <div className="col-lg-4 col-sm-5">
            <h2 className="service-link-title mb-1 mb-sm-0">{title}</h2>
          </div>
          <div className="col-lg-8 col-sm-7 service-link-tagline lead">
            {tagline}
          </div>
        </div>
      </div>
      <div className="col-auto service-link-arrow ps-0">
        <i
          className={`material-icons text-${color != null ? color : ''}`}
          style={{ fontSize: '36px' }}
        >
          keyboard_arrow_right
        </i>
      </div>
      {false && (
        <div
          className={`bg-${color != null ? color : ''}`}
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            top: 0,
            width: '5px',
          }}
        />
      )}
    </Link>
  )
}
