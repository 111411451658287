// @flow
import * as React from 'react'
import { EN, NL, InsightsPage, ReadMore } from '../../../components'
import Swier from '../../../assets/team/swier.jpg'
import Photo from '../../../assets/insights/safeguarding-data-privacy.png'
import Thumbnail from '../../../assets/insights/interview-swier.jpg'

import type { StoryMetadata } from '../index'

export const metadata: StoryMetadata = {
  slug: 'safeguarding-data-privacy-in-ai-the-role-of-pseudonymization',
  title: 'Safeguarding Data Privacy in AI: The Role of Pseudonymization',
  description:
    "Understanding and safeguarding privacy is essential in the complex 'black box' of artificial intelligence (AI) and machine learning (ML). Swier Heeres, a data scientist at Landscape with nearly a decade of experience, sheds light on this critical topic, particularly emphasizing pseudonymization as a key strategy for maintaining privacy in AI applications",
  date: '2024-01-25',
  published: false,
  thumbnail: Thumbnail,
}

export default function Story(): React.Node {
  return (
    <InsightsPage
      metadata={metadata}
      hero={{
        date: metadata.date,
        subtitle: (
          <>
            <NL>Een interview met Swier Heeres</NL>
            <EN>An interview with Swier Heeres</EN>
          </>
        ),
        title: (
          <span className="text-blue">
            <span className="text-dark-blue">Safeguarding Data Privacy&nbsp;in&nbsp;AI:</span>
            <br />
            The Role of Pseudonymization
          </span>
        ),
        description:
          "Understanding and safeguarding privacy is essential in the complex 'black box' of artificial intelligence (AI) and machine learning (ML). Swier Heeres, a data scientist at Landscape with nearly a decade of experience, sheds light on this critical topic, particularly emphasizing pseudonymization as a key strategy for maintaining privacy in AI applications.",
      }}
      quote={{
        quote:
          'Pseudonymization is vital in AI because it improves the performance of AI models and safeguards privacy simultaneously.',
        name: 'Swier Heeres',
        img: Swier,
        subtitle: (
          <>
            <NL>Van onze data scientist Swier</NL>
            <EN>From our data scientist Swier</EN>
          </>
        ),
      }}
      image={{
        src: Photo,
        alt: '"Data Privacy" by DALL-E',
      }}
    >
      <h3 className="mt-5 mb-3 text-dark-green">Importance of privacy in AI</h3>
      <p>
        Privacy is a critical focus in AI and machine learning. They process vast amounts of sensitive data, raising
        privacy concerns. GDPR requires robust data handling. AI risks replicating biases from their training data,
        including private information. Ensuring privacy involves careful design, pseudonymization, and ethical
        principles. Prioritizing privacy is crucial for legal compliance and responsible AI development.
      </p>

      <h3 className="mt-5 mb-3 text-dark-green">Understanding Pseudonymization in AI</h3>
      <p>
        According to Swier, pseudonymization is similar to anonymization because both aim to remove identifiable
        information from data. However, complete anonymization is only sometimes possible, particularly in fields such
        as healthcare. Pseudonymization involves removing direct identifiers, but the data can still be linked to
        individuals under certain circumstances. Often, when people talk about anonymizing data, it only consists of
        removing names, email addresses, etc. But that doesn't mean linking the data back to individuals has become
        impossible. The data has been pseudonymized: it's challenging to connect to individuals, but not impossible. The
        data should still be treated as sensitive.
      </p>
      <h3 className="mt-5 mb-3 text-dark-green">AI's Role in Protecting Privacy</h3>
      <p>
        Teaching AI models to focus on non-sensitive data is a challenge. Achieving this requires a deep understanding
        of the data and the context in which it's used. For example, removing personal identifiers and names is
        essential before using the data to train AI models when dealing with medical records. This step is crucial for
        protecting individuals' privacy and ensuring the model's accuracy and effectiveness.
      </p>

      <h3 className="mt-5 mb-3 text-dark-green">Real-World Applications and Challenges</h3>
      <p>
        Swier shares insights from a recent project involving the coding of medical records. "The goal was to reduce
        administrative burdens in hospitals. We had to ensure the AI model didn't learn to associate specific operations
        with doctors' names. If you don't remove all names from the data, you run the risk that the model learns to
        associate specific names with specific operations. Instead, we needed the model to understand the nature of the
        operations themselves," he details.
      </p>
      <p>
        This project underlines the significance of pseudonymization, which serves the dual purpose of improving the
        performance of AI models and safeguarding patients' privacy. Ensuring that the data is pseudonymized, the
        integrity of the model is maintained, and the privacy of individuals whose data is being utilized is respected.
      </p>
      <ReadMore to={{ nl: '/portfolio-zorg#dhd', en: '/portfolio-healthcare#dhd' }}>
        <NL>Veilig medische dossiers classificeren</NL>
        <EN>Safely classifying medical case reports</EN>
      </ReadMore>

      <h3 className="mt-5 mb-3 text-dark-green">The Future of AI and Privacy</h3>
      <p>
        Looking ahead, Swier anticipates significant developments in AI, especially concerning large language models
        like ChatGPT. In the future, Swier expects AI to undergo substantial advancements, particularly in large
        language models such as GPT. He explains: “People or organizations can use these powerful tools without your
        best interests. Take voice cloning, for example, which is used to scam people out of their money. Keeping your
        information safe prevents it from being used against you. That's why privacy and data protection are becoming
        increasingly important.”While these models will have a central role, they will also pose new challenges in terms
        of privacy. As AI becomes more capable of processing and generating text, the need for strong privacy measures,
        including advanced pseudonymization techniques, will become increasingly important.
      </p>

      <h3 className="mt-5 mb-3 text-dark-green">Landscape's Approach to AI and Privacy</h3>
      <p>
        At Landscape, the approach to AI and privacy should not just focus on creating models. However, it should also
        take into account the underlying issues. Therefore, Landscape insists on close collaboration and transparent
        problem-solving with the customer, leading to the most effective outcomes. By delving deeper into the problem,
        we can provide more tailored and privacy-conscious AI solutions. Swier explains: "We strive to understand and
        address the root cause of the problem to ensure that our AI solutions are both effective and secure."
      </p>
      <ReadMore to={{ nl: '/over-ons#values', en: '/about-us#values' }}>
        <NL>Onze kernwaarden</NL>
        <EN>Our core values</EN>
      </ReadMore>

      <h3 className="mt-5 mb-3 text-dark-green">Conclusion</h3>
      <p>
        Swier Heeres' expertise highlights the importance of pseudonymization in AI, balancing the power of machine
        learning with the imperative of privacy protection. As AI continues to advance, the role of data scientists like
        Swier in finding the right solutions becomes ever more critical. Landscape is dedicated to comprehending our
        clients' requirements and providing top-notch AI solutions prioritizing their privacy.
      </p>
    </InsightsPage>
  )
}
