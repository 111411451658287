// @flow
import * as React from 'react'
import {
  Hero,
  HeroTitle,
  HeroBody,
  Section,
  WallpaperSection,
  Button,
  Page,
  NL,
  EN,
  // Link,
} from '../../components'

import Traffic from '../../assets/traffic.jpg'
import { PallasShort as Pallas } from './Pallas'
import { PseuduckShort as Pseuduck } from './Pseuduck'
import ReviewPhoto from '../../assets/projects/systematic-review-thumbnail.jpg'
import PseuduckThumb from '../../assets/projects/pseuduck.png'

const PortfolioProducten = (): React.Node => (
  <Page
    title={{
      nl: 'Portfolio Maatwerk-AI-Producten',
      en: 'Portfolio Custom AI Products',
    }}
    description={{
      nl: 'We helpen onder andere zorgorganisaties data-gedreven oplossingen te realiseren met AI, om werkdruk te verlichten en wachttijden te verkorten',
      en: 'We help healthcare organisations move from ideas to data-driven results with AI and Machine Learning, to lighten the workload and shorten waiting lines.',
    }}
    location={{ nl: '/portfolio-ai-producten', en: '/portfolio-ai-products' }}
  >
    <Hero>
      <HeroTitle>
        <NL>
          Ons eerdere werk <br /> <span className="text-teal">Maatwerk-AI-producten</span>
        </NL>
        <EN>
          Our previous work <br /> <span className="text-teal">Custom AI products</span>
        </EN>
      </HeroTitle>
      <HeroBody>
        <NL>
          <p>
            Door de jaren heen hebben wij gewerkt met veel verschillende typen organisaties. De overeenkomst? De wens om{' '}
            <span className="strong text-teal">beter en slimmer</span> hun werk te kunnen doen.{' '}
            <span className="strong text-teal">Met data, machine learning en AI.</span>
          </p>
          <p>
            In deze tijd hebben we ook <span className="strong text-teal">AI-producten</span> of beter gezegd{' '}
            <span className="strong text-teal">AI-bouwstenen</span> ontwikkeld, die we kunnen{' '}
            <span className="strong text-teal">personaliseren</span> naar jouw wensen.{' '}
          </p>
          <p className="strong text-teal">Profiteer jij hier ook van?</p>
        </NL>
        <EN>
          <p>
            Over the years we have worked with quite a variety of organisations. What they had in common? The wish to do
            their <span className="strong text-teal">work better and smarter</span>.{' '}
            <span className="strong text-teal">With data, machine learning and AI.</span>
          </p>
          <p>
            In this time we have also developed <span className="strong text-teal">AI products</span> or better yet{' '}
            <span className="strong text-teal">AI building blocks</span> that we can{' '}
            <span className="strong text-teal">personalise</span> according to your wishes.
          </p>
          <p className="strong text-teal">Will you profit from this as well?</p>
        </EN>
        <Button
          color="teal"
          to={{
            nl: '#intro',
            en: '#intro',
          }}
          className="me-3"
        >
          <NL>Lees verder!</NL>
          <EN>Read on!</EN>
        </Button>
        <Button color="teal" outline to={{ nl: '/portfolio', en: '/portfolio' }} className="me-3">
          <NL>Terug naar alle projecten</NL>
          <EN>Back to all projects</EN>
        </Button>
      </HeroBody>
    </Hero>

    <WallpaperSection size="small" wallpaper={Traffic}>
      <div className="text-container text-white">
        <h1>
          <NL>
            "Wij hebben <span className="text-teal">AI-bouwstenen</span> waar jij tijd mee kunt besparen."
          </NL>
          <EN>
            "We have <span className="text-teal">AI building blocks</span> that can help you save time."
          </EN>
        </h1>
      </div>
    </WallpaperSection>

    <Section size="small" id="intro">
      <div className="text-container">
        <div className="subtitle">
          <NL>Laat AI & ML jou ondersteunen</NL>
          <EN>Let AI & ML support you</EN>
        </div>
        <h2 className="text-teal mb-3">
          <NL>Tijdswinst bij onderzoek en data delen</NL>
          <EN>Saving time in research and sharing data</EN>
        </h2>
        <p>
          <NL>
            Het fijne van goed-geschreven code (en goede IP-afspraken) is dat je deze kan hergebruiken. En het mooie van
            machinelearning-modellen (en zo ook taalmodellen) is dat je deze kunt bijtrainen in verschillende specifieke
            vakgebieden en in verschillende talen.
          </NL>
          <EN>
            The upside of well-written code (and good IP agreements) is that you can reuse it. And the nice thing about
            machine learning (and as such language models) is that they can be trained further to specialise in specific
            subject areas and in different languages.
          </EN>
        </p>
        <h3 className="text-teal">
          <NL>Met onze personaliseerbare AI-producten</NL>
          <EN>With our customisable AI products</EN>
        </h3>
        <p>
          <NL>
            Dit kunnen we doen met kleinere en grotere stukken code en machinelearning- en taalmodellen. De grootste
            hiervan zijn onze 'AI-producten' <span className="text-teal strong">Selectical</span> en{' '}
            <span className="text-teal strong">Pseuduck</span> die we kunnen personaliseren om jou te kunnen
            ondersteunen in je werk.{' '}
          </NL>
          <EN>
            We can do this with smaller and bigger pieces of code and machine learning models. The biggest of which are
            our 'AI products' <span className="text-teal strong">Selectical</span> and{' '}
            <span className="text-teal strong">Pseuduck</span> which we can personalise to support you in your work.
          </EN>
        </p>
        <Button color="teal" to="#pseudIntro" className="mb-5">
          <NL>Scroll direct naar producten</NL>
          <EN>Scroll to products directly</EN>
        </Button>
        <h3 className="text-teal">
          <NL>Op basis van (ook Nederlandse) NLP</NL>
          <EN>Based on (multilingual) NLP</EN>
        </h3>
        <p className="bg-transparent-teal p-4 my-3">
          <NL>
            NLP, ofwel{' '}
            <a
              className="text-teal "
              target="_blank"
              href="https://en.wikipedia.org/wiki/Natural_language_processing"
              rel="noreferrer"
            >
              Natural Language Processing
            </a>
            , betreft het gebruiken taalkunde, data science, artificial intelligence en machine learning om een computer
            natuurlijke taal te kunnen laten 'begrijpen'. (Denk aan ChatGPT.) Hiermee kan je bijvoorbeeld gemakkelijker
            door teksten heen zoeken, deze samenvatten, deze anonimiseren, deze beoordelen, vragen stellen, etc.
          </NL>
          <EN>
            NLP, or{' '}
            <a
              className="text-teal "
              target="_blank"
              href="https://en.wikipedia.org/wiki/Natural_language_processing"
              rel="noreferrer"
            >
              Natural Language Processing
            </a>
            , concerns the use of linguistics, data science, artificial intelligence and machine learning to let a
            computer 'understand' natural language. (Think of ChatGPT.) With NLP you can e.g. make it easier to search
            through texts, summarize them, anonymise them, judge them, ask questions, etc.{' '}
          </EN>
        </p>
        <p>
          <NL>
            De meeste taalmodellen zijn gebaseerd op het Engels, terwijl we in Nederland nog veel organisaties hebben
            die volledig in het Nederlands opereren. Daartoe werken we ook aan{' '}
            <span className="text-teal strong">Nederlandse NLP</span>.
          </NL>
          <EN>
            Most language models are based on English, while in other countries – like in the Netherlands
            – organisations often operate completely in their own language. That is why we also work with{' '}
            <span className="text-teal strong">Dutch NLP and other languages</span>.{' '}
          </EN>
        </p>
      </div>

      <div className="row justify-content-center" id="pseudIntro">
        <div className="bg-transparent-blue p-5 text-container col m-4">
          <img src={PseuduckThumb} alt="Pseuduck" className="rounded-circle img-fluid" style={{ maxWidth: '190px' }} />
          <h3 className="text-blue mt-4">
            <NL>Pseuduck: onze anonimisatie-tool</NL>
            <EN>Pseuduck: our anonymisation tool</EN>
          </h3>
          <p>
            <NL>
              Eigenlijk is Pseuduck een pseudonimisatie-tool, maar als je kunt pseudonimiseren, kan je ook anonimiseren.
              Het komt namelijk vooral neer op het herkennen van verschillende entiteiten zoals namen, steden,
              telefoonnummers, adressen, geslacht, banen, etc. Dit doen we met{' '}
              <a
                className="text-blue"
                target="_blank"
                href="https://en.wikipedia.org/wiki/Named-entity_recognition"
                rel="noreferrer"
              >
                Named Entity Recognition (NER)
              </a>
              .
            </NL>
            <EN>
              Well, actually Pseuduck is an pseudonymisation tool, but if you can pseudonymise you can also anonymise.
              It all comes down to recognizing different entities like names, cities, phone numbers, addresses, gender,
              professions, etc. This we do with{' '}
              <a
                className="text-blue"
                target="_blank"
                href="https://en.wikipedia.org/wiki/Named-entity_recognition"
                rel="noreferrer"
              >
                Named Entity Recognition (NER)
              </a>
              .
            </EN>
          </p>
          <p>
            <NL>
              Hiermee kan je herkennen of er überhaupt <span className="strong text-blue">persoonsgegevens</span> staan
              in jouw teksten of documenten en deze naar wens maskeren, hernoemen of verwijderen. Ideaal voor als je
              data wilt delen met klanten, collega's, partners, voor onderzoek, etc.
            </NL>
            <EN>
              With it you can recognise whether there is <span className="strong text-blue">personal data</span> in your
              texts and documents and mask, rename or delete them according to your wishes. Ideal for when you want to
              share data with customers, colleagues, partners, for research, etc.{' '}
            </EN>
          </p>
          <Button
            className="me-3"
            color="blue"
            to={{
              nl: '#pseuduck',
              en: '#pseuduck',
            }}
          >
            <NL>Meer over Pseuduck</NL>
            <EN>More on Pseuduck</EN>
          </Button>
        </div>

        {/* <hr className="my-5"></hr> */}
        <div className="bg-transparent-green p-5 text-container col m-4">
          <div className="px-3 mb-3">
            <img
              src={ReviewPhoto}
              alt="Selectical"
              className="rounded-circle img-fluid"
              style={{ maxWidth: '190px' }}
            />
          </div>
          <h3 className="text-green mt-4">
            <NL>Selectical: onze literatuurselectietool</NL>
            <EN>Selectical: our literature selection tool</EN>
          </h3>
          <p>
            <NL>
              Het vinden en/of selecteren van relevante documenten, teksten of nieuwe publicaties voor jouw onderzoek
              (of dit nu due dilligence of systematisch literatuuronderzoek is), kan best wel een werk zijn.
            </NL>
            <EN>
              Finding and/or selecting relevant documents, texts or new publications for your research (whether this
              concerns due dilligence or systematic literature research), it can be quite a task.
            </EN>
          </p>
          <p>
            <NL>
              Als je dit vaak moet doen, heb je vast wel eens gedacht "had ik hier maar een{' '}
              <span className="strong text-green">personal assistent</span> voor".
            </NL>
            <EN>
              If you have to do this often, you might have thought at some point "wouldn't it be nice to have a{' '}
              <span className="strong text-green">personal assistent</span>".
            </EN>
          </p>
          <p>
            <NL>Die is er nu! Het heet Selectical. :{')'} </NL>
            <EN>Well, is exists! It's called Selectical. :{')'} </EN>
          </p>
          <Button
            className="me-3"
            color="green"
            to={{
              nl: '#selectical',
              en: '#selectical',
            }}
          >
            <NL>Meer over Selectical</NL>
            <EN>More on Selectical</EN>
          </Button>
        </div>
      </div>
    </Section>

    <Section size="small" className="text-white" gradient="teal-blue" id="pseuduck">
      <div className="text-container">
        <Pseuduck />
      </div>
    </Section>

    <Section size="small" id="selectical">
      <div className="text-container">
        <Pallas />
      </div>
    </Section>

    <Section size="small" id="IP" className="bg-transparent-teal">
      <div className="text-container">
        <div className="subtitle">
          <NL>Kanttekening over Intellectueel Eigendom</NL>
          <EN>Side note on Intellectual Property</EN>
        </div>
        <h1 className="text-teal">
          <NL>Wil jij je eigen AI-product?</NL>
          <EN>Want to own your own AI product?</EN>
        </h1>
        <p>
          <NL>
            Voordat we met elkaar aan de slag gaan aan oplossingen, maken we duidelijke afspraken over de doeleinden,
            wat er wordt gebruikt en wiens eigendom de resulterende producten zijn. Hierin zijn meerdere vormen
            mogelijk. We werken en denken graag met je mee!
          </NL>
          <EN>
            Before we get to work on solutions together, we will make clear agreements on the goals, code and knowledge
            (re-) usage and eventual intellectual property ownership. Many arrangements are possible. We like to work
            and think along with you!
          </EN>
        </p>
      </div>
    </Section>
  </Page>
)
export default PortfolioProducten
