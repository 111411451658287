// @flow
import * as React from 'react'
import { Section } from './'

import './Jumbotron.scss'

type Props = {
  children?: React.Node,
  className?: string,
  title: React.Node
}

export const Jumbotron = (props: Props): React.Node => {
  const className = [
    props.className != null ? props.className : '',
    'Jumbotron'
  ].join(' ')
  return (
    <Section className={className}>
      <div className="Jumbotron-title-container container">
        <h1 className="Jumbotron-title">{props.title}</h1>
      </div>
      {props.children}
    </Section>
  )
}
